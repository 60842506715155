(function () {
  'use strict';

  angular
    .module('pages.recoverPassword.emailInput')
    .controller('EmailInputController', EmailInputController);

  function EmailInputController(ZendeskWidget, UserService) {
    var vm = this;

    var RECOVER_PASSWORD_EMPTY = {
      email: '',
    };
    vm.forgotPassword = angular.copy(RECOVER_PASSWORD_EMPTY);
    vm.forgotPasswordError = '';

    // Mensaje al usuario
    vm.showUserMessage = false;
    vm.userMessageType = 'warning';
    vm.userMessage = '';

    // Variables para manejar qué formulario mostrar
    const PASSWORD = 'forgot-password';
    // const PASSWORD_SUCCESS = 'forgot-password-success';

    vm.busy = false;
    vm.currentForm = PASSWORD;

    vm.changeForm = changeForm;
    vm.recoverUserPassword = recoverUserPassword;
    vm.showZendeskHelp = showZendeskHelp;

    /**
     * Cambiar formulario
     * @param form {string}
     */
    function changeForm(form) {
      vm.currentForm = form;
    }

    /**
     * Recuperar contraseña
     */
    function recoverUserPassword() {
      vm.busy = true;
      vm.showUserMessage = false;

      UserService.recoverPassword(vm.forgotPassword).then(
        function () {
          vm.busy = false;
          vm.changeForm('forgot-password-success');
        },
        function (reason) {
          vm.busy = false;

          if (reason.data.code === 'FRM') {
            vm.userMessage = 'El formato de email es incorrecto';
          }
          // Si el correo no existe en Fork, no debería mostrarse al usuario
          else if (reason.data.code === 'NGC') {
            vm.userMessage =
              'No hemos podido encontrar una cuenta con esa información. Inténtalo de nuevo con otro correo.';
          } else if (reason.data.message) {
            vm.userMessage = reason.data.message;
          } else {
            vm.userMessage =
              'Ha ocurrido un error con la recuperación de contraseña, por favor inténtalo más tarde';
          }

          vm.showUserMessage = true;
        }
      );
    }

    /**
     * Abrir ventana de Zendesk
     */
    function showZendeskHelp() {
      ZendeskWidget.activate();
    }
  }
})();
