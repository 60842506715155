(function () {
  'use strict';

  angular.module('shared.headerGrayBar').component('headerGrayBarComponent', {
    templateUrl: 'shared/header-gray-bar/header-gray-bar.template.html',
    controller: HeaderGrayBarController,
    controllerAs: 'vm',
  });

  function HeaderGrayBarController($rootScope, $scope, GlobalService) {
    var vm = this;
    $scope.global = GlobalService;

    var unregisterCofigDelivery;
    vm.busy = false;

    // Abrir/Cerrar Menu
    vm.desktopMenuIsOpen = false;
    vm.mobileMenuIsOpen = false;
    vm.overDesktopCategory = null;

    // Data
    vm.image = '';
    vm.title = '';
    vm.name = '';
    vm.address = '';

    vm.$onInit = onInit;
    vm.openConfigDeliveryModal = openConfigDeliveryModal;

    $scope.$on('$destroy', onDestroy);
    $scope.toggleMenu = toggleMenu;
    $scope.clickOverlayMenu = clickOverlayMenu;
    $scope.setData = setData;

    function onInit() {
      vm.busy = true;
      const currentConfigDelivery = $scope.global.configDelivery;
      if (currentConfigDelivery.kind) {
        $scope.setData(currentConfigDelivery);
      }

      // Si la configuración de entrega cambia, actualizar la variable del controlador
      unregisterCofigDelivery = $scope.$watch(
        'global.configDelivery',
        (newVal) => {
          if (newVal && newVal.kind !== null) {
            $scope.setData(newVal);
          }
        },
        true
      );
    }

    function onDestroy() {
      unregisterCofigDelivery();
    }

    /**
     * Abrir el modal de configuración de entrega
     */
    function openConfigDeliveryModal() {
      $rootScope.$broadcast('openConfigDeliveryModal', { state: 'config-delivery-bar' });
      console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
    }

    /**
     * Establecer la información de configuración de entrega para mostrarla en la vista
     * @param configDelivery
     */
    function setData(configDelivery) {
      vm.busy = true;
      if (configDelivery.kind === 0) {
        const address = configDelivery.address;

        vm.image = 'assets/img/Delivery.svg';
        vm.title = 'Fork lo lleva a';
        vm.name = address.name;
        vm.address = `${address.street_name} ${address.number}`;
        if (address.unit_number) {
          vm.address += ` depto ${address.unit_number}`;
        }
      } else if (configDelivery.kind === 1) {
        const store = configDelivery.store;

        vm.image = 'assets/img/Retiro.svg';
        vm.title = 'Retiras en tienda';
        vm.name = store.name;
        vm.address = store.address;
        vm.comentario = store.comentario ? store.comentario : null;
      } else {
        console.warn(`No existe data para el modo de entrega ${configDelivery.kind}`);
      }

      vm.busy = false;
    }

    // animacion icono menu
    $('.menu-wrapper').on('click', function () {
      var menuBtn = '.menu-btn';
      var menuBtnOn = 'menu-btn-on';
      var menuWrapOn = 'menu-wrapper-on';

      if ($(this).hasClass(menuWrapOn)) {
        $(this).removeClass(menuWrapOn);
      } else {
        $(this).addClass(menuWrapOn);
      }
      if ($(menuBtn).hasClass(menuBtnOn)) {
        $(menuBtn).removeClass(menuBtnOn);
      } else {
        $(menuBtn).addClass(menuBtnOn);
      }
    });

    // Mostrar / Ocultar Menus
    function toggleMenu(device) {
      if (device === 'desktop') {
        vm.desktopMenuIsOpen = !vm.desktopMenuIsOpen;
        vm.overDesktopCategory = null;
        // condición para desabilitar el scroll cuando el menu este abierto
        // y activar/desactivar animacion del icono del menu
        if (vm.desktopMenuIsOpen) {
          document.body.style.overflow = 'hidden';
          $('.menu-wrapper').addClass('menu-wrapper-on');
          $('.menu-btn').addClass('menu-btn-on');
        } else {
          document.body.style.overflow = 'auto';
          $('.menu-wrapper').removeClass('menu-wrapper-on');
          $('.menu-btn').removeClass('menu-btn-on');
        }
      }
      if (device === 'mobile') {
        vm.mobileMenuIsOpen = !vm.mobileMenuIsOpen;
        if (vm.mobileMenuIsOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }
      return null;
    }

    function clickOverlayMenu() {
      // cerrar menú
      vm.desktopMenuIsOpen = false;
      vm.mobileMenuIsOpen = false;
      vm.overDesktopCategory = null;

      // habilitar el scroll al cerrar menu
      document.body.style.overflow = 'auto';

      // cambiar icono del menu
      $('.menu-wrapper').removeClass('menu-wrapper-on');
      $('.menu-btn').removeClass('menu-btn-on');
    }
  }
})();
