(function () {
  'use strict';

  angular.module('services.navigation').service('NavigationService', NavigationService);

  function NavigationService(config) {
    /**
     * Enlaces que van hacia una pagina externa
     */
    const externalUrls = [
      {
        name: 'Nosotros',
        href: config.LANDING_URL,
      },
      {
        name: 'Proveedores',
        href: `${config.LANDING_URL}/proveedores`,
      },
      {
        name: 'Tiendas',
        href: `${config.LANDING_URL}/tiendas`,
      },
      {
        name: 'Delivery',
        href: `${config.LANDING_URL}/delivery`,
      },
      {
        name: 'Preguntas frecuentes',
        href: 'http://soporte.forkchile.cl',
      },
    ];

    /**
     * Enlaces en los que el usuario puede navegar dentro de la aplicación
     */
    const internalUrls = [
      {
        name: 'Mi perfil',
        uiSref: 'user.profile',
        visible: true,
      },
      {
        name: 'Direcciones',
        uiSref: 'user.addresses',
        visible: true,
      },
      {
        name: 'Facturación',
        uiSref: 'user.billing',
        visible: true,
      },
      {
        name: 'Tarjeta de crédito',
        uiSref: 'user.payments',
        visible: true,
      },
      {
        name: 'Pedidos',
        uiSref: 'user.orders',
        visible: true,
      },
      {
        name: 'Alcancía',
        uiSref: 'user.piggyBank',
        visible: true,
      }
    ];

    /**
     * Enlaces de las redes sociales
     */

    const HTTPS = 'https://';
    const IMG_URL = 'assets/img';
    const IMG_EXT = '.svg';

    const socialUrls = [
      {
        name: 'Facebook',
        href: `${HTTPS}www.facebook.com/forkchile/`,
        img: `${IMG_URL}/facebook_footer${IMG_EXT}`,
      },
      {
        name: 'Instagram',
        href: `${HTTPS}www.instagram.com/forkchile/`,
        img: `${IMG_URL}/instagram_footer${IMG_EXT}`,
      },
    ];

    return {
      getExternalUrls: getExternalUrls,
      getInternalUrls: getInternalUrls,
      getSocialUrls: getSocialUrls,
    };

    function getExternalUrls() {
      return externalUrls;
    }

    function getInternalUrls() {
      return internalUrls;
    }

    function getSocialUrls() {
      return socialUrls;
    }
  }
})();
