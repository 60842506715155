(function () {
  'use strict';

  angular.module('productDetails.productPackMenu').component('productPackMenu', {
    templateUrl:
      'pages/product-details/components/product-pack-menu/product-pack-menu.template.html',
    controller: ProductPackMenuController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
    },
  });

  function ProductPackMenuController($state, CartService, GlobalService) {
    var vm = this;

    vm.packGroups = [];

    // Mensajes al usuario
    vm.submitted = false;
    vm.showUserMessage = false;
    vm.userMessage = '';

    vm.$onInit = onInit;
    vm.addPackToCart = addPackToCart;
    vm.verifyMissingGroups = verifyMissingGroups;

    function onInit() {
      vm.packGroups = vm.product.packDinamico;

      // Inicializar la variable para almacenar los productos seleccionados por grupo.
      // El `selectedProduct: null` representa que el usuario no ha seleccionado un producto para ese grupo.
      vm.packGroups.map(function (group) {
        group.selectedProduct = null;
      });
    }

    /**
     * Agregar el pack al carro.
     * Debe haber por lo menos un producto por cada grupo
     */
    function addPackToCart() {
      vm.submitted = true;
      var missingGroups = verifyMissingGroups();

      if (!missingGroups.length) {
        vm.busy = true;

        var item = vm.product.items[0].portion;
        var itemsPack = vm.packGroups.map(function (group) {
          return group.selectedProduct;
        });

        // Necesitamos que el precio esté en la porción del producto para poder enviar las analíticas
        item.price = vm.product.items[0].unit_price;

        // mapeamos propiedades extras para analiticas
        item.title = vm.product.title;
        item.subtitle = vm.product.subtitle;
        item.imageUrl = vm.product.pictures_web[0];

        CartService.addToCart(item, itemsPack, 'product-pack-menu').then(
          function (value) {
            GlobalService.cart = value;
            $state.go('home');
          },
          function (reason) {
            vm.busy = false;
            console.error(reason);
          }
        );
      }
    }

    /**
     * Verificar si falta algún producto por seleccionar
     * @returns missingGroups {object[]} Lista de grupos que faltan por seleccionar un producto
     */
    function verifyMissingGroups() {
      if (vm.submitted) {
        // Para saber si falta algún producto por seleccionar, hay que filtrar los grupos que tengan la propiedad
        // `selectedProduct` en null.
        var missingGroups = vm.packGroups.filter(function (group) {
          return !group.selectedProduct;
        });

        if (missingGroups.length) {
          // Construir el mensaje que se le mostrará al usuario
          vm.userMessage = 'No olvides seleccionar tu ';

          missingGroups.forEach(function (group, index) {
            if (index > 0) {
              if (index !== missingGroups.length - 1) {
                vm.userMessage += ', ';
              } else {
                vm.userMessage += ' y ';
              }
            }
            vm.userMessage += group.nombre;

            // Mostrar mensaje al usuario
            vm.showUserMessage = true;
          });
        } else {
          // Ocultar mensaje al usuario
          vm.showUserMessage = false;
        }

        return missingGroups;
      }

      return [];
    }
  }
})();
