(function () {
  'use strict';

  angular.module('shared.navbarMessage').component('navbarMessageComponent', {
    templateUrl: 'shared/navbar-message/navbar-message.template.html',
    controller: NavbarMessageController,
    controllerAs: 'vm',
    bindings: {
      type: '<',
      messageTitle: '<',
      message: '<',
    },
  });

  function NavbarMessageController($rootScope) {
    const vm = this;

    vm.$onChanges = onChanges;
    vm.dismissNavbarMessage = dismissNavbarMessage;

    function onChanges() {
      if (vm.type) {
        vm.imageUrl = 'assets/img';

        switch (vm.type) {
          case 'success':
            vm.imageUrl += '/check-green.svg';
            break;
          case 'warning':
            vm.imageUrl += '/warning.svg';
            break;
          case 'info':
            vm.imageUrl += '/info.svg';
            break;
        }
      }
    }

    function dismissNavbarMessage() {
      $rootScope.$broadcast('dismissNavbarMessage');
      console.info('Broadcast: ¡Cerrar el mensaje del navbar!');
    }
  }
})();
