(function () {
  'use strict';

  angular
    .module('pages.userProfile.userData.changePasswordModal')
    .component('changePasswordModalComponent', {
      templateUrl:
        'pages/user-profile/pages/user-data/components/change-password-modal/change-password-modal.template.html',
      controller: ChangePasswordModalController,
      controllerAs: 'vm',
    });

  function ChangePasswordModalController($scope, AnalyticsService, GlobalService, UserService) {
    var vm = this;

    vm.email = '';
    vm.actualPassword = '';
    vm.newPassword = '';
    vm.repeatNewPassword = '';

    vm.showActualPassword = false;
    vm.showNewPassword = false;
    vm.showRepeatNewPassword = false;

    // Variable para dejar de escuchar los cambios en el scope
    var unregisterOpenEditPasswordModal = function () {
      return undefined;
    };

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.changePassword = changePassword;
    vm.closeChangePasswordModal = closeChangePasswordModal;
    vm.resetActualPasswordValidator = resetActualPasswordValidator;
    vm.toggleActualPassword = toggleActualPassword;
    vm.toggleNewPassword = toggleNewPassword;
    vm.toggleRepeatPassword = toggleRepeatPassword;

    function onInit() {
      vm.email = GlobalService.user.email;

      unregisterOpenEditPasswordModal = $scope.$on('openChangePasswordModal', function () {
        vm.actualPassword = '';
        vm.newPassword = '';
        vm.repeatNewPassword = '';
        vm.changePasswordError = false;
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
        vm.showActualPassword = false;
        vm.showNewPassword = false;
        vm.showRepeatNewPassword = false;

        $('#change-password-modal-component').modal('show');
        AnalyticsService.initChangePassword();
      });
    }

    function closeChangePasswordModal() {
      $('#change-password-modal-component').modal('hide');
    }

    function changePassword() {
      vm.busy = true;
      vm.changePasswordError = false;

      var form = {
        contraseniaVieja: vm.actualPassword,
        contraseniaNueva: vm.newPassword,
        contraseniaNueva2: vm.repeatNewPassword,
      };

      UserService.changePasswordPefil(form).then(
        function () {
          vm.busy = false;
          $scope.$emit('passwordEdited');
          vm.closeChangePasswordModal();
          AnalyticsService.editPassword();
        },
        function (reason) {
          vm.busy = false;

          if (reason.data.message === 'Error validando contraseña antigua') {
            $scope.form.actualPassword.$setValidity('correct', false);
          } else {
            vm.changePasswordError = true;
          }
        }
      );
    }

    function resetActualPasswordValidator() {
      $scope.form.actualPassword.$setValidity('correct', true);
    }

    function toggleActualPassword() {
      vm.showActualPassword = !vm.showActualPassword;
    }

    function toggleNewPassword() {
      vm.showNewPassword = !vm.showNewPassword;
    }

    function toggleRepeatPassword() {
      vm.showRepeatNewPassword = !vm.showRepeatNewPassword;
    }

    $scope.$on('$destroy', function () {
      unregisterOpenEditPasswordModal();
    });
  }
})();
