(function () {
  'use strict';

  angular.module('filters.rutNumber').filter('rutNumber', RutNumberFilter);

  function RutNumberFilter() {
    return function (rut) {
      if (rut) {
        // Despejar guión
        var value = rut.toString().replace('-', '');

        // Aislar Cuerpo y Dígito Verificador
        var body = value.slice(0, -1);
        var dv = value.slice(-1).toUpperCase();

        return body.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dv;
      }
    };
  }
})();
