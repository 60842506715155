(function () {
  'use strict';

  angular.module('shared.footerMessage').component('footerMessageComponent', {
    templateUrl: 'shared/footer-message/footer-message.template.html',
    controller: FooterMessageController,
    controllerAs: 'vm',
  });

  function FooterMessageController($scope, contentful, DateService, GlobalService, StoreService) {
    var vm = this;
    $scope.global = GlobalService;

    vm.imageUrl = '';

    vm.$onInit = onInit;
    vm.dismissFooterMessage = dismissFooterMessage;

    $scope.loadData = loadData;
    $scope.checkContenfulMessage = checkContentfulMessage;
    $scope.checkAlertNextDay = checkAlertNextDay;

    function onInit() {
      if ($scope.global.footerMessage === null) {
        $scope.checkContenfulMessage();
      } else if ($scope.global.showFooterMessage) {
        $scope.loadData($scope.global.footerMessage);
      }
    }

    /**
     * Verificar si hay que mostrar el mensaje de delivery del día siguiente
     */
    function checkAlertNextDay() {
      StoreService.getStores().then((value) => {
        const selectedStore = value[0];

        // Para saber si hay delivery para mañana, basta con que cualquier bloque de delivery tenga la propiedad `dia`.
        StoreService.getSchedule(selectedStore.id).then(function (value) {
          var store = value.data;

          if (
            store &&
            store.available_schedules &&
            store.available_schedules.length &&
            store.available_schedules[0].dia
          ) {
            $scope.loadData({
              type: 'info',
              title: '',
              message: {
                fields: { text: 'Ya puedes puedes pedir delivery para recibirlo mañana.' },
              },
            });
          }
        });
      });
    }

    /**
     * Verificar si un mensaje por publicar en el footer configurado desde Contentful
     */
    function checkContentfulMessage() {
      var index;
      var data;

      contentful.entries('content_type=alert&order=-sys.createdAt').then(function (value) {
        data = value.data;
        if (data.total) {
          // Verificar si alguna de esas alertas pertenecen al footer
          data.items.forEach(function (item) {
            if (item.fields && item.fields.showIn && item.fields.showIn.length) {
              index = item.fields.showIn.findIndex(function (component) {
                return component === 'Footer';
              });

              // Si pertenece...
              if (index !== -1) {
                // Evaluar si la alerta se encuentra dentro de la fecha
                if (item.fields.begins && item.fields.ends) {
                  DateService.getCurrentTimestamp().then(function (value) {
                    var today = value * 1000;
                    var alertBegins = item.fields.begins;
                    var alertEnds = item.fields.ends;

                    // ¿La alerta se encuentra dentro de la fecha?
                    if (moment(today).isAfter(alertBegins) && moment(today).isBefore(alertEnds)) {
                      $scope.loadData(item.fields);
                    } else {
                      $scope.checkAlertNextDay();
                    }
                  });
                } else {
                  $scope.loadData(item.fields);
                }
              } else {
                $scope.checkAlertNextDay();
              }
            }
          });
        } else {
          $scope.checkAlertNextDay();
        }
      });
    }

    /**
     * Ocultar el mensaje del footer
     */
    function dismissFooterMessage() {
      GlobalService.showFooterMessage = false;
    }

    /**
     * Cargar la información que se mostrará en el mensaje del footer
     * @param item - Objeto que contiene la información desde contentful
     */
    function loadData(item) {
      if (item.type && (item.title || item.message)) {
        vm.imageUrl = 'assets/img';

        switch (item.type) {
          case 'success':
            vm.imageUrl += '/check-green.svg';
            break;
          case 'warning':
            vm.imageUrl += '/warning.svg';
            break;
          case 'info':
            vm.imageUrl += '/info.svg';
            break;
        }

        vm.type = item.type;
        vm.title = item.title;
        vm.message = item.message ? item.message.fields.text : '';

        GlobalService.footerMessage = item;
        GlobalService.showFooterMessage = true;
      }
    }
  }
})();
