(function () {
  'use strict';

  angular.module('shared.landingHome').component('landingHomeComponent', {
    templateUrl: 'shared/landing-home/landing-home.template.html',
    controller: LandingHomeController,
    controllerAs: 'vm',
    bindings: {
      categoriesLandingData: '<',
      navbarMessageIsActive: '<',
    },
  });

  function LandingHomeController(
    $rootScope,
    $scope,
    $state,
    GlobalService,
    AnalyticsService,
    MetaTagService,
    ScrollService
  ) {
    const vm = this;
    $scope.global = GlobalService;

    vm.clickLandingCategory = clickLandingCategory;
    $scope.currentTime = new Date().valueOf();

    // vm.$onInit = onInit;

    // function onInit() {}

    function clickLandingCategory(categoryId, categoryName) {
      $rootScope.$broadcast('changeCategory', {
        categoryId: categoryId,
        categoryName: `destacados-${categoryName.trim().toLowerCase().split(' ').join('-')}`,
      });
      $('html').animate({ scrollTop: 0 }, 0);

      AnalyticsService.clickLandingCategory(categoryId, categoryName);
    }
  }
})();
