(function () {
  'use strict';

  angular.module('app', [
    'angularLazyImg',
    'angulartics',
    'angulartics.google.analytics',
    'angulartics.mixpanel',
    'angulartics.facebook.pixel',
    'contentful',
    'ngAnimate',
    'ngCookies',
    'ngMap',
    'ngMask',
    'ngStorage',
    'slugifier',
    'smoothScroll',
    'ui.router',
    'ui.swiper',
    'ui.bootstrap',
    'ui.mask',
    'ui.router.state.events',
    'zendeskWidget',

    // Aplicación
    'config',
    'app.constants',
    'app.core',
    'app.pages',
    'app.shared',
  ]);
})();
