(function () {
  'use strict';

  angular.module('shared.ratingModal').component('ratingModalComponent', {
    templateUrl: 'shared/rating-modal/rating-modal.template.html',
    controller: RatingModalController,
    controllerAs: 'vm',
  });

  function RatingModalController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    AlertService,
    GlobalService,
    LogService,
    OrderService,
    RatingService
  ) {
    // Constantes
    const FIRST_TITLE = 'Califica tu experiencia';
    const RATE_VENTA_DIGITAL = '¿Cómo evaluas los siguientes aspectos de tu compra?';
    const RATE_PRODUCT = '¿Te gustó lo que probaste?';

    // Componente
    const vm = this;
    const CTRL_NAME = vm.constructor.name;

    $scope.global = GlobalService;
    $scope.checkedOnce = false;

    // Tipos de calificación
    let FINISH = 'FINISH';
    let SCORE = 'SCORE';
    vm.COMMENT = 'COMMENT';
    vm.COMMENT_DISH = 'COMMENT_DISH';

    vm.busy = false;
    vm.ready = false;
    vm.done = false;

    vm.currentStep = 1;
    vm.totalSteps = 3;
    vm.kindImage = '';
    vm.modaTitle = FIRST_TITLE;
    vm.scores = [];
    vm.products = [];

    // Información del pedido y del usuario
    vm.detail = ''; // Detalles del pedido
    vm.order = null; // Pedido a evaluar
    vm.schedule = ''; // Horario de delivery
    vm.username = ''; // Nombre del usuario

    // Input del usuario
    vm.orderComments = ''; // Comentarios del usuario acerca del pedido
    vm.productComments = ''; // Comentarios del usuario acerca de los productos
    vm.score = 0; // Evaluación del usuario
    vm.simulatedScore = 0; // Simular evaluación del usuario

    // Variables enlazadas al controlador
    $scope.incompletedStep = 0;
    $scope.modalIsVisible = false;
    $scope.source = 'WEB';
    $scope.orders = [];
    $scope.stars = [];
    $scope.event = {};

    // Funciones enlazadas con la plantilla
    vm.closeRatingModal = closeRatingModal;
    vm.deleteOrderComments = deleteOrderComments;
    vm.deleteProductComments = deleteProductComments;
    vm.exitRating = exitRating;
    vm.nextStep = nextStep;
    vm.openCommentsModal = openCommentsModal;
    vm.prevStep = prevStep;
    vm.setScore = setScore;
    vm.simulateScore = simulateScore;

    // Funciones enlazadas al controlador
    $scope.checkRatingModal = checkRatingModal;
    $scope.checkRatingCompleteness = checkRatingCompleteness;
    $scope.clearOrderComments = clearOrderComments;
    $scope.clearProductComments = clearProductComments;
    $scope.endRating = endRating;
    $scope.getOrderByUserHash = getOrderByUserHash;
    $scope.getOrderToRate = getOrderToRate;
    $scope.goToIncompletedStep = goToIncompletedStep;
    $scope.setContent = setContent;
    $scope.openRatingModal = openRatingModal;
    $scope.updateTitle = updateTitle;

    // Funciones enlazadas al rootScope
    $rootScope.$on('checkRatingModal', checkRatingModal);

    /**
     * Verificar si se debe mostrar el modal de rating, dependiendo de si el pedido a evaluar viene en la ruta o en la
     * lista de pedidos.
     * La verificación se hace 1 vez, ya sea por un medio o por el otro.
     */
    function checkRatingModal() {
      if (!$scope.checkedOnce) {
        $scope.checkedOnce = true;

        if ($stateParams && $stateParams.orderId) {
          const { orderId, hash, ventaDigital, score, source } = $stateParams;

          vm.isDigital = ventaDigital != 'false';
          $scope.source = source || vm.isDigital ? 'WEB' : 'EMAIL';

          // Si la venta es física, hay que cambiar el nombre de las constantes
          if (!vm.isDigital) {
            FINISH = 'FIN';
            SCORE = 'ESCALA_7';
            vm.COMMENT = 'COMENTARIO_PEDIDO';
            vm.COMMENT_DISH = 'COMENTARIO_PRODUCTO';
          }

          if (orderId && hash) {
            $scope.getOrderByUserHash(orderId, hash, score, vm.isDigital);
          } else {
            console.warn(
              'Parámetros de la ruta incompletos. Se requiere el número del pedido y el hash del usuario'
            );
            if ($state.current.name === 'rating') {
              $state.go('home');
            }
          }
        } else if (GlobalService.user) {
          vm.isDigital = true;
          $scope.source = 'WEB';
          $scope.modalIsVisible = $('.modal').is(':visible');
          $scope.getOrderToRate();
        }
      }
    }

    /**
     * Verificar la completitud del rating
     */
    function checkRatingCompleteness() {
      let incomplete = true;
      $scope.incompletedStep = 0;

      // Oraciones
      let unratedSentence = vm.order.evaluacionOrigenVenta.findIndex(
        (sentence) => !angular.isDefined(sentence.thumb)
      );

      // Productos
      let unratedProduct = vm.products.findIndex((product) => !angular.isDefined(product.thumb));

      if (!vm.score) {
        // Calificación del pedido
        $scope.incompletedStep = 1;
      } else if (unratedSentence !== -1) {
        // Oraciones
        $scope.incompletedStep = 2;
      } else if (unratedProduct !== -1) {
        $scope.incompletedStep = 3;
      } else {
        incomplete = false;
      }

      return incomplete;
    }

    /**
     * Cerrar el modal de rating
     */
    function closeRatingModal() {
      // Si el usuario no ha seleccionado una calificación, enviar 0 al servidor.
      // De esa manera, la próxima vez no se abrirá el modal de rating por el mismo
      // pedido
      if (!vm.score && !!vm.order) {
        RatingService.setRating($scope.source, vm.isDigital, SCORE, 0, vm.order.id);
      }

      $('#rating-modal').fadeOut(150);

      $timeout(() => {
        $('#rating-modal').modal('hide');

        if ($stateParams.hash) {
          $state.go('home');
        }
      }, 200);
    }

    /**
     * Es posible eliminar el comentario del pedido, pero antes se muestra un alerta
     */
    function deleteOrderComments() {
      AlertService.deleteOrderComments($scope.clearOrderComments);
    }

    /**
     * Es posible eliminar el comentario de los productos, pero antes se muestra un alerta
     */
    function deleteProductComments() {
      AlertService.deleteOrderComments($scope.clearProductComments);
    }

    /**
     * Eliminar comentario del pedido
     */
    function clearOrderComments() {
      vm.orderComments = '';
    }

    /**
     * Eliminar comentario de los productos
     */
    function clearProductComments() {
      vm.productComments = '';
    }

    /**
     * Mostrar el fin del rating
     */
    function endRating() {
      vm.done = true;

      $timeout(vm.closeRatingModal, 5000);

      // Devolver al usuario al home, si es que se encontraba en la vista de rating
      if (/rating$/.test($state.current.name)) {
        $state.go('home');
      }

      RatingService.setRating($scope.source, vm.isDigital, FINISH, true, vm.order.id);
    }

    /**
     * Es posible cerrar el modal, pero antes se mostrará una advertencia
     */
    function exitRating() {
      AlertService.exitRating(vm.closeRatingModal);
    }

    /**
     * Obtiene el pedido por el hash del usuario
     * @param {string} orderId Número del pedido
     * @param {string} hash Hash del usuario
     * @param {string} score Calificación
     * @param {boolean} isDigital ¿Es venta digital?
     */
    function getOrderByUserHash(orderId, hash, score, isDigital) {
      OrderService.getOrderToRateByUserHash(orderId, hash, isDigital)
        .then((value) => {
          const order = value.data.response;
          if (order) {
            console.info('Evaluar pedido: ', order);
            $scope.setContent(order);
          } else {
            console.info('No hay pedidos para evaluar');
            $state.go('home');
          }
        })
        .catch((reason) => {
          // Si ocurre un error obteniendo el pedido, no hay mucho que hacer
          console.error(reason);
          $state.go('home');
        });
    }

    /**
     * Obtener el último pedido del usuario
     */
    function getOrderToRate() {
      // Si hay un modal visible, omitir la manipulación del contenido
      if (!$scope.modalIsVisible) {
        OrderService.getOrderToRate(GlobalService.user.id).then((value) => {
          const order = value.data.response;
          if (order) {
            console.info('Evaluar pedido: ', order);
            $scope.setContent(order);
          }
        });
      }
    }

    /**
     * Ir al paso donde no se ha completado la evaluación
     */
    function goToIncompletedStep() {
      vm.currentStep = $scope.incompletedStep;
      $scope.updateTitle();
    }

    /**
     * Ir un paso adelante
     */
    function nextStep() {
      if (!vm.busy) {
        if (vm.currentStep < vm.totalSteps) {
          vm.currentStep++;
          $scope.updateTitle();
        } else if (vm.currentStep === vm.totalSteps && $scope.checkRatingCompleteness()) {
          AlertService.quitRating($scope.goToIncompletedStep, $scope.endRating);
        } else {
          vm.currentStep++;
          $scope.endRating();
        }
      }
    }

    /**
     * Abrir el modal de comentarios
     */
    function openCommentsModal(type) {
      $scope.$broadcast('openCommentsModal', { type: type });
    }

    /**
     * Ir un paso atrás
     */
    function prevStep() {
      vm.currentStep = vm.currentStep <= 1 ? 1 : vm.currentStep - 1;
      $scope.updateTitle();
    }

    /**
     * Dibujar el contenido en el componente, en función de la información del pedido
     */
    function setContent(order, score) {
      let kindImage = 'assets/img/';
      let kindDetail = '';
      if (order.tipoVenta === 0) {
        // Delivery
        kindImage += 'Delivery.svg';
        kindDetail = `Reparto a ${order.direccion.nombre}`;
      } else if (order.tipoVenta === 1) {
        // Retiro en tienda
        kindImage += 'Retiro.svg';
        kindDetail = `Retiro en tienda ${order.sucursal.nombre}`;
      } else if (order.tipoVenta === 2) {
        // Freezer
        kindImage += 'refri.svg';
        kindDetail = `Retiro en refrigerador ${order.sucursal.nombre}`;
      } else if (order.tipoVenta === 5) {
        kindImage += 'Retiro.svg';
        kindDetail = `Compra en tienda ${order.sucursal.nombre}`;
      }

      vm.order = order;
      vm.username = `${order.usuario.nombre} ${order.usuario.apellidoPaterno}`;
      vm.products = order.ventaDetalles;
      vm.scores = order.sistemaRating;
      vm.kindImage = kindImage;
      vm.kindDetail = kindDetail;
      vm.schedule = order.fechaVenta;

      if (score) {
        vm.setScore(score);
      }

      vm.ready = true;

      $timeout($scope.openRatingModal);
    }

    /**
     * Establecer la evaluación
     * @param {number} score Evaluación del usuario
     */
    function setScore(score) {
      vm.busy = true;
      vm.score = score;
      vm.simulatedScore = 0;

      RatingService.setRating($scope.source, vm.isDigital, SCORE, score, vm.order.id);
      vm.busy = false;
    }

    /**
     * Simular la evaluación cuando el usuario haga hover en cualquiera de las estrellas
     * Sólo aplica cuando el usuario no ha seleccionado una evaluación
     * @param {number} rating Evaluación del usuario
     */
    function simulateScore(rating) {
      if (!vm.score) {
        vm.simulatedScore = rating;
      }
    }

    /**
     * Mostrar el modal de rating, sólo si no hay otro modal abierto
     */
    function openRatingModal() {
      $('#rating-modal').modal('show');
    }

    /**
     * Actualizar el título del modal
     */
    function updateTitle() {
      let title;

      if (vm.currentStep === 1) {
        title = FIRST_TITLE;
      } else if (vm.currentStep === 2) {
        title = RATE_VENTA_DIGITAL;
      } else if (vm.currentStep === 3) {
        title = RATE_PRODUCT;
      } else {
        title = '';
        LogService.unexpectedCase(
          CTRL_NAME,
          'Se intentó actualizar el título del modal con un paso que no está definido: ' +
            vm.currentStep
        );
      }

      vm.modaTitle = title;
    }
  }
})();
