(function () {
  'use strict';

  angular.module('shared.ratingModal.ratingProductCard').component('ratingProductCardComponent', {
    templateUrl:
      'shared/rating-modal/components/rating-product-card/rating-product-card.template.html',
    controller: RatingProductCardController,
    controllerAs: 'vm',
    bindings: {
      order: '<',
      product: '<',
      isDigital: '<',
    },
  });

  function RatingProductCardController($stateParams, RatingService) {
    // Constantes
    let SCORE_DISH;

    // Componente
    const vm = this;

    // Variables enlazadas con la plantilla
    vm.thumb = -1;

    // Funciones enlazadas con la plantilla
    vm.$onInit = onInit;
    vm.getButtonClass = getButtonClass;
    vm.setThumb = setThumb;

    function onInit() {
      SCORE_DISH = vm.isDigital ? 'SCORE_DISH' : 'EVALUACION_PRODUCTO';
    }

    /**
     * Obtiene la clase que dibuja el botón que esté seleccionado
     * @param thumb
     * @returns {{"rating-product-btn--selected": boolean}}
     */
    function getButtonClass(thumb) {
      return { 'rating-product-btn--selected': vm.thumb === thumb };
    }

    /**
     * Establecer calificación positiva o negativa
     * @param thumb
     */
    function setThumb(thumb) {
      if (vm.thumb !== thumb) {
        vm.thumb = thumb;
        vm.product.thumb = thumb;

        const source = $stateParams.source || vm.isDigital ? 'WEB' : 'EMAIL';

        RatingService.setRating(
          source,
          vm.isDigital,
          SCORE_DISH,
          thumb,
          vm.order.id,
          vm.product.sku
        );
      }
    }
  }
})();
