(function () {
  'use strict';

  angular.module('checkout.configDelivery').component('checkoutConfigDelivery', {
    controller: CheckoutConfigDelivery,
    controllerAs: 'vm',
    templateUrl:
      'pages/checkout/components/checkout-config-delivery/checkout-config-delivery.template.html',
    bindings: {
      currentStep: '<',
    },
  });

  function CheckoutConfigDelivery($rootScope, $scope, DateService, GlobalService) {
    const vm = this;
    $scope.global = GlobalService;

    vm.configDeliveryInfo = null;

    vm.$onInit = onInit;
    vm.openConfigDeliveryModal = openConfigDeliveryModal;

    $scope.updateConfigDeliveryInfo = updateConfigDeliveryInfo;

    function onInit() {
      $scope.$watch('global.configDelivery', function (newVal) {
        $scope.updateConfigDeliveryInfo(newVal);
      });
    }

    function openConfigDeliveryModal() {
      $rootScope.$broadcast('openConfigDeliveryModal', { state: 'checkout' });
      console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
    }

    function updateConfigDeliveryInfo(configDelivery) {
      if (configDelivery.address && configDelivery.kind === 0) {
        const address = configDelivery.address;

        let subtitle2 = '';
        if (address.unit_number) {
          subtitle2 = `depto ${address.unit_number}, `;
        }
        subtitle2 += address.commune.name;

        vm.configDeliveryInfo = {
          title: `Fork lo lleva a "${address.name}"`,
          subtitle: `${address.street_name} ${address.number}`,
          subtitle2: subtitle2,
          image: 'assets/img/Delivery.svg',
        };
      } else if (configDelivery.store && configDelivery.kind === 1) {
        const store = configDelivery.store;

        vm.configDeliveryInfo = {
          title: `Retiro en tienda ${store.name}`,
          subtitle: `${store.address}. ${store.commune.name}`,
          image: 'assets/img/Retiro.svg',
        };

        // Obtener el día de hoy
        DateService.getCurrentWeekdayStoreService(store).then((value) => {
          vm.configDeliveryInfo.subtitle2 = value;
        });
      } else {
        console.warn(`No existe data para el modo de entrega ${configDelivery.kind}`);
      }
    }
  }
})();
