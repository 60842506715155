(function () {
  'use strict';

  angular.module('shared.navbar').component('navbarComponent', {
    templateUrl: 'shared/navbar/navbar.template.html',
    controller: NavbarController,
    controllerAs: 'vm',
  });

  function NavbarController(
    $rootScope,
    $scope,
    $state,
    AlertService,
    AnalyticsService,
    CookiesService,
    GlobalService,
    MetaTagService,
    NavigationService,
    StoreService,
    UserService
  ) {
    const vm = this;

    $scope.global = GlobalService;

    vm.category = null;
    vm.user = null;
    vm.alcancia = null;

    vm.navUrls = NavigationService.getExternalUrls();
    vm.internalUrls = NavigationService.getInternalUrls();

    // Variable para dejar de escuchar los cambios en el rootScope
    let unregisterUser;
    let unregisterConfigDelivery;
    let unregisterCategories;

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.goToFirstCategory = goToFirstCategory;
    vm.logout = logout;
    vm.openLoginModal = openLoginModal;
    vm.openLogoutAlert = openLogoutAlert;
    vm.scrollTop = scrollTop;

    // Funciones enlazadas al controlador
    $scope.$on('$destroy', unregisterUser);
    $rootScope.$on('openLogoutAlert', vm.openLogoutAlert);

    function onInit() {
      // detectar si el dispositivo es IOS
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        $('.toggle-cart-btn > .cart-icon').addClass('ios-cart-icon');
        $('.login').addClass('ios-login');
        $('.vertical-gray-bar').addClass('ios-vertical-gray-bar');
      }

      // Si el usuario cambia, actualizar la variable del controlador
      unregisterUser = $scope.$watch('global.user', function (newValue) {
        vm.user = newValue;
      });

      $scope.$watch('global.user.unlimited_credits', (newValue) => {
        if (newValue || parseInt(newValue) === 0) {
          vm.alcancia = vm.user.unlimited_credits;
        }
      });

      unregisterConfigDelivery = $scope.$watch('global.configDelivery.store', (newValue) => {
        if (newValue && newValue.id) {
          vm.configDelivery = newValue;
          unregisterConfigDelivery();
        }
      });

      // global.productCategories
      unregisterCategories = $scope.$watch('global.productCategories', (newValue) => {
        if (newValue && newValue.length) {
          vm.category = newValue[0];
          unregisterCategories();
        }
      });
    }

    function goToFirstCategory() {
      $rootScope.$broadcast('changeCategory', {
        categoryId: 34,
        categoryName: 'destacados',
      });

      // Actualizar metatags
      MetaTagService.updateMetaTags('home');
      scrollTop(100);
    }

    function scrollTop(duration) {
      $('html,body').animate({ scrollTop: 0 }, duration);
    }

    function logout() {
      CookiesService.removeUser();
      $state.go('home');
      UserService.getUser().then((value) => {
        GlobalService.user = value.data;
        GlobalService.addresses = [];
        GlobalService.cart = { productos: [] };
        GlobalService.creditCards = [];

        // Desvincular el usuario en Zendesk
        zE('webWidget', 'logout');

        UserService.getUserConfig().then((value) => {
          const userConfig = value.data;
          GlobalService.userConfig = userConfig;
          GlobalService.configDelivery.kind = 1;
          GlobalService.configDelivery.address = null;
          GlobalService.configDelivery.paymentMethod = null;

          StoreService.getStores().then((value) => {
            GlobalService.configDelivery.store =
              value.find((store) => store.id === userConfig.store_id) || value[0];
          });

          AnalyticsService.logout();
        });
      });
    }

    function openLoginModal() {
      $rootScope.$broadcast('openLoginModal', { source: 'navbar' });
      console.info('Broadcast: ¡Abrir modal de inicio de sesión!');
    }

    function openLogoutAlert() {
      AlertService.logout(vm.logout);
    }
  }
})();
