(function () {
  'use strict';

  angular.module('shared.footerSummary').component('footerSummaryComponent', {
    templateUrl: 'shared/footer-summary/footer-summary.template.html',
    controller: FooterSummaryController,
    controllerAs: 'vm',
  });

  function FooterSummaryController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {}
  }
})();
