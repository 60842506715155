(function () {
  'use strict';

  angular.module('shared.configDeliveryModal').component('configDeliveryModalComponent', {
    templateUrl: 'shared/config-delivery-modal/config-delivery-modal.template.html',
    controller: ConfigDeliveryModalController,
    controllerAs: 'vm',
  });

  function ConfigDeliveryModalController(
    $rootScope,
    $scope,
    $state,
    $timeout,
    contentful,
    AlertService,
    AnalyticsService,
    CartService,
    CatalogService,
    DateService,
    GlobalService,
    StoreService,
    UserService
  ) {
    const vm = this;
    $scope.global = GlobalService;

    // Variables para manejar qué mostrar en la vista
    vm.state = 0;
    vm.busy = false;
    vm.currentStep = 1;
    vm.totalSteps = 2;
    vm.firstTime = false;
    vm.welcomeMessage = false;
    vm.modalTitle = '';

    // Variables para saber de dónde se ha llamado el modal
    const ADDRESS_MODAL_FAILURE = 'address-modal-failure',
      ADDRESS_MODAL_SUCCESS = 'address-modal-success',
      CHECKOUT = 'checkout',
      CHECKOUT_WITHOUT_DELIVERY_SCHEDULES = 'checkout-without-delivery-schedules',
      CONFIG_DELIVERY_BAR = 'config-delivery-bar',
      DEFAULT_CONFIG_DELIVERY = 'default-config-delivery',
      FIRST_ADD_TO_CART = 'first-add-to-cart',
      LOGIN_MODAL = 'login-modal',
      LOGIN_MODAL_DEFAULT_CONFIG = 'login-modal-default-config',
      ONBOARDING = 'onboarding';

    // Configuración de entrega
    const DEFAULT_CONFIG_DELIVERY_VALUE = {
      kind: null,
      commune: null,
      store: null,
      address: null,
    };

    vm.newConfigDelivery = Object.assign({}, DEFAULT_CONFIG_DELIVERY_VALUE);
    vm.currentConfigDelivery = Object.assign({}, DEFAULT_CONFIG_DELIVERY_VALUE);

    vm.communes = []; // Comunas
    vm.stores = []; // Tiendas
    vm.addresses = []; // Direcciones
    vm.availableAddresses = []; // Direcciones disponibles
    vm.unavailableAddresses = []; // Direcciones no disponibles
    vm.cart = null; // Carro de compras
    vm.user = null; // Usuario

    // Utils
    vm.dropItems = []; // Objetos del carro de compras que se perderán durante el proceso
    vm.weekday = null; // Día de hoy
    vm.deliveryAvailable = false; // Disponibilidad de delivery
    vm.deliveryNextDay = false; // Delivery en el día siguiente

    // Alerta del modal de configuración de entrega
    vm.alertType = '';
    vm.alertTitle = '';
    vm.alertMessage = '';

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.closeConfigDeliveryModal = closeConfigDeliveryModal;
    vm.goToCheckout = goToCheckout;
    vm.goToHome = goToHome;
    vm.openAddressModal = openAddressModal;
    vm.openLoginModal = openLoginModal;
    vm.openRegisterModal = openRegisterModal;
    vm.saveConfigDelivery = saveConfigDelivery;
    vm.selectAddress = selectAddress;
    vm.selectCommune = selectCommune;
    vm.selectKind = selectKind;
    vm.selectStore = selectStore;
    vm.stepBack = stepBack;
    vm.stepForward = stepForward;

    // Functiones enlazadas al controlador
    $scope.adjustCart = adjustCart;
    $scope.checkCart = checkCart;
    $scope.checkSchedules = checkSchedules;
    $scope.loadAlertData = loadAlertData;
    $scope.openConfigDeliveryModal = openConfigDeliveryModal;
    $scope.updateAddresses = updateAddresses;
    $scope.updateTodayServices = updateTodayServices;

    // Funciones enlazadas al rootScope
    $rootScope.$on('openConfigDeliveryModal', openConfigDeliveryModal);

    function onInit() {
      // Obtener el día de hoy
      DateService.getCurrentWeekdayString().then((value) => {
        vm.weekday = value;
      });

      // Si la configuración de entrega cambia, actualizar la variable de la vista
      $scope.$watch('global.configDelivery', (newValue) => {
        vm.currentConfigDelivery = newValue;
      });

      // Si la lista de direcciones cambia, actualizar la variable del controlador
      $scope.$watch('global.addresses', (newValue) => {
        if (newValue) {
          $scope.updateAddresses();
        }
      });

      // Si el carro cambia o es ajustado, actualizar la variable del controlador
      $scope.$watch('global.cart', (newValue, oldValue) => {
        vm.cart = newValue;

        // Actualizar los servicios de las tiendas si es que cambia el carro. ¿Por qué? Porque los mensajes varían
        // si tiene productos de consumo inmediato o no
        if (!oldValue || newValue.consumo_inmediato !== oldValue.consumo_inmediato) {
          $scope.updateTodayServices();
        }
      });

      // Si el usuario cambia, actualizar la variable del controlador
      $scope.$watch('global.user', (newValue) => {
        vm.user = newValue;
      });

      // Verificar si hay alertas para mostrar en el modal de configuración de entrega
      contentful.entries('content_type=alert').then((value) => {
        const data = value.data;

        if (data.total) {
          let index;

          // Verificar si alguna de esas alertas pertenecen al modal de configuración de entrega
          data.items.forEach(function (item) {
            if (item.fields && item.fields.showIn && item.fields.showIn.length) {
              index = item.fields.showIn.findIndex(
                (component) => component === 'Config Delivery Modal'
              );

              // Si pertenece...
              if (index !== -1) {
                // Evaluar si la alerta se encuentra dentro de la fecha
                if (item.fields.begins && item.fields.ends) {
                  DateService.getCurrentTimestamp().then((value) => {
                    const today = value * 1000;
                    const alertBegins = item.fields.begins;
                    const alertEnds = item.fields.ends;

                    // ¿La alerta se encuentra dentro de la fecha?
                    if (moment(today).isAfter(alertBegins) && moment(today).isBefore(alertEnds)) {
                      $scope.loadAlertData(item.fields);
                    }
                  });
                } else {
                  $scope.loadAlertData(item.fields);
                }
              }
            }
          });
        }
      });
    }

    /**
     * Ajustar el carro de compras
     * @param {object[]} dropItems Objetos que se eliminarán del carro
     */
    function adjustCart(dropItems) {
      vm.busy = true;

      if (vm.newConfigDelivery.kind === 0) {
        vm.newConfigDelivery.store = vm.stores.find(
          (store) => store.id === vm.newConfigDelivery.address.store_id
        );
      }

      CartService.adjustCart(vm.cart, dropItems, vm.newConfigDelivery)
        .then((value) => {
          GlobalService.cart = value.data.response;
          GlobalService.configDelivery.kind = vm.newConfigDelivery.kind;

          vm.saveConfigDelivery();
        })
        .catch(() => {
          vm.busy = false;
        });
    }

    /**
     * Función recursiva que se llama a sí misma si no hay bloques de reparto disponibles.
     * Deja de llamarse una vez se hayan validado todas las tiendas o si alguna tiene bloques de reparto.
     * @param {object} store Tienda
     * @param {number} index Índice de la tienda
     */
    function checkSchedules(store, index) {
      vm.deliveryAvailable = false;
      vm.deliveryNextDay = false;

      if (vm.stores && vm.stores.length) {
        if (vm.currentStep <= 1) {
          vm.busy = true;
        }

        StoreService.getSchedule(store.id)
          .then((value) => {
            const store = value.data;

            if (store && store.available_schedules && store.available_schedules.length) {
              vm.deliveryAvailable = true;

              if (store.available_schedules[0].dia) {
                vm.deliveryNextDay = true;
              }
            } else if (index + 1 < vm.stores.length) {
              $scope.checkSchedules(vm.stores[index + 1], index + 1);
            }
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            if (vm.currentStep <= 1) {
              vm.busy = false;
            }
          });
      }
    }

    /**
     * Verificar carro de compras.
     * Siempre se debe hacer ésta validación cada vez que se cambie la configuración de entrega
     */
    function checkCart() {
      if (vm.cart.productos.length) {
        if (vm.newConfigDelivery.kind === 0) {
          vm.newConfigDelivery.store = { id: vm.newConfigDelivery.address.store_id };
        }

        CartService.checkCart(vm.newConfigDelivery.store).then((value) => {
          const dropItems = value.data.response.productosNoEncontrados;

          if (!dropItems || !dropItems.length) {
            vm.saveConfigDelivery();
          } else {
            // Mostrar alerta del sistema
            AlertService.stockDifference(
              vm.newConfigDelivery,
              dropItems,
              function () {
                return $scope.adjustCart(dropItems);
              },
              function () {
                vm.busy = false;
              }
            );
          }
        });
      } else {
        vm.saveConfigDelivery();
      }
    }

    function closeConfigDeliveryModal() {
      $('#config-delivery-modal').modal('hide');

      // Mostrar el mensaje del navbar, si es que el usuario está destarcando el modal de configuración de entrega
      // con la configuración por defecto
      if (
        GlobalService.userConfig.default &&
        (vm.state === FIRST_ADD_TO_CART || vm.state === DEFAULT_CONFIG_DELIVERY)
      ) {
        $rootScope.$broadcast('showNavbarMessage', {
          type: 'info',
          title: `Estás viendo el stock disponible en la tienda ${GlobalService.configDelivery.store.name}`,
        });
        console.info('Broadcast: ¡Mostrar el mensaje del navbar!');
      } else {
        $rootScope.$broadcast('dismissNavbarMessage');
        console.info('Broadcast: ¡Ocultar el mensaje del navbar!');
      }
    }

    /**
     * Finalizar configuración de entrega y mostrar checkout
     */
    function goToCheckout() {
      $('#config-delivery-modal').modal('hide');
      if ($state.current.name !== 'checkout') {
        $state.go('checkout', { stepName: 'entrega' });
      }
    }

    function goToHome() {
      $('#config-delivery-modal').modal('hide');
      if ($state.current.name !== 'home') {
        $state.go('home');
      }
    }

    /**
     * Cargar la información de la alerta que se mostrará en el primer paso de la configuración de entrega
     * @param item - Objeto de contentful que representa el alerta
     */
    function loadAlertData(item) {
      vm.alertType = item.type;
      vm.alertTitle = item.title;
      vm.alertMessage = item.message ? item.message.fields.text : '';
    }

    /**
     * Abrir modal de direcciones
     */
    function openAddressModal() {
      $('#config-delivery-modal').modal('hide');

      $timeout(() => {
        $rootScope.$broadcast('openAddressModal', { source: 'config-delivery-modal' });
        console.info('Broadcast: ¡Abrir el modal de dirección!');
      });
    }

    /**
     * Abrir modal de inicio de sesión
     */
    function openLoginModal(source) {
      $('#config-delivery-modal').modal('hide');

      $timeout(() => {
        $rootScope.$broadcast('openLoginModal', { source });
        console.info('Broadcast: ¡Abrir modal de inicio de sesión!');
      });
    }

    function openRegisterModal(source) {
      $('#config-delivery-modal').modal('hide');

      $timeout(() => {
        $rootScope.$broadcast('openRegisterModal', { source });
        console.info('Broadcast: ¡Abrir modal de inicio de sesión!');
      });
    }

    /**
     * Guardar configuración de entrega
     */
    function saveConfigDelivery() {
      let configDelivery = null;

      if (vm.newConfigDelivery.kind === 0 || vm.newConfigDelivery.kind === 1) {
        configDelivery = {
          kind: vm.newConfigDelivery.kind,
          store: !vm.newConfigDelivery.address
            ? vm.newConfigDelivery.store
            : { id: vm.newConfigDelivery.address.store_id },
          address: vm.newConfigDelivery.address,
          paymentMethod: vm.currentConfigDelivery.paymentMethod,
        };

        GlobalService.configDelivery = configDelivery;
      } else {
        configDelivery = vm.currentConfigDelivery;
      }

      // Además de la configuración de entrega, el método de pago podría verse afectado por el cambio
      if (GlobalService.configDelivery.paymentMethod) {
        if (
          (GlobalService.configDelivery.paymentMethod.kind === 1 && configDelivery.kind === 0) ||
          (GlobalService.configDelivery.paymentMethod.kind === 2 && configDelivery.kind === 1)
        ) {
          configDelivery.paymentMethod = null;
        }
      }

      // La configuración de entrega ya no es por defecto porque el usuario ya tomó una desición
      GlobalService.userConfig.default = false;
      UserService.setUserConfig(configDelivery).then((value) => {
        vm.currentConfigDelivery = configDelivery;
        GlobalService.userConfig = value.data;
        console.info('Configuración de entrega: ', configDelivery);
        console.info('Configuración del usuario: ', value.data);
      });

      CartService.setCart(vm.cart).then((value) => {
        GlobalService.cart = value.data.response;
      });

      CatalogService.updateCatalog();

      // Ocultar el mensaje en el navbar, debido a que el usuario ya ha tomado una desición
      $rootScope.$broadcast('dismissNavbarMessage');
      console.info('Broadcast: ¡Ocultar el mensaje del navbar!');

      // Si se está cambiando la configuración de entrega desde el checkout, no mostrar mensaje de confirmación
      if (
        vm.firstTime ||
        vm.state === CHECKOUT ||
        vm.state === CHECKOUT_WITHOUT_DELIVERY_SCHEDULES
      ) {
        vm.closeConfigDeliveryModal();
      } else {
        vm.currentStep++;
        vm.busy = false;
      }
    }

    /**
     * Seleccionar dirección
     * @param {any} address
     */
    function selectAddress(address) {
      $rootScope.$broadcast('storeChanged',  address );
      if (!address.delivery_available) {
        AlertService.addressWithoutDelivery(function () {
          vm.busy = false;
        });
      } else if (!address.store_id) {
        AlertService.addressFromClosedStore(address, null);
        vm.busy = false;
      } else {
        vm.newConfigDelivery.address = address;
        vm.busy = true;
        $scope.checkCart();
      }
    }

    /**
     * Seleccionar comuna
     * @param commune
     */
    function selectCommune(commune) {
      vm.newConfigDelivery.commune = commune;
      vm.currentStep++;
    }

    /**
     * Seleccionar tipo de entrega
     * @param {number} kind Reparto a domicilio (0) o Retiro en tienda (1)
     */
    function selectKind(kind) {
      // Por tratarse del primer paso, limpiamos todas las variables de configuración de entrega
      vm.newConfigDelivery = {
        kind: kind,
        address: null,
        commune: null,
        store: null,
      };

      vm.currentStep++;

      // Separar las tiendas con delivery que tienen una tienda activa, con las que no
      if (kind === 0 && vm.addresses.length) {
        $scope.updateAddresses();
      }

      // Enviar analíticas del tipo de entrega que seleccionó
      AnalyticsService.selectKindDelivery(kind);
    }

    /**
     * Seleccionar tienda
     * @param {any} store
     */
    function selectStore(store) {
      vm.newConfigDelivery.store = store;
      $scope.global.configDelivery.store = store
      $rootScope.$broadcast('storeChanged');
      vm.busy = true;
      $scope.checkCart();
    }

    function stepBack() {
      vm.currentStep--;

      // No permitir devolverse al paso 0 o -1...
      if (vm.currentStep < 1) {
        vm.currentStep = 1;
      } else if (vm.currentStep === 2 && vm.newConfigDelivery.kind === 0) {
        // Actualizar la lista de direcciones, si es que el usuario ha selecionado delivery y se devolvió al paso 2
        $scope.updateAddresses();
      }
    }

    function stepForward() {
      vm.currentStep++;
    }

    /**
     * Abrir el modal de configuración de entrega.
     * Los parámetros son necesarios para tomar desiciones acerca de cómo mostrar el componente,
     * debido a que el proceso para agregar una nueva dirección se encuentra en otro componente
     * @param {number} opt.state (Opcional) Paso en la que se debe mostrar la configuración de entrega
     * @param {string|object} opt.data (Opcional) Información adicional
     */
    function openConfigDeliveryModal(event, opt) {
      vm.state = opt.state;
      vm.recommendedStore =
        vm.currentConfigDelivery && vm.currentConfigDelivery.address
          ? vm.currentConfigDelivery.address.store_id
          : null;
      vm.currentStep = 1;

      vm.addressWarning = '';
      vm.firstTime = false;
      vm.welcomeMessage = false;

      vm.modalTitle = 'Personaliza tu pedido';

      switch (vm.state) {
        case DEFAULT_CONFIG_DELIVERY:
          vm.newConfigDelivery = Object.assign({}, DEFAULT_CONFIG_DELIVERY_VALUE);
          vm.currentStep = 1;
          vm.welcomeMessage = true;
          vm.modalTitle = '¡Bienvenido!';
          break;
        case FIRST_ADD_TO_CART:
          vm.newConfigDelivery = Object.assign({}, vm.currentConfigDelivery);
          vm.firstTime = true;
          vm.currentStep = 0;
          vm.modalTitle = 'Confírmanos';
          break;
        case CHECKOUT:
        case CONFIG_DELIVERY_BAR:
        case LOGIN_MODAL_DEFAULT_CONFIG:
          vm.currentStep = 1;
          break;
        case ADDRESS_MODAL_SUCCESS:
          vm.busy = true;
          vm.currentStep = 2;
          $scope.updateAddresses();
          vm.selectAddress(opt.data);
          break;
        case CHECKOUT_WITHOUT_DELIVERY_SCHEDULES:
          vm.newConfigDelivery.kind = 1;
          vm.currentStep = 2;
          break;
        case ADDRESS_MODAL_FAILURE:
          vm.addressWarning = opt.data;
          vm.newConfigDelivery.kind = 0;
          vm.currentStep = 2;
          break;
        case LOGIN_MODAL:
          vm.newConfigDelivery.kind = 0;
          vm.currentStep = 2;
          break;
        case ONBOARDING:
          vm.busy = true;
          vm.currentStep = 2;
          vm.newConfigDelivery = GlobalService.configDelivery;
          vm.selectAddress(opt.data);
          break;
      }

      // Cargar lista de tiendas
      if (!vm.stores.length) {
        StoreService.getStores().then((value) => {
          vm.stores = value;
          $scope.checkSchedules(vm.stores[0], 0);
          $scope.updateTodayServices();
        });
      } else {
        $scope.checkSchedules(vm.stores[0], 0);
      }

      // Cargar lista de comunas
      if (!vm.communes.length) {
        StoreService.getStoresByCommune().then((value) => {
          vm.communes = value;
        });
      }

      $('#config-delivery-modal').modal('show');

      // Enviar analíticas
      AnalyticsService.initConfigDelivery(vm.state);
    }

    /**
     * Actualiza la lista de direcciones
     */
    function updateAddresses() {
      vm.addresses = GlobalService.addresses;
      vm.availableAddresses = [];
      vm.unavailableAddresses = [];

      if (vm.addresses && vm.addresses.length) {
        vm.addresses.forEach((address) => {
          if (address.delivery_available) {
            vm.availableAddresses.push(address);
          } else {
            vm.unavailableAddresses.push(address);
          }
        });
      }
    }

    /**
     * Actualiza el servicio de cada tienda
     * Solo sí hay un carro de compras. ¿Por qué? Porque el mensaje depende de si tiene productos en el carro o no
     */
    function updateTodayServices() {
      if (vm.cart) {
        vm.stores.forEach((store) => {
          DateService.getCurrentWeekdayStoreService(store).then((value) => {
            store.todayService = value;
          });
        });
      }
    }
  }
})();
