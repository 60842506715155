(function () {
  'use strict';

  angular.module('shared.loginModal').component('loginModalComponent', {
    templateUrl: 'shared/login-modal/login-modal.template.html',
    controller: LoginModalController,
    controllerAs: 'vm',
  });

  function LoginModalController(
    $rootScope,
    $scope,
    $state,
    $timeout,
    AnalyticsService,
    GlobalService,
    LogService
  ) {
    var vm = this;
    var CTRL_NAME = vm.constructor.name;

    // Variables para saber de dónde se ha llamado el modal
    const CHECKOUT = 'checkout',
      CONFIG_DELIVERY = 'config-delivery-modal',
      CONFIG_DELIVERY_WELCOME = 'config-delivery-modal-welcome',
      FORK_PIGGY_BANK = 'fork-piggy-bank',
      NAVBAR = 'navbar',
      ONBOARDING = 'onboarding';

    // Variables para manejar qué formulario mostrar
    var LOGIN = 'login',
      REGISTER = 'register',
      PASSWORD = 'forgot-password',
      PASSWORD_SUCCESS = 'forgot-password-success';

    // Variables de los títulos posibles del modal
    var LOGIN_TITLE = '¡Bienvenido a Fork!',
      LOGIN_SUBTITLE = 'Ricos platos preparados para\ndelivery o retiro en tienda',
      LOGIN_TITLE_CLASS = 'login-title',
      LOGIN_SUBTITLE_CLASS = 'login-subtitle',
      ONBOARDING_TITLE = 'Primero, lo primero',
      ONBOARDING_SUBTITLE = 'Para agregar direcciones a tu cuenta, debes tener una cuenta ¡Obvio!';

    var FORGOT_PASSWORD_TITLE = 'Recuperar contraseña',
      FORGOT_PASSWORD_SUBTITLE =
        'Ingresa tu email y te enviaremos instrucciones para restablecer tu contraseña',
      FORGOT_PASSWORD_TITLE_CLASS = 'forgot-password-title',
      FORGOT_PASSWORD_SUBTITLE_CLASS = 'forgot-password-subtitle';

    var FORGOT_PASSWORD_SUCCESS_TITLE = '¡Listo!',
      FORGOT_PASSWORD_SUCCESS_SUBTITLE =
        'Revisa tu email y sigue las instrucciones para establecer la contraseña.';

    // Variable para manejar qué mostrar en la vista
    vm.source = '';
    vm.currentForm = '';
    vm.busy = false;
    vm.rutReason = false;
    vm.currentStep = 1;
    vm.title = '';
    vm.subtitle = '';
    vm.titleClass = LOGIN_TITLE_CLASS;
    vm.subtitleClass = LOGIN_SUBTITLE_CLASS;

    // Mostrar Mensaje activación cuenta usuario nuevo
    vm.showActivateAccountMessage = false;
    vm.emailActivateAccount = '';

    // Funciones enlazadas con la vista
    vm.changeForm = changeForm;
    vm.closeLoginModal = closeLoginModal;
    vm.prevStep = prevStep;

    $scope.updateModalTitle = updateModalTitle;

    // Funciones enlazadas al rootScope
    $rootScope.$on('openLoginModal', openLoginModal);
    $rootScope.$on('openRegisterModal', openRegisterModal);
    $rootScope.$on('openForgotPasswordModal', openForgotPasswordModal);

    /**
     * Cambiar formulario
     * @param form {string}
     */
    function changeForm(form) {
      vm.currentForm = form;
      vm.currentStep = 1;

      $scope.updateModalTitle();

      if (form === REGISTER) {
        AnalyticsService.initRegister();
      }
    }

    /**
     * Cerrar modal de inicio de sesión
     * @param success
     */
    function closeLoginModal(success) {
      $('#login-modal').modal('hide');

      // Reset valores activación cuenta
      vm.showActivateAccountMessage = false;
      vm.emailActivateAccount = '';

      $timeout(function () {
        if (vm.source === CONFIG_DELIVERY) {
          if (success && !GlobalService.addresses.length) {
            $rootScope.$broadcast('openAddressModal', { source: 'config-delivery-modal' });
            console.info('Broadcast: ¡Abrir modal de dirección!');
          } else {
            $rootScope.$broadcast('openConfigDeliveryModal', { state: 'login-modal' });
            console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
          }
        } else if (vm.source === CONFIG_DELIVERY_WELCOME) {
          if (success && GlobalService.userConfig.default) {
            $rootScope.$broadcast('openConfigDeliveryModal', {
              state: 'login-modal-default-config',
            });
            console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
          }
        } else if (vm.source === ONBOARDING && success) {
          $rootScope.$broadcast('openAddressModal', { source: 'onboarding' });
          console.info('Broadcast: ¡Abrir modal de dirección!');
        } else if (vm.source === FORK_PIGGY_BANK && success) {
          $state.go('user.piggyBank');
        } else if (vm.source !== NAVBAR && vm.source !== CHECKOUT) {
          LogService.unexpectedCase(
            CTRL_NAME,
            'Se está cerrando el modal de inicio de sesión desde un origen desconocido:' + vm.source
          );
        }
      });
    }

    /**
     * Abrir modal de inicio de sesión
     * @param event
     * @param opt
     */
    function openLoginModal(event, opt) {
      vm.source = opt.source;
      vm.currentForm = LOGIN;
      vm.busy = false;

      // Reset valores activación cuenta
      vm.showActivateAccountMessage = false;
      vm.emailActivateAccount = '';

      $scope.updateModalTitle();

      $('#login-modal').modal('show');

      AnalyticsService.initLogin(opt.source);
    }

    /**
     * Abrir modal de inicio de sesión en el paso de registro
     * @param {object} event
     * @param {object} opt
     */
    function openRegisterModal(event, opt) {
      vm.source = opt.source;
      vm.currentForm = REGISTER;
      vm.busy = false;

      $scope.updateModalTitle();

      $('#login-modal').modal('show');

      AnalyticsService.initLogin(opt.source);
    }

    function openForgotPasswordModal(event, opt) {
      vm.source = opt.source;
      vm.currentForm = PASSWORD;
      vm.busy = false;

      $scope.updateModalTitle();

      $('#login-modal').modal('show');

      AnalyticsService.initLogin(opt.source);
    }

    /**
     * Volver al paso anterior
     */
    function prevStep() {
      if (vm.currentForm === PASSWORD || vm.currentForm === PASSWORD_SUCCESS) {
        vm.changeForm(LOGIN);
      } else {
        vm.currentStep--;
        AnalyticsService.clickPrevRegisterStep(vm.currentStep);
      }
    }

    /**
     * Actualiza el título del login, dependiendo si viene del flujo onboarding o no
     */
    function updateModalTitle() {
      if (vm.currentForm === LOGIN || vm.currentForm === REGISTER) {
        vm.titleClass = LOGIN_TITLE_CLASS;
        vm.subtitleClass = LOGIN_SUBTITLE_CLASS;

        if (vm.source !== ONBOARDING) {
          vm.title = LOGIN_TITLE;
          vm.subtitle = LOGIN_SUBTITLE;
        } else {
          vm.title = ONBOARDING_TITLE;
          vm.subtitle = ONBOARDING_SUBTITLE;
        }
      } else if (vm.currentForm === PASSWORD || vm.currentForm === PASSWORD_SUCCESS) {
        vm.titleClass = FORGOT_PASSWORD_TITLE_CLASS;
        vm.subtitleClass = FORGOT_PASSWORD_SUBTITLE_CLASS;

        if (vm.currentForm === PASSWORD) {
          vm.title = FORGOT_PASSWORD_TITLE;
          vm.subtitle = FORGOT_PASSWORD_SUBTITLE;
        } else {
          vm.title = FORGOT_PASSWORD_SUCCESS_TITLE;
          vm.subtitle = FORGOT_PASSWORD_SUCCESS_SUBTITLE;
        }
      } else {
        LogService.unexpectedCase(
          CTRL_NAME,
          'Se está intentando cambiar a un formulario que no existe: ' + vm.currentForm
        );
      }
    }
  }
})();
