(function () {
  'use strict';

  angular.module('services.metaTag').service('MetaTagService', MetaTagService);

  function MetaTagService($location, $rootScope, $sce) {
    const defaultTitle = 'Fork | Platos preparados para todos los días';
    const defaultDescription =
      'La comida está lista. Platos preparados para todos los días. Fondos, pastas, ensaladas, postres, panadería. Compra online con delivery o retiro en tienda.';
    const defaultUrl = $location.absUrl();
    const defaultImage = `${$location.host()}/assets/img/logo-fork.png`;
    const defaultImageWidth = '84';
    const defaultImageHeight = '49';
    const defaultType = 'website';

    return {
      updateMetaTags: updateMetaTags,
    };

    /**
     * Actualizar los meta tags del sitio web
     * @param state Estado o ruta en la que se encuentra la aplicación
     * @param data Información adicional
     */
    function updateMetaTags(state, data) {
      if (state === 'home.category') {
        // Meta tags por categoría
        $rootScope.meta = {
          title: `Fork | ${data.nombre} | Online, Tiendas, Delivery`,
          description: defaultDescription,
          url: defaultUrl,
          image: defaultImage,
          imageWidth: defaultImageWidth,
          imageHeight: defaultImageHeight,
          type: defaultType,
        };
      } else if (state === 'productDetails') {
        // Meta tags por producto
        $rootScope.meta = {
          title: `Fork | ${data.title} ${data.subtitle} | Online, Tiendas, Delivery`,
          description: defaultDescription,
          url: defaultUrl,
          image: $sce.trustAsResourceUrl(data.thumbnail_url),
          imageWidth: '830',
          imageHeight: '700',
          type: 'product',
          availability: 'instock',
        };
      } else {
        // Meta tags por defecto
        $rootScope.meta = {
          title: defaultTitle,
          description: defaultDescription,
          url: defaultUrl,
          image: defaultImage,
          imageWidth: defaultImageWidth,
          imageHeight: defaultImageHeight,
          type: defaultType,
        };
      }
    }
  }
})();
