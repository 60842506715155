(function () {
  'use strict';

  angular
    .module('pages.activateAccount')
    .controller('ActivateAccountController', ActivateAccountController);

  function ActivateAccountController(
    AnalyticsService,
    ZendeskWidget,
    $rootScope,
    $state,
    $location,
    UserService
  ) {
    var vm = this;

    vm.$onInit = onInit;
    vm.showZendeskHelp = showZendeskHelp;
    vm.goToHome = goToHome;
    vm.busy = true;
    vm.requestResponseStatus = true;
    vm.successRequestMessage = '';
    vm.hashActivacion = $location.path().split('/')[2];

    function onInit() {
      UserService.activateNewUserAccount(vm.hashActivacion)
        .then((value) => {
          if (value.data.registrado && !value.data.message.includes('activado')) {
            vm.successRequestMessage = '¡Tu cuenta ha sido activada correctamente!';
            // Enviar analíticas
            AnalyticsService.userRegister(value.data.usuario_id);
          }
          if (value.data.registrado && value.data.message.includes('activado')) {
            vm.successRequestMessage = 'Esta cuenta ya se encuentra activada.';
          }
          vm.requestResponseStatus = value.data.registrado;
          vm.busy = false;
        })
        .catch((reason) => {
          vm.requestResponseStatus = false;
          vm.busy = false;
        });
    }

    function showZendeskHelp() {
      ZendeskWidget.activate();
    }

    function goToHome() {
      $rootScope.$broadcast('changeCategory', {
        categoryId: 34,
        categoryName: 'destacados',
      });

      $state.go('home');
    }
  }
})();
