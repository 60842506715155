(function () {
  'use strict';

  angular.module('services.date').service('DateService', DateService);

  function DateService($filter, $http, $q, config, GlobalService) {
    let currentWeekday = '';
    let currentTimestamp = 0;

    return {
      getCurrentTimestamp: getCurrentTimestamp,
      getCurrentWeekdayNumber: getCurrentWeekdayNumber,
      getCurrentWeekdayString: getCurrentWeekdayString,
      getCurrentWeekdayStoreService: getCurrentWeekdayStoreService,
      getMonthString: getMonthString,
      getDateFestivity: getDateFestivity,
    };

    function getCurrentTimestamp() {
      if (!currentTimestamp) {
        currentTimestamp = $http
          .get(`${config.URL_API_UTIL}/time`)
          .then((value) => {
            let time = value.data.time;
            console.info(`Marca de tiempo: ${time}`);
            return time;
          })
          .catch((reason) => {
            // Si no se puede obtener la hora del servidor,
            // como última instancia devolvemos la hora del cliente.
            const time = Date.now() / 1000;
            console.info(`Marca de tiempo del cliente: ${time}`);
            return time;
          });
      }
      return currentTimestamp;
    }

    /**
     * Obtiene el número de la semana que corresponde el día actual, siendo 0 el lunes y 6 el domingo
     * @returns {*}
     */
    function getCurrentWeekdayNumber() {
      const defer = $q.defer();

      if (currentWeekday) {
        defer.resolve(currentWeekday);
      } else {
        getCurrentTimestamp()
          .then((value) => {
            const today = new Date(value * 1000);
            const weekday = today.getDay();

            // Los días de la semana comienzan desde el domingo (0) hasta el sábado (6).
            // Sin embargo, la api retorna los días de servicio de las tiendas desde el día lunes (0)
            // hasta el domingo (6)
            currentWeekday = weekday === 0 ? 6 : weekday - 1;

            defer.resolve(currentWeekday);
          })
          .catch((response) => {
            defer.reject(response.data);
          });
      }

      return defer.promise;
    }

    /**
     * Obtiene el string del día de la semana actual
     * @returns {*}
     */
    function getCurrentWeekdayString() {
      const defer = $q.defer();

      getCurrentWeekdayNumber().then((value) => {
        let weekDay = '';
        switch (value) {
          case 0:
            weekDay = 'LUNES';
            break;
          case 1:
            weekDay = 'MARTES';
            break;
          case 2:
            weekDay = 'MIERCOLES';
            break;
          case 3:
            weekDay = 'JUEVES';
            break;
          case 4:
            weekDay = 'VIERNES';
            break;
          case 5:
            weekDay = 'SABADO';
            break;
          default:
            weekDay = 'DOMINGO';
            break;
        }

        defer.resolve(weekDay);
      });

      return defer.promise;
    }

    function getCurrentWeekdayStoreService(store) {
      const defer = $q.defer();
      const holiday = getDateFestivity();
      const preHoliday = getDatePreFestivity();
      const sucursalesCierranTemprano = [36, 26, 22, 21];

      if (store && store.service && store.service.length) {
        getCurrentWeekdayString().then((value) => {
          const weekday = value;
          const todayService = store.service.find(
            (service) => service.weekday.toUpperCase() === weekday
          );

          console.log(store);

          if (todayService) {
            let message = '';
            const open = $filter('shortTime')(todayService.open);
            let close = $filter('shortTime')(todayService.close);
            if (!store.retiro) {
              if (holiday) {
                close = '18';
              }
              message = `Entre las ${open} y ${close} hrs`;
            } else if (
              (!GlobalService.cart || !GlobalService.cart.consumo_inmediato) &&
              !(holiday || preHoliday)
            ) {
              message =
                store.id === 44
                  ? `Sólo hoy entre ${open} y ${close} hrs`
                  : `Hoy o mañana entre ${open} y ${close} hrs`;
            } else {
              let horaCierreAntesFeriado = sucursalesCierranTemprano.includes(store.id)
                ? '19:00'
                : '19:00';
              console.log(preHoliday);
              message = preHoliday
                ? `Hoy hasta las ${close} hrs \n y mañana entre 9 y ${horaCierreAntesFeriado} hrs`
                : `Solo hoy entre las 9:00 y ${horaCierreAntesFeriado} hrs`;
            }

            defer.resolve(message);
          }
        });
      } else {
        defer.resolve('');
      }

      return defer.promise;
    }

    function getMonthString(month) {
      switch (month) {
        case 1:
          return 'enero';
        case 2:
          return 'febrero';
        case 3:
          return 'marzo';
        case 4:
          return 'abril';
        case 5:
          return 'mayo';
        case 6:
          return 'junio';
        case 7:
          return 'julio';
        case 8:
          return 'agosto';
        case 9:
          return 'septiembre';
        case 10:
          return 'octubre';
        case 11:
          return 'noviembre';
        default:
          return 'diciembre';
      }
    }

    function getDateFestivity() {
      var dateNow = moment().format('DD/MM');
      switch (dateNow) {
        case '24/12':
        case '31/12':
        case '30/04':
          return true;
        default:
          return false;
      }
    }

    function getDatePreFestivity() {
      var dateNow = moment().format('DD/MM');
      switch (dateNow) {
        case '29/04':
          return true;
        case '23/12':
          return true;
        case '30/12':
          return true;
        default:
          return false;
      }
    }
  }
})();
