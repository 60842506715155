(function () {
  'use strict';

  angular
    .module('pages.userProfile.piggyBank')
    .controller('PiggyBankController', PiggyBankController);

  function PiggyBankController(
    $anchorScroll,
    $rootScope,
    $scope,
    AnalyticsService,
    GlobalService,
    UserService
  ) {
    var vm = this;

    $scope.global = GlobalService;

    vm.busy = false;
    vm.discounts = [];

    vm.$onInit = onInit;
    vm.openPromoCodeModal = openPromoCodeModal;

    $scope.updatePromos = updatePromos;

    $rootScope.$on('promoCodeMessage', onPromoCodeMessage);
    $rootScope.$on('updatePromos', updatePromos);

    function onInit() {
      $anchorScroll();
      $scope.updatePromos();
      AnalyticsService.profileView('/codigos-promo');
    }

    function onPromoCodeMessage(event, opt) {
      vm.message = opt.message;
      vm.type = 'success';
    }

    function openPromoCodeModal() {
      $rootScope.$broadcast('openPromoCodeModal', { source: 'profile' });
      console.info('Broadcast: ¡Abrir modal de códigos de promoción!');
    }

    function updatePromos() {
      vm.busy = true;
      UserService.getUserDiscounts().then(function (value) {
        vm.discounts = value.data;
        vm.busy = false;
      });
    }
  }
})();
