(function () {
  'use strict';

  angular.module('core.directives', [
    'directives.confettiOnHover',
    'directives.emojiFormRestrict',
    'directives.equalityFormValidation',
    'directives.fixedTop',
    'directives.fullViewportHeight',
    'directives.latin1FormValidation',
    'directives.productSchema',
    'directives.resizeEvent',
    'directives.rutFormValidation',
    'directives.scrollEvent',
    'directives.sticky',
    'directives.truncateMultilineText',
    'directives.uniqueEmailFormValidation',
    'directives.uniqueRutFormValidation',
  ]);
})();
