(function () {
  'use strict';

  angular
    .module('pages.recoverPassword.newPassword')
    .controller('NewPasswordController', NewPasswordController);

  function NewPasswordController($state, $stateParams, $timeout, UserService) {
    var vm = this;

    vm.email = '';
    vm.newPassword = '';
    vm.repeatNewPassword = '';

    vm.showNewPassword = false;
    vm.showRepeatNewPassword = false;

    vm.message = '';
    vm.messageType = '';

    vm.busy = false;

    vm.$onInit = onInit;
    vm.changePassword = changePassword;
    vm.toggleNewPassword = toggleNewPassword;
    vm.toggleRepeatPassword = toggleRepeatPassword;

    function onInit() {
      vm.email = $stateParams.email;
    }

    function changePassword() {
      vm.busy = true;

      var id = $stateParams.user_id;
      var email = $stateParams.email;
      var token = $stateParams.token;

      var form = {
        contrasenia: vm.newPassword,
        contrasenia2: vm.repeatNewPassword,
      };

      UserService.changePassword(id, email, token, form).then(
        function () {
          vm.message = 'Tu contraseña ha sido guardada correctamente';
          vm.messageType = 'success';
          $timeout(function () {
            $state.go('home');
          }, 3000);
        },
        function (reason) {
          vm.message = reason.message;
          vm.messageType = 'warning';
          vm.busy = false;
        }
      );
    }

    function toggleNewPassword() {
      vm.showNewPassword = !vm.showNewPassword;
    }

    function toggleRepeatPassword() {
      vm.showRepeatNewPassword = !vm.showRepeatNewPassword;
    }
  }
})();
