(function () {
  'use strict';

  angular.module('services.payment').service('PaymentService', PaymentService);

  function PaymentService($http, config, GlobalService) {
    return {
      getCreditCards: getCreditCards,
      getPaymentMethods: getPaymentMethods,
      addCardPayment: addCardPayment,
      addEdenredCardPayment: addEdenredCardPayment,
      finalizePayment: finalizePayment,
      deletePayment: deletePayment,
      getPayments: getPayments,
    };

    function getCreditCards() {
      return $http.get(`${config.URL_API}/payments`).then((value) => {
        // Ojo, devolver sólo las tarjetas de crédito
        const creditCards = value.data.payments.filter((el) => el.id >= 10);
        return { data: creditCards };
      });
    }

    function getPaymentMethods() {
      let paymentMethods = GlobalService.cart.metodosPago;
      console.log('GlobalService.cart.metodosPago');
      console.log(GlobalService.cart.metodosPago);

      // El carro no nos da mucha información de los métodos de pago. Hay que
      // agregarla, si es que se encuentra en la lista ¯\_(ツ)_/¯
      let webpayPlus = paymentMethods.find((paymentMethod) => paymentMethod.id === 4);
      if (webpayPlus) {
        webpayPlus.title = 'Webpay Plus';
        webpayPlus.subtitle = 'Paga online con tarjeta de débito/crédito o OnePay';
        webpayPlus.image = '/assets/img/logo-webpay-plus.svg';
      }

      let amiPASS = paymentMethods.find((paymentMethod) => paymentMethod.id === 6);
      if (amiPASS) {
        amiPASS.title = 'amiPASS';
        amiPASS.subtitle = 'Paga online con amiPASS';
        amiPASS.image = '/assets/img/logo-amipass.png';
      }

      let sodexo = paymentMethods.find((paymentMethod) => paymentMethod.id === 7);
      if (sodexo) {
        sodexo.title = 'Pluxee';
        sodexo.subtitle = 'Paga online con Pluxee';
        sodexo.image = '/assets/img/pluxee_logo.png';
      }

      getEdenredInscrito().then(
        (value) => {
          let edenred = paymentMethods.find((paymentMethod) => paymentMethod.id === 9);
          let existeInscripcionEdenred = value.data.existeInscripcionEdenred === 'true';
          if (edenred) {
            edenred.title = 'Edenred';
            edenred.subtitle = 'Paga online con Edenred';
            edenred.image = '/assets/img/logo-edenred.png';
            //se crear un valor habilitado específico de endenred para validar registro de usuario
            edenred.habilitadoEdenred = existeInscripcionEdenred;
          }
        },
        (reason) => {
          console.log(reason);
        }
      );

      return paymentMethods;
    }

    function deletePayment(id) {
      return $http.delete(`${config.URL_API}/payments/${id}`);
    }

    function finalizePayment() {
      return $http.get(`${config.URL_API}/payments/tbk_oneclick/finalize`);
    }

    function getPayments() {
      return $http.get(`${config.URL_API}/payments`);
    }

    function addCardPayment(objCard) {
      return $http.post(`${config.URL_API}/payments/web`, angular.toJson(objCard));
    }

    function addEdenredCardPayment() {
      return $http.get(`${config.URL_API}/payments/edenred/url`);
    }

    function getEdenredInscrito() {
      return $http.get(`${config.URL_API}/payments/edenred/inscrito`);
    }
  }
})();
