(function () {
  'use strict';

  angular.module('shared.loginModal.registerForm').component('registerFormComponent', {
    templateUrl: 'shared/login-modal/components/register-form/register-form.template.html',
    controller: RegisterFormController,
    controllerAs: 'vm',
    bindings: {
      currentStep: '=',
      onChangeForm: '&',
      showActivateAccountMessage: '=',
      emailActivateAccount: '=',
    },
  });

  function RegisterFormController(
    $scope,
    AnalyticsService,
    FormExceptionHandlerFactory,
    PunycodeFactory,
    CookiesService,
    GlobalService,
    LogService,
    UserService
  ) {
    var vm = this;

    var REGISTER_EMPTY = {
      name: '',
      lastname: '',
      email: '',
      phone_number: '',
      password: '',
      repeat_password: '',
      rut_dv: '',
    };
    vm.register = angular.copy(REGISTER_EMPTY);
    vm.registerError = '';
    vm.rutReason = false;

    vm.totalSteps = 3;
    vm.nextStepAttempt = false;
    vm.busyEmailValidation = false;
    vm.busyRutValidation = false;

    vm.$onInit = onInit;
    vm.changeForm = changeForm;
    vm.nextStep = nextStep;
    vm.resetEmailUniqueValidator = resetEmailUniqueValidator;
    vm.resetRutUniqueValidator = resetRutUniqueValidator;
    vm.toggleRutReason = toggleRutReason;

    $scope.registerUser = registerUser;
    $scope.validateFirstStep = validateFirstStep;
    $scope.validateSecondStep = validateSecondStep;
    $scope.validateThirdStep = validateThirdStep;

    function onInit() {
      vm.register = angular.copy(REGISTER_EMPTY);
    }

    /**
     * Cambiar el formulario del modal de inicio de sesión
     * @param form {string}
     */
    function changeForm(form) {
      vm.onChangeForm({ form: form });
    }

    /**
     * Ir al siguiente paso
     */
    function nextStep() {
      vm.nextStepAttempt = false;

      if (
        (vm.currentStep === 1 && $scope.validateFirstStep()) ||
        (vm.currentStep === 2 && $scope.validateSecondStep())
      ) {
        vm.currentStep++;
        AnalyticsService.clickNextRegisterStep(vm.currentStep);
      } else if (vm.currentStep === 3 && $scope.validateThirdStep()) {
        $scope.registerUser();
      } else {
        vm.nextStepAttempt = true;
      }
    }

    /**
     * Registrar al usuario invitado
     */
    function registerUser() {
      vm.busy = true;
      vm.registerError = '';

      vm.register.auth_token = CookiesService.getAuthToken();
      vm.register.email = PunycodeFactory.emailToUnicode(vm.register.email);

      UserService.registerGuest(vm.register, document.referrer)
        .then((value) => {
          // Mostrar Mensaje es necesario activar cuenta
          vm.showActivateAccountMessage = true;
          vm.emailActivateAccount = vm.register.email;
        })
        .catch((reason) => {
          console.error(reason);
          const formErrors = FormExceptionHandlerFactory.getListErrors(reason);

          if (formErrors.email && formErrors.email === 'El email está en uso.') {
            $scope.registerForm.email.$setValidity('unique', false);
            vm.currentStep = 1;
          } else if (
            formErrors.email &&
            formErrors.email === 'El formato de email es incorrecto.'
          ) {
            $scope.registerForm.email.$setValidity('email', false);
            vm.currentStep = 1;
          } else if (formErrors.rut_dv && formErrors.rut_dv === 'El rut ya está en uso') {
            $scope.registerForm.rut_dv.$setValidity('unique', false);
          } else if (reason.data && reason.data.message) {
            vm.registerError = reason.data.message;
          } else {
            vm.registerError = 'Ocurrió un error en el registro del usuario.';
          }
        })
        .finally(() => {
          vm.busy = false;
        });
    }

    /**
     * Resetear el validador personalizado para el campo email
     */
    function resetEmailUniqueValidator() {
      $scope.registerForm.email.$setValidity('unique', true);
    }

    /**
     * Resetear el validador personalizado para el campo rut
     */
    function resetRutUniqueValidator() {
      $scope.registerForm.rut_dv.$setValidity('unique', true);
    }

    /**
     * Mostrar/Ocultar la razón del por qué solicitamos el RUT
     */
    function toggleRutReason() {
      vm.rutReason = !vm.rutReason;
      AnalyticsService.toggleRutReason(vm.rutReason);
    }

    /**
     * Validar el primer paso del formulario
     * @returns {boolean}
     */
    function validateFirstStep() {
      var form = {
        name: vm.register.name,
        lastname: vm.register.lastname,
        email: vm.register.email,
        phone_number: vm.register.phone_number,
      };

      form.valid =
        $scope.registerForm.name.$valid &&
        $scope.registerForm.lastname.$valid &&
        $scope.registerForm.email.$valid &&
        $scope.registerForm.phone_number.$valid;

      LogService.info('Formulario de registro: Paso 1', vm.constructor.name, form);

      return form.valid;
    }

    /**
     * Validar el segundo paso del formulario
     * @returns {boolean}
     */
    function validateSecondStep() {
      var form = {
        password: vm.register.password.replace(/(.)(?!$)/g, '*'),
        repeat_password: vm.register.repeat_password.replace(/(.)(?!$)/g, '*'),
      };

      form.valid =
        $scope.registerForm.password.$valid && $scope.registerForm.repeat_password.$valid;

      LogService.info('Formulario de registro: Paso 2', vm.constructor.name, form);

      return form.valid;
    }

    /**
     * Validar el tercer paso del formulario
     * @returns {boolean}
     */
    function validateThirdStep() {
      var form = {
        rut_dv: vm.register.rut_dv,
      };

      form.valid = $scope.registerForm.rut_dv.$valid;

      LogService.info('Formulario de registro: Paso 3', vm.constructor.name, form);

      return form.valid;
    }
  }
})();
