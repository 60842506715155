(function () {
  'use strict';

  angular.module('shared.ratingModal.ratingSentenceCard').component('ratingSentenceCardComponent', {
    templateUrl:
      'shared/rating-modal/components/rating-sentence-card/rating-sentence-card.template.html',
    controller: RatingSentenceCardController,
    controllerAs: 'vm',
    bindings: {
      order: '<',
      sentence: '<',
      isDigital: '<',
    },
  });

  function RatingSentenceCardController($stateParams, RatingService) {
    const vm = this;
    const EVALUACION_VENTA_DIGITAL = 'EVALUACION_VENTA_DIGITAL';
    const EVALUACION_VENTA_FISICA = 'EVALUACION_VENTA_FISICA';

    // Variables enlazadas con la plantilla
    vm.thumb = -1;

    // Funciones enlazadas con la plantilla
    vm.setThumb = setThumb;

    /**
     * Establecer calificación positiva o negativa
     * @param thumb
     */
    function setThumb(thumb) {
      if (vm.thumb !== thumb) {
        vm.thumb = thumb;
        vm.sentence.thumb = thumb;

        const source = $stateParams.source || vm.isDigital ? 'WEB' : 'EMAIL';
        const ratingType = vm.isDigital ? EVALUACION_VENTA_DIGITAL : EVALUACION_VENTA_FISICA;

        RatingService.setRating(
          source,
          vm.isDigital,
          ratingType,
          thumb,
          vm.order.id,
          null,
          vm.sentence.id
        );
      }
    }
  }
})();
