(function () {
  'use strict';

  angular.module('filters.absoluteValue').filter('absoluteValue', AbsoluteValueFilter);

  function AbsoluteValueFilter() {
    return function (number) {
      if (number) {
        return number && number < 0 ? number * -1 : number;
      }
    };
  }
})();
