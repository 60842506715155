(function () {
  'use strict';

  angular.module('app.shared', [
    'shared.addressModal',
    'shared.bannerSlider',
    'shared.cart',
    'shared.configDeliveryModal',
    'shared.devRibbon',
    'shared.footer',
    'shared.footerMessage',
    'shared.footerSummary',
    'shared.forkLifeModal',
    'shared.loginModal',
    'shared.navbar',
    'shared.navbarMessage',
    'shared.navbarPromo',
    'shared.newsModal',
    'shared.productCard',
    'shared.promoCodeModal',
    'shared.promoModal',
    'shared.ratingModal',
    'shared.systemAlertModal',
    'shared.userMessage',
    'shared.headerGrayBar',
    'shared.landingHome',
    'shared.categoriesMenu',
    'shared.billingModal',
  ]);
})();
