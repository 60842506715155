(function () {
  'use strict';

  angular
    .module('directives.fullViewportHeight')
    .directive('fullViewportHeight', FullViewportHeight);

  function FullViewportHeight($window) {
    return {
      scope: {
        difference: '<',
        maxHeight: '<',
      },
      link: function (scope, element) {
        var height = $window.innerHeight - scope.difference;
        if (scope.maxHeight) {
          element.css({
            'max-height': height + 'px',
            display: 'block',
            'overflow-y': 'auto',
          });
        } else {
          element.css({
            height: height + 'px',
            display: 'block',
            'overflow-y': 'auto',
          });
        }
      },
    };
  }
})();
