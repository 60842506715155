(function () {
  'use strict';

  angular
    .module('home.collapseCategoryDescription')
    .component('collapseCategoryDescriptionComponent', {
      templateUrl:
        'pages/home/components/collapse-category-description/collapse-category-description.template.html',
      controller: CollapseCategoryDescriptionController,
      controllerAs: 'vm',
    });

  function CollapseCategoryDescriptionController($scope, ContentfulService, GlobalService) {
    const vm = this;
    $scope.global = GlobalService;

    vm.collapsed = false;
    vm.categoryTitle = '';
    vm.categoryDescription = '';
    vm.contentfulCategories = [];

    vm.$onInit = onInit;

    $scope.updateCategoryDescription = updateCategoryDescription;

    function onInit() {
      /**
       * Obtener la descripción de categorías desde Contentful
       */
      ContentfulService.getProductCategories().then((value) => {
        vm.contentfulCategories = value;
        $scope.updateCategoryDescription();
      });

      $scope.$watch('global.activeCategory', () => {
        $scope.updateCategoryDescription();
      });
    }

    /**
     * Actualizar la descripción de la categoría
     */
    function updateCategoryDescription() {
      const { id, nombre } = $scope.global.activeCategory;

      if (nombre) {
        const contentfulCategory = vm.contentfulCategories.find((category) => category.id === id);
        vm.categoryTitle = nombre;
        vm.categoryDescription = contentfulCategory ? contentfulCategory.description : '';
      }
    }
  }
})();
