(function () {
  'use strict';

  angular
    .module('pages.externalRating')
    .controller('ExternalRatingController', ExternalRatingController);

  function ExternalRatingController($rootScope) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      // Si el usuario llegó a esta vista, entonces hay que verificar de una vez si es que tiene una calificación
      // pendiente
      $rootScope.$broadcast('checkRatingModal');
      console.info('Broadcast: ¡Consultar el modal de rating!');
    }
  }
})();
