(function () {
  'use strict';

  angular.module('shared.newsModal').component('newsModalComponent', {
    templateUrl: 'shared/news-modal/news-modal.template.html',
    controller: NewsModalController,
    controllerAs: 'vm',
  });

  function NewsModalController($scope, $timeout, ContentfulService) {
    const vm = this;

    vm.ready = false;
    vm.modalTitle = '';
    vm.imageUrl = '';
    vm.contentText = '';
    vm.buttonText = '';

    vm.$onInit = onInit;

    $scope.openNewsModal = openNewsModal;

    function onInit() {
      ContentfulService.getNews().then((value) => {
        if (value) {
          const { title, text, imageUrl, buttonText } = value;
          vm.modalTitle = title;
          vm.contentText = text;
          vm.imageUrl = imageUrl;
          vm.buttonText = buttonText;
          vm.ready = true;
          $timeout($scope.openNewsModal);
        }
      });
    }

    function openNewsModal() {
      $('#news-modal').modal('show');
    }
  }
})();
