(function () {
  'use strict';

  angular.module('services.store').service('StoreService', StoreService);

  function StoreService($http, $q, config, AddressService) {
    let storesData = null;
    let storesByCommuneData = null;

    return {
      getStores: getStores,
      getSchedule: getSchedule,
      getDateOfStore: getDateOfStore,
      getStore: getStore,
      getStoreDataById: getStoreDataById,
      getStoresByCommune: getStoresByCommune,
      obtenerTiendas: obtenerTiendas,
    };

    function getStore(storeId) {
      return $http.get(`${config.URL_API}/stores/${storeId}`);
    }

    function getDateOfStore() {
      return $http.get(`${config.URL_API}/stores/date`);
    }

    function getSchedule(id) {
      return $http.get(`${config.URL_API}/stores/${id}/schedules`);
    }

    function filterStoresWithServiceIsActive(stores) {
      let dayNumber = moment().weekday();
      let weekDays = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'];
      if (typeof dayNumber == 'number' && dayNumber >= 0 && dayNumber <= 6) {
        let currentWeekDay = weekDays[dayNumber];

        // Si una sucursal no tiene un horario de atención asignado para el dia de hoy se eliminará del array
        let storesFiltered = stores.filter((store) => {
          let serviceOk = store.service.find(
            (service) => service.weekday.toUpperCase() === currentWeekDay
          );
          if (serviceOk) {
            return store;
          }
        });

        return storesFiltered;
      } else {
        return stores;
      }
    }

    /**
     * Obtener la lista de tiendas
     * @returns {[]}
     */
    function getStores() {
      if (!storesData) {
        storesData = $http
          .get(`${config.URL_API}/stores`)
          .then((value) => {
            var data = value.data.stores;
            let stores = filterStoresWithServiceIsActive(data);
            // console.info('Tiendas: ', data);
            return stores;
          })
          .catch((reason) => {
            console.warn(reason);
            // TODO: De ocurrir un error, fallará el inicio de sesión
          });
      }

      return storesData;
    }

    /**
     * Obtener la lista de tiendas
     * @returns {[]}
     */
    function obtenerTiendas(tipoDistribucion) {
      if (!storesData) {
        storesData = $http({
          url: `${config.URL_API}/stores`,
          method: 'GET',
          params: { tipoDistribucion: tipoDistribucion },
        })
          .then((value) => {
            var data = value.data.stores;
            let stores = filterStoresWithServiceIsActive(data);
            // console.info('Tiendas: ', data);
            return stores;
          })
          .catch((reason) => {
            console.warn(reason);
            // TODO: De ocurrir un error, fallará el inicio de sesión
          });
      }

      return storesData;
    }

    /**
     * Devuelve la tienda almacenada en la variable local de tiendas
     * @param {number} storeId Número identificador de la tienda
     * @returns Objeto tienda o undefined
     */
    function getStoreDataById(storeId) {
      return storesData.then((stores) => stores.find((store) => store.id === storeId));
    }

    /**
     * Obtener la lista de tiendas separadas por comunas
     */
    function getStoresByCommune() {
      if (!storesByCommuneData) {
        storesByCommuneData = $q
          .all([getStores(), AddressService.getCommunes()])
          .then((data) => {
            const stores = filterStoresWithServiceIsActive(data[0]);
            const communes = data[1];

            let commune = {};

            // Recorrer cada tienda
            stores.forEach((store) => {
              // Encontrar la comuna correspondiente a la tienda
              commune = communes.find((communeElement) => {
                return communeElement.id === store.commune.id;
              });

              if (!commune.hasOwnProperty('stores')) {
                commune.stores = [];
              }

              commune.stores.push(store);

              // Indicador de que la comuna contiene una nueva tienda
              commune.hasNewStore = store.recent && !commune.hasNewStore;
            });

            // Devolver sólo las comunas que tengan tiendas
            return communes.filter((communeElement) => {
              return communeElement.stores && communeElement.stores.length;
            });
          })
          .catch((reason) => {
            console.warn(reason);
            // TODO: De ocurrir un error, fallará el inicio de sesión
          });
      }

      return storesByCommuneData;
    }
  }
})();
