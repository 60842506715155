(function () {
  'use strict';

  angular
    .module('pages.userProfile.billing')
    .controller('BillingController', BillingController);

  function BillingController(
    $anchorScroll,
    $rootScope,
    $scope,
    AlertService,
    AnalyticsService,
    GlobalService,
    BillingService
  ) {
    var vm = this;
    $scope.global = GlobalService;

    vm.message = '';
    vm.messageType = '';
    vm.addresses = null;
    vm.addressesWithDelivery = [];
    vm.addressesWithoutDelivery = [];

    vm.addressesWithoutDeliveryInfo = '';

    var unregisterAddresses = function () {
      return undefined;
    };

    vm.$onInit = onInit;
    vm.openCreateBillingModal = openCreateBillingModal;
    vm.openDeleteBillingAlert = openDeleteBillingAlert;
    vm.openEditBillingModal = openEditBillingModal;
    vm.isValidRUT = isValidRUT;

    $scope.deleteAddress = deleteAddress;
    $scope.$on('$destroy', onDestroy);

    $rootScope.$on('billingCreated', billingCreated);
    $rootScope.$on('billingEdited', billingEdited);
    $rootScope.$on('billingError', billingError);

    function onInit() {
      $anchorScroll();
      vm.billing = GlobalService.billing;
      $scope.$watch(
        'global.billing',
        function (newVal) {
          if (newVal) {
            vm.billing = newVal;
          }
        },
        true
      );

      AnalyticsService.profileView('/direcciones');
    }

    function onDestroy() {
      unregisterAddresses();
    }

    function billingCreated() {
      vm.message = 'Agregaste una nueva dirección';
      vm.messageType = 'success';
    }

    function billingEdited() {
      vm.message = 'Datos de facturación editados';
      vm.messageType = 'success';
    }

    function billingError(event, opt) {
      vm.message = opt.reason;
      vm.messageType = 'warning';
    }

    /**
     * Eliminar dirección
     * @param address
     */
    function deleteAddress(billing) {
      BillingService.deleteBilling(billing.id).then(
        function () {
          GlobalService.billing.response = $scope.global.billing.response.filter(function (el) {
            return el.id !== billing.id;
          });

          vm.message = 'Eliminaste una dirección de facturacion';
          vm.messageType = 'success';

          //AnalyticsService.deleteAddress(address);
        },
        function (reason) {
          vm.message = reason.data.message;
          vm.messageType = 'warning';
        }
      );
    }

    function openCreateBillingModal() {
      $rootScope.$broadcast('openBillingModal', { source: 'user-new-billing' });
      console.info('Broadcast: ¡Abrir modal de billing!');
    }

    /**
     * Abrir el modal para editar la dirección
     * @param address
     */
    function openEditBillingModal(address) {
      $rootScope.$broadcast('openBillingModal', { source: 'user-edit-billing', address: address });
      console.info('Broadcast: ¡Abrir modal de billing!');
    }

    /**
     * Abrir el modal de alerta de confirmación para eliminar la dirección
     * @param address
     */
    function openDeleteBillingAlert(billing) {
        AlertService.deleteAddressBilling(function () {
          $scope.deleteAddress(billing);
        });
    }

    function isValidRUT(rut) {
      if (!/^[0-9]+-[0-9kK]{1}$/.test(rut)) return false;
      var parts = rut.split('-');
      var digits = parts[0].split('').reverse();
      var checkDigit = parts[1].toLowerCase();
      var sum = 0;
      var multiplier = 2;

      for (var i = 0; i < digits.length; i++) {
          sum += digits[i] * multiplier;
          multiplier = (multiplier % 7) + 1;
      }

      var mod = 11 - (sum % 11);
      var computedCheckDigit = mod === 11 ? '0' : mod === 10 ? 'k' : mod.toString();

      return checkDigit === computedCheckDigit;
    }
  }
})();
