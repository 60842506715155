(function () {
  'use strict';

  angular.module('shared.cart').component('cartComponent', {
    templateUrl: 'shared/cart/cart.template.html',
    controller: CartController,
    controllerAs: 'vm',
    bindings: {
      hideGoToCheckoutButton: '<',
    },
  });

  function CartController(
    $document,
    $rootScope,
    $scope,
    $window,
    AnimateService,
    CartService,
    GlobalService
  ) {
    var vm = this;

    $scope.global = GlobalService;

    var unregisterCart;

    vm.cart = {};
    vm.showCart = false;
    vm.ready = false;
    vm.busy = false;
    vm.busyPosition = 0;

    vm.$onInit = onInit;
    vm.decreaseProduct = decreaseProduct;
    vm.increaseProduct = increaseProduct;
    vm.toggleCart = toggleCart;

    $scope.onDestroy = onDestroy;
    $scope.animateCart = animateCart;

    $scope.$on('$destroy', $scope.onDestroy);

    $rootScope.$on('scrollEvent', onScrollEvent);

    /**
     * Constructor
     */
    function onInit() {
      vm.ready = true;

      // Si el carro cambia o es ajustado, actualizar la variable del controlador
      unregisterCart = $scope.$watch('global.cart', (newValue, oldValue) => {
        if (newValue) {
          vm.cart = newValue;
          $rootScope.$broadcast('checkPopupPromo', { cartTotal: vm.cart.total });
          console.info('Broadcast: ¡Consultar el modal de promoción!');

          if (oldValue && oldValue.total !== newValue.total && newValue.total) {
            $scope.animateCart();
          }
        }
      });
    }

    function onDestroy() {
      unregisterCart();
    }

    /**
     * Cuando el usuario hace scroll, el carro se cierra en dispositivos móviles, si es que estaba abierto
     */
    function onScrollEvent() {
      if (vm.showCart) {
        var viewportWidth = Math.max(
          $document[0].documentElement.clientWidth,
          $window.innerWidth || 0
        );

        if (viewportWidth < 767) {
          vm.toggleCart();
          $scope.$apply();
        }
      }
    }

    /**
     * Disminuir un producto del carro
     * @param {object} product Elemento a disminuir
     * @param {number} index Posición en la que se encuentra el elemento
     */
    function decreaseProduct(product, index) {
      if (!vm.busy) {
        vm.busyPosition = index;
        vm.busy = true;

        CartService.removeFromCart(product, product.productosPack, 'cart')
          .then((value) => {
            if (value) {
              GlobalService.cart = value.data.response;
            }

            // Si no queda ningún producto, ocultar carro
            if (!GlobalService.cart.productos.length) {
              vm.toggleCart();
            }
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            vm.busy = false;
          });
      }
    }

    /**
     * Aumentar un producto del carro
     * @param {object} product Elemento a aumentar
     * @param {number} index Posición en la que se encuentra el elemento
     */
    function increaseProduct(product, index) {
      if (!vm.busy) {
        vm.busyPosition = index;
        vm.busy = true;
        CartService.addToCart(product, product.productosPack, 'cart')
          .then((value) => {
            GlobalService.cart = value;
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            vm.busy = false;
          });
      }
    }

    /**
     * Animar carro de compras
     */
    function animateCart() {
      AnimateService.animate('.toggle-cart-btn', 'heartBeat');
    }

    /**
     * Cambiar el estado del carro (Abierto/Cerrado)
     */
    function toggleCart() {
      vm.showCart = !vm.showCart;
      console.log(vm.showCart);

      if (vm.showCart) {
        $('.user-menu').addClass('hide-user-menu');
        AnimateService.animate('.item-list-container', 'fadeInDown');
      } else {
        $('.user-menu').removeClass('hide-user-menu');
        vm.ready = false;
        AnimateService.animate('.item-list-container', 'fadeOutUp', function () {
          vm.ready = true;
          $scope.$apply();
        });
      }
    }

    function hideCart() {
      if (vm.showCart) {
        vm.showCart = false;
        $('.user-menu').removeClass('hide-user-menu');
        vm.ready = false;
        AnimateService.animate('.item-list-container', 'fadeOutUp', function () {
          vm.ready = true;
          $scope.$apply();
        });
      }
    }

    // Cerrar carro al hacer click fuera del contenedor
    $('body').on('click', function (event) {
      if (!$(event.target).closest('.cart-component').length) {
        hideCart();
      }
    });
  }
})();
