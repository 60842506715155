(function () {
  'use strict';

  angular
    .module('directives.truncateMultilineText')
    .directive('truncateMultilineText', TruncateMultilineText);

  /**
   * La directiva truncará el texto dentro de su propio contenedor
   * ... O dentro del id del contenedor definido en el atributo 'compareTo'
   */
  function TruncateMultilineText($document, $rootScope, $timeout, $window) {
    return {
      scope: {
        compareTo: '<',
        text: '<',
      },
      link: function (scope, element) {
        var originalText;
        var container;

        $timeout(onInit);
        scope.$watch('compareTo', function (newVal, oldVal) {
          if (newVal && newVal !== oldVal) {
            onInit();
          }
        });
        scope.$watch('text', function (newVal) {
          if (newVal) {
            onInit();
          }
        });

        // Ajustar contenido de la tarjeta si el usuario redimensiona la ventana.
        $rootScope.$on('resizeEvent', truncate);
        scope.$on('$destroy', onDestroy);

        function onInit() {
          container = scope.compareTo ? $document[0].getElementById(scope.compareTo) : element[0];
          originalText = scope.text || element[0].innerText;
          truncate();
        }

        function onDestroy() {
          angular.element($window).unbind('resize');
        }

        function truncate() {
          if (container && container.scrollHeight) {
            // Resetear el texto original
            element[0].innerText = originalText;

            var wordArray = element[0].innerText.split(' ');

            // Repetir mientras existan al menos más de una palabra en el texto
            // Y el contenido sea mayor a su contenedor
            while (wordArray.length > 1 && container.scrollHeight > container.offsetHeight) {
              wordArray.pop();

              element[0].innerText = wordArray.join(' ') + '...';
            }

            // Eliminar la coma, si es que quedó una justo antes de los "..."
            element[0].innerText = element[0].innerText.replace(/,(?=\.\.\.)/, '');
          }
        }
      },
    };
  }
})();
