(function () {
  'use strict';

  angular.module('pages.userProfile.orders').controller('OrdersController', OrdersController);

  function OrdersController(
    $anchorScroll,
    $sce,
    $rootScope,
    $scope,
    $timeout,
    $window,
    AnalyticsService,
    GlobalService,
    OrderService
  ) {
    const vm = this;
    $scope.global = GlobalService;

    const orderStatus = [
      { name: 'Ingresado', class: 'order-status-active' },
      { name: 'Preparado', class: 'order-status-active' },
      { name: 'En Camino', class: 'order-status-active' },
      { name: 'Disponible', class: 'order-status-active' },
      { name: 'Pagado', class: 'order-status-inactive' },
      { name: 'Anulado', class: 'order-status-inactive' },
      { name: 'Entregado', class: 'order-status-inactive' },
      { name: 'Cancelado', class: 'order-status-inactive' },
    ];

    vm.busy = false;
    vm.orders = null;
    vm.selectedOrder = null;
    vm.mobile = true;

    vm.html = '';

    vm.$onInit = onInit;
    vm.getOrderStatusClass = getOrderStatusClass;
    vm.getOrderStatusName = getOrderStatusName;
    vm.selectOrder = selectOrder;

    $scope.resizeEvent = resizeEvent;
    $scope.selectFirstOrder = selectFirstOrder;

    $rootScope.$on('resizeEvent', $scope.resizeEvent);

    function onInit() {
      $anchorScroll();
      vm.mobile = $window.innerWidth < 768;
      vm.busy = true;

      OrderService.getUserOrders()
        .then((value) => {
          vm.orders = value.data.response;
          $scope.selectFirstOrder();
        })
        .catch((reason) => {
          console.error(reason);
          vm.orders = [];
        })
        .finally(() => {
          console.info('Lista de pedidos: ', vm.orders);
          vm.busy = false;
        });

      AnalyticsService.profileView('/pedidos');
    }

    function getOrderStatusClass(status) {
      return orderStatus[status] ? orderStatus[status].class : '';
    }

    function getOrderStatusName(status) {
      return orderStatus[status] ? orderStatus[status].name : '';
    }

    function resizeEvent() {
      $timeout(() => {
        if (vm.orders && vm.orders.length) {
          vm.mobile = $window.innerWidth < 768;
          if (!vm.mobile && !vm.selectedOrder) {
            vm.selectOrder(vm.orders[0]);
          }
        }
      });
    }

    function selectFirstOrder() {
      if (!vm.mobile) {
        vm.selectOrder(vm.orders[0]);
      }
    }

    function selectOrder(order) {
      if (
        (order && !vm.selectedOrder) ||
        (order && vm.selectedOrder && order.id !== vm.selectedOrder.id)
      ) {
        vm.busyOrder = true;

        OrderService.getVoucher(order.id)
          .then((value) => {
            vm.html = $sce.trustAsHtml(value.data.html);
          })
          .catch((reason) => {
            console.error(reason);
            vm.html = 'Ocurrió un error obteniendo el pedido';
          })
          .finally(() => {
            vm.busyOrder = false;
            vm.selectedOrder = order;
            AnalyticsService.orderView(order);
          });

        if (vm.mobile) {
          $anchorScroll();
        }
      } else {
        vm.selectedOrder = order;
      }
    }
  }
})();
