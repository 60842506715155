(function () {
  'use strict';

  angular
    .module('directives.uniqueRutFormValidation')
    .directive('uniqueRut', UniqueRutFormValidation);

  function UniqueRutFormValidation($q, UserService) {
    return {
      require: 'ngModel',
      scope: {
        busy: '=',
      },
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$asyncValidators.uniqueRut = function (modelValue, viewValue) {
          // No validar si el texto viene vacío
          if (ctrl.$isEmpty(modelValue) || ctrl.$error.email) {
            return $q.resolve();
          } else {
            scope.busy = true;
            return UserService.validateRut(viewValue).then(function (value) {
              scope.busy = false;
              if (value.data.valid) {
                return $q.resolve();
              } else {
                return $q.reject();
              }
            }, $q.reject);
          }
        };
      },
    };
  }
})();
