(function () {
  'use strict';

  angular.module('directives.confettiOnHover').directive('confettiOnHover', ConfettiOnHover);

  function ConfettiOnHover($document, $interval, $window, confetti) {
    return {
      link: function ($scope, element) {
        $scope.rainingConfetti = false;

        element.on('mouseenter', function () {
          if (!$scope.rainingConfetti) {
            // Evitar spam
            $scope.rainingConfetti = true;

            var aux = 0;
            var offsetWidth = element[0].getBoundingClientRect().x + element[0].clientWidth / 2;
            var clientWidth = $window.innerWidth || $document[0].documentElement.clientWidth;

            var intervalConfetti = $interval(function () {
              if (aux < 8) {
                // Repetir 'aux' veces
                confetti({
                  decay: 0.7,
                  origin: {
                    y: 0.077,
                    x: offsetWidth / clientWidth,
                  },
                  startVelocity: 20,
                  particleCount: 5,
                  spread: 80,
                  angle: 270,
                  zIndex: 1030,
                });
                aux++;
              } else {
                $scope.rainingConfetti = false;
                $interval.cancel(intervalConfetti);
              }
            }, 100);
          }
        });
      },
    };
  }
})();
