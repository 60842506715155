(function () {
  'use strict';

  angular.module('shared.userMessage').component('userMessage', {
    templateUrl: 'shared/user-message/user-message.template.html',
    controller: UserMessageController,
    controllerAs: 'vm',
    bindings: {
      type: '<',
      messageTitle: '<',
      message: '<',
    },
  });

  function UserMessageController() {
    var vm = this;

    vm.imageUrl = '';

    vm.$onChanges = onChanges;

    function onChanges() {
      if (vm.type) {
        vm.imageUrl = 'assets/img';

        switch (vm.type) {
          case 'success':
            vm.imageUrl += '/check-green.svg';
            break;
          case 'warning':
            vm.imageUrl += '/warning.svg';
            break;
          case 'info':
            vm.imageUrl += '/info.svg';
            break;
        }
      }
    }
  }
})();
