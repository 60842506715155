(function () {
  'use strict';

  angular
    .module('pages.productDetails')
    .controller('ProductDetailsController', ProductDetailsController);

  function ProductDetailsController(
    $rootScope,
    $scope,
    $stateParams,
    $state,
    $transitions,
    $timeout,
    AnalyticsService,
    GlobalService,
    MetaTagService,
    ProductService,
    HomeNavigationService,
    Slug
  ) {
    const vm = this;
    $scope.global = GlobalService;

    let unregisterConfigDelivery;
    let unregisterFooterMessage;
    let unregisterUserConfig;

    vm.busy = false;

    // Variable para que llama el mensaje de productos frescos
    vm.freshProductMessage = false;

    // Objeto del producto
    vm.product = {
      title: '',
      subtitle: '',
      pictures_web: [],
      imageMasks: [],
      description: '',
      consumo_inmediato: false,
      unit_price: 0,
      unit_price_real: 0,
      discount_percentage: 0,
      stock: 0,
      inCart: 0,
      sellos: [],
      tags: [],
      chef_comment: {},
      item: {},
    };

    // Objeto de los breadcrumbs
    vm.breadcrumbs = {
      categoryName: '',
      categoryId: '',
      subcategoryName: '',
      subcategoryId: '',
    };

    $scope.imgSlider = null;

    vm.$onInit = onInit;

    $scope.getProduct = getProduct;
    $scope.initImagesSlider = initImagesSlider;
    $scope.initTagsSlider = initTagsSlider;
    $scope.setMissingProductInfo = setMissingProductInfo;
    $scope.setPartialProductInfo = setPartialProductInfo;
    $scope.goHomeFromBreadcrumbs = goHomeFromBreadcrumbs;
    $scope.goToCategory = goToCategory;
    $scope.goToSubCategory = goToSubCategory;

    function onInit() {
      // handleLocalStorageBreadcrumbs();
      $('html').animate({ scrollTop: 0 }, 200);

      // Obtener información del producto, si es que el usuario seleccionó alguno desde el catálogo
      const product = ProductService.getSelectedProduct();

      if (product) {
        $scope.setPartialProductInfo(product);
      }

      // Si el usuario tiene la configuración de retiro, se visualiza el mensaje de producto de consumo inmediato
      if ($scope.global.configDelivery.kind === 1) {
        vm.freshProductMessage = true;
      }

      // Mensaje en el footer
      vm.showFooterMessage = GlobalService.showFooterMessage;
      unregisterFooterMessage = $scope.$watch('global.showFooterMessage', (newVal, oldVal) => {
        vm.showFooterMessage = newVal;
        if (oldVal && !newVal) {
          unregisterFooterMessage();
        }
      });

      // Si la configuración de entrega cambia, actualizar la variable del controlador
      unregisterConfigDelivery = $scope.$watch('global.configDelivery.store', (newVal) => {
        if (newVal) {
          $scope.getProduct();
          unregisterConfigDelivery();
        }
      });

      // Verificar si se debe mostrar el modal de bienvenida.
      unregisterUserConfig = $scope.$watch('global.userConfig', (newVal) => {
        if (newVal) {
          if (newVal.default) {
            $rootScope.$broadcast('openConfigDeliveryModal', { state: 'default-config-delivery' });
            console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
          }

          unregisterUserConfig();
        }
      });

      $scope.$on('storeChanged', function(event, data=null) {
        if (data == undefined) {
          data = null         
          $scope.getProduct(); 
        }else{
          $scope.getProduct(data);
        }
      });
    }

    /**
     * Manejar cuando el usuario quiera navegar con los botones del navegador
     */
    $transitions.onSuccess({ from: 'productDetails', to: 'productDetails' }, (transition) => {
      const { productId } = transition.router.globals.params;
      if (productId && parseInt(productId) !== vm.product.id) {
        $scope.getProduct();
      }
    });

    function getProduct(data=null) {
      const productId = $stateParams.productId;
      const store = $scope.global.configDelivery.store || null;

      let storeid;
      let tipoDistribucion

      if (data) {
        storeid = data.store_id;
        tipoDistribucion = 0;
      }else{
        storeid = store.id;
        tipoDistribucion = 1;
      }

      if (productId && store) {
        ProductService.getProductDetails(productId, storeid, tipoDistribucion)
          .then((value) => {
            const product = value.data;

            if (!vm.product.title) {
              $scope.setPartialProductInfo(product);
            }
            $scope.setMissingProductInfo(product);

            // Iniciar Swiper
            $timeout(() => {
              $scope.initImagesSlider();
              $scope.initTagsSlider();
            });

            // Actualizar meta tags
            MetaTagService.updateMetaTags('productDetails', product);

            // Enviar analíticas
            AnalyticsService.productView(product, store);
          })
          .catch((reason) => {
            console.error(reason);
            $state.go('home');
          });
      }
    }

    function initImagesSlider() {
      if (!$scope.imgSlider && vm.product.pictures_web.length > 1) {
        $scope.imgSlider = new Swiper('#product-images', {
          navigation: {
            nextEl: '#product-images-next',
            prevEl: '#product-images-prev',
            disabledClass: 'disabled',
          },
          pagination: {
            el: '#product-images-pagination',
            type: 'bullets',
            clickable: true,
          },
          slidesPerView: 1.4,
          centeredSlides: true,
          spaceBetween: 25,
          slideToClickedSlide: true,
        });

        $scope.imgSlider.update();
      }
    }

    function initTagsSlider() {
      if (vm.product.tags && vm.product.tags.length > 3) {
        $scope.tagSlider = new Swiper('#product-tags', {
          slidesPerView: 'auto',
          freeMode: true,
          direction: 'horizontal',
        });
      }
    }

    /**
     * Establece la información que falta del producto,
     * la que no viene desde el catálogo
     * @param product
     */
    function setMissingProductInfo(product) {
      vm.product = product;

      // Agregarle una máscara distinta a cada imagen de productos
      let masks = [];
      let currentMask = 1;
      for (let x = 0; x < product.pictures_web.length; x++) {
        masks.push(currentMask);
        currentMask = currentMask + 1 > 4 ? 1 : currentMask + 1;
      }

      vm.product.imageMasks = masks;
      console.info('Información completa del producto: ', vm.product);
    }

    /**
     * Establece la información parcial del producto,
     * la que podemos rescatar desde el catálogo
     * @param product
     */
    function setPartialProductInfo(product) {
      vm.product.title = product.titulo;
      vm.product.subtitle = product.subtitulo;

      // Breadcrumbs
      vm.breadcrumbs.categoryName = product.categoryName;
      vm.breadcrumbs.categoryId = product.categoryId;
      vm.breadcrumbs.subcategoryName = product.sectionName;
      vm.breadcrumbs.subcategoryId = product.subcategoryId;

      // Imagen
      vm.product.pictures_web = [product.imagen];
      vm.product.imageMasks = [1];
      $timeout($scope.initImagesSlider);

      $scope.sku = product.sku;

      vm.busy = false;
      console.info('Información parcial del producto: ', vm.product);
    }

    function goHomeFromBreadcrumbs() {
      HomeNavigationService.goHome();
      MetaTagService.updateMetaTags('home');
    }

    function goToCategory() {
      HomeNavigationService.goToCategory(vm.breadcrumbs.categoryId, vm.breadcrumbs.categoryName);
    }

    function goToSubCategory() {
      HomeNavigationService.goToSubCategory(
        vm.breadcrumbs.categoryId,
        vm.breadcrumbs.categoryName,
        vm.breadcrumbs.subcategoryId
      );
    }
  }
})();
