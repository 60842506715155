(function () {
  'use strict';

  angular.module('config').constant('config', {
    AUTH_TOKEN: 'auth_token',
    URL_API: 'https://ehma4c50j3.execute-api.sa-east-1.amazonaws.com/prod',
    URL_API_LOG: 'https://log.forkchile.cl/log',
    URL_API_UTIL: 'https://apiutils.forkchile.cl',
    DOMAIN: 'forkchile.cl',
    LANDING_URL: 'https://nosotros.forkchile.cl',
    ENV: 'prod',
    FORK_API_KEY:
      '8lYrd1f8Z289sgHAc1Nq86xJQUZT4zUUssTDoo2t8FBbRFiMRQ7KB5IWO6P89m00cHUOLwd2zJInoj2Tr8K',
    COORDINADOR_API_KEY:
      '8lYrd1fiZ289sgHAc1Nq66xJQUZT4zUUssTDoo2t8FBbRFiMRQ7KBdIWO6Pk9m00cHUOLwd2zJInoj2Tr8K',
  });
})();
