(function () {
  'use strict';

  angular.module('shared.bannerSlider').component('bannerSliderComponent', {
    templateUrl: 'shared/banner-slider/banner-slider.template.html',
    controller: BannerSliderComponent,
    controllerAs: 'vm',
    bindings: {
      navbarMessageIsActive: '<',
    },
  });

  function BannerSliderComponent(
    $rootScope,
    $state,
    $timeout,
    $window,
    ContentfulService,
    ScrollService
  ) {
    const vm = this;

    vm.ready = false; // El componente no estará listo hasta que se inicie el slider
    vm.banners = []; // Banners que se mostrarán en la vista

    vm.$onInit = onInit;
    vm.handleRedirect = handleRedirect;

    function onInit() {
      // Obtener banners
      ContentfulService.getBanners().then((value) => {
        $timeout(() => {
          vm.banners = value;
          vm.ready = true;
        });
      });
    }

    function scrollTop(duration) {
      $('html,body').animate({ scrollTop: 0 }, duration);
    }

    /**
     * Maneja el redireccionamiento de la ruta del banner
     * @param banner
     */
    function handleRedirect(banner) {
      if (banner.url) {
        if (/\/categoria/.test(banner.url)) {
          const splitUrl = banner.url.split('/');
          const categoryId = splitUrl[4];
          const categoryName = splitUrl[5];

          // $rootScope.$broadcast('changeCategory', {
          // categoryId: categoryId,
          // categoryName: categoryName,
          // });
          // ScrollService.scrollToCatalog();
          $state.go('home.category', {
            categoryId: categoryId,
            categoryName: categoryName,
          });

          scrollTop(0);
          console.info(`Broadcast: ¡Cambiar a la categoría ${categoryId}!`);
        } else {
          $window.open(banner.url, banner.target);
        }
      }
    }
  }
})();
