(function () {
  'use strict';

  angular.module('services.alert').factory('AlertService', AlertService);

  function AlertService($rootScope, GlobalService, LogService) {
    // Tipos de alertas
    const WARNING = 'warning';
    const INFO = 'info';

    // Mensajes comunes
    const CLOSED = 'Cerrado',
      DELIVERY_CLOSED_STORE = 'Temporalmente sin delivery',
      DELIVERY_DOESNT_EXIST = 'Dirección no disponible',
      DEL_ADDRESS = 'Eliminarás esta dirección de tu perfil',
      DEL_COMMENT = 'Eliminar comentario',
      DEL_DELIVERY_ADDRESS = '¡Dirección está siendo usada para reparto!',
      DEL_ITEM = 'Quitarás del canasto todas las unidades de',
      DEL_PAYMENT_METHOD = 'Eliminarás este medio de pago de tu perfil',
      DELIVERY_UNAVAILABLE = 'No hay delivery disponible',
      DEL_ADDRESS_BILLING = 'Eliminarás esta dirección de tu perfil',
      EXIT_RATING = 'Salir de la evaluación',
      PURCHASE_FAILED = 'No se pudo procesar tu pedido',
      ITEM_WILL_LOSE_PLUR = 'Se perderán algunos artículos',
      ITEM_WILL_LOSE_SING = 'Se perderá un artículo',
      QUIT_RATING = 'Evaluación incompleta',
      SCHEDULE_UNAVAILABLE = 'El bloque de reparto seleccionado ya no se encuentra disponible',
      SEE_YOU_LATER = '¡Nos vemos pronto!',
      WITHOUT_DELIVERY = 'Dirección fuera de la zona de reparto';

    const // ADDRESS_DOESNT_EXIST = 'Puedes retirar en tienda',
      ALRIGHT = '¿De acuerdo?',
      CANT_LOAD_ONECLICK = 'Los datos de tu tarjeta OneClick no pudieron ser cargados',
      DEL_COMMENT_DESC = '¿Estás seguro que quieres eliminar este comentario?',
      DEL_DELIVERY_ADDRESS_DESC = 'Podrás eliminar una vez que no esté en uso',
      DELIVERY_UNAVAILABLE_DESC =
        'En este momento no podemos llevar el pedido a tu dirección. ¿Quieres retirarlo en tienda?',
      EXIT_RATING_NOW = '¿Estás seguro que quieres abandonar la evaluación ahora?',
      ITEM_NOT_AVAILABLE_PLUR = 'Los siguientes artículos ya no están disponibles',
      ITEM_NOT_AVAILABLE_SING = 'El siguiente artículo ya no está disponible',
      ITEM_SOLD_OUT_PLUR = 'Los siguientes artículos están agotados',
      ITEM_SOLD_OUT_SING = 'El siguiente artículo está agotado',
      LOGOUT_CONFIRM = 'Confirma que quieres cerrar tu sesión',
      MODIFIED_CONFIG_DELIVERY = 'En este momento no hay delivery para la dirección',
      QUIT_RATING_ANYWAY =
        'No has respondido todas las preguntas ¿Quieres enviar la evaluación igual?',
      STORE_WITHDRAWAL = 'Puedes retirar en tienda',
      TRY_ANOTHER_SCHEDULE = 'Selecciona otro horario',
      TRY_LATER = 'Por favor, inténtalo más tarde',
      WITHOUT_DELIVERY_DESC =
        'Agregamos la dirección a tu cuenta pero aún no podemos repartir a ella ¯\\_(ツ)_/¯';

    const ACCEPT = 'Aceptar',
      BETTER_NOT = 'Mejor no',
      DAMN = '¡Pucha!',
      LEAVE = 'Abandonar',
      LOGOUT = 'Cerrar sesión',
      NO_THANKS = 'No, gracias',
      NOT_YET = 'No todavía',
      OKAY = 'Bueno',
      UNDO = 'Cancelar',
      YES_CONTINUE = 'Sí, continuar',
      YES_DELETE = 'Sí, eliminar',
      YES_SEND = 'Sí, enviar';

    return {
      addressDoesntExist: addressDoesntExist,
      addressFromClosedStore: addressFromClosedStore,
      addressWithoutDelivery: addressWithoutDelivery,
      cantLoadOneClick: cantLoadOneClick,
      deleteAddress: deleteAddress,
      deleteDeliveryAddress: deleteDeliveryAddress,
      deleteOrderComments: deleteOrderComments,
      deletePaymentMethod: deletePaymentMethod,
      deleteProduct: deleteProduct,
      deliveryUnavailable: deliveryUnavailable,
      exitRating: exitRating,
      genericPurchaseError: genericPurchaseError,
      holiday: holiday,
      logout: logout,
      notEnoughProducts: notEnoughProducts,
      oneclickError: oneclickError,
      purchaseServerError: purchaseServerError,
      quitRating: quitRating,
      scheduleUnavailable: scheduleUnavailable,
      stockDifference: stockDifference,
      deleteAddressBilling: deleteAddressBilling
    };

    /**
     * La dirección no tiene delivery temporalmente
     * @param {object} address Dirección que no tiene delivery.
     * @param {object} store Tienda en la que se sugiere retirar.
     */
    function addressFromClosedStore(address, store) {
      showSystemAlert(
        WARNING,
        DELIVERY_CLOSED_STORE,
        `${MODIFIED_CONFIG_DELIVERY} "${address.name}". ${STORE_WITHDRAWAL} ${store.name}`,
        null,
        OKAY
      );
    }

    /**
     * La dirección no existe
     * @param {object} store Tienda en la que se sugiere retirar
     */
    function addressDoesntExist(store) {
      showSystemAlert(
        WARNING,
        DELIVERY_DOESNT_EXIST,
        `${STORE_WITHDRAWAL} ${store.name}`,
        null,
        OKAY
      );
    }

    /**
     * Agregar dirección que no tiene área de delivery
     * @param {function} affirmativeFunction Función de la acción "positiva"
     */
    function addressWithoutDelivery(affirmativeFunction) {
      showSystemAlert(
        WARNING,
        WITHOUT_DELIVERY,
        WITHOUT_DELIVERY_DESC,
        null,
        OKAY,
        affirmativeFunction ? affirmativeFunction : null
      );
    }

    /**
     * Al intentar comprar, no se pudo cargar los datos de la tarjeta One Click
     */
    function cantLoadOneClick() {
      showSystemAlert(WARNING, PURCHASE_FAILED, CANT_LOAD_ONECLICK, null, ACCEPT);
    }

    /**
     * Eliminar dirección
     * @param {function} negativeFunction Función de la acción "negativa"
     */
    function deleteAddress(negativeFunction) {
      showSystemAlert(
        WARNING,
        DEL_ADDRESS,
        ALRIGHT,
        null,
        UNDO,
        null,
        YES_DELETE,
        negativeFunction
      );
    }
    
    /**
     * Eliminar Billing
     * @param {function} negativeFunction Función de la acción "negativa"
     */
    function deleteAddressBilling(negativeFunction) {
      showSystemAlert(
        WARNING,
        DEL_ADDRESS_BILLING,
        ALRIGHT,
        null,
        UNDO,
        null,
        YES_DELETE,
        negativeFunction
      );
    }

    /**
     * Intentar eliminar dirección seleccionada para delivery
     */
    function deleteDeliveryAddress() {
      showSystemAlert(WARNING, DEL_DELIVERY_ADDRESS, DEL_DELIVERY_ADDRESS_DESC, null, ACCEPT);
    }

    /**
     * Eliminar el comentario de un pedido
     * @param {function} negativeFunction Función de la acción "negativa"
     */
    function deleteOrderComments(negativeFunction) {
      showSystemAlert(
        WARNING,
        DEL_COMMENT,
        DEL_COMMENT_DESC,
        null,
        BETTER_NOT,
        null,
        YES_DELETE,
        negativeFunction
      );
    }

    /**
     * Eliminar método de pago
     * @param {function} negativeFunction Función de la acción "negativa"
     */
    function deletePaymentMethod(negativeFunction) {
      showSystemAlert(
        WARNING,
        DEL_PAYMENT_METHOD,
        ALRIGHT,
        null,
        UNDO,
        null,
        YES_DELETE,
        negativeFunction
      );
    }

    /**
     * Eliminar un producto del carro de compras
     * @param {string} productName Nombre del producto
     * @param {function} affirmativeFunction Functión de la acción "positiva"
     * @param {function} negativeFunction Functión de la acción "negativa"
     */
    function deleteProduct(productName, affirmativeFunction, negativeFunction) {
      showSystemAlert(
        WARNING,
        `${DEL_ITEM} ${productName}`,
        ALRIGHT,
        null,
        BETTER_NOT,
        affirmativeFunction,
        YES_DELETE,
        negativeFunction
      );
    }

    /**
     * No hay delivery disponible
     * @param {function} affirmativeFunction Función de la acción "afirmativa"
     */
    function deliveryUnavailable(affirmativeFunction) {
      showSystemAlert(
        WARNING,
        DELIVERY_UNAVAILABLE,
        DELIVERY_UNAVAILABLE_DESC,
        null,
        OKAY,
        affirmativeFunction,
        NO_THANKS
      );
    }

    /**
     * Abandonar la evaluación de la experiencia
     * @param {function} negativeFunction Functión a ejecutar cuando se ciere el modal
     */
    function exitRating(negativeFunction) {
      showSystemAlert(
        WARNING,
        EXIT_RATING,
        EXIT_RATING_NOW,
        null,
        NOT_YET,
        null,
        LEAVE,
        negativeFunction
      );
    }

    /**
     * Mensaje de error genérico cuando falla la compra
     */
    function genericPurchaseError() {
      showSystemAlert(WARNING, PURCHASE_FAILED, TRY_LATER, null, ACCEPT);
    }

    /**
     * Intento de compra en un día feriado
     * @param {string} code Código del error
     * @param {string} subtitle Subtítulo o descripción del feriado
     */
    function holiday(subtitle) {
      showSystemAlert(WARNING, CLOSED, subtitle, null, OKAY);
    }

    /**
     * Cerrar sesión
     * @param {function} negativeFunction Función de la acción "negativa"
     */
    function logout(negativeFunction) {
      showSystemAlert(
        WARNING,
        SEE_YOU_LATER,
        LOGOUT_CONFIRM,
        null,
        UNDO,
        null,
        LOGOUT,
        negativeFunction
      );
    }

    /**
     * No hay suficientes productos en stock.
     * @param {object[]} dropItems Objetos que fueron eliminados del carro
     * @param {function} affirmativeFunction Función de la acción "afirmativa"
     */
    function notEnoughProducts(dropItems, affirmativeFunction) {
      const { kind, address, store } = GlobalService.configDelivery;
      let title = '';
      let subtitle = '';

      // Los mensajes son plurales, dependiendo de la cantidad de objetos que se eliminaron del carro.
      if (dropItems.length === 1 && dropItems[0].over === 1) {
        title = ITEM_WILL_LOSE_SING;
        subtitle = ITEM_SOLD_OUT_SING;
      } else {
        title = ITEM_WILL_LOSE_PLUR;
        subtitle = ITEM_SOLD_OUT_PLUR;
      }

      subtitle +=
        kind === 0 ? ` para la dirección "${address.name}".` : ` en tienda ${store.name}.`;

      showSystemAlert(WARNING, title, subtitle, dropItems, DAMN, affirmativeFunction);
    }

    /**
     * Error ocasionado al intentar comprar con oneclick
     * @param message Mensaje de la razón que devuelve el servidor
     * @param kind Tipo de entrega, para saber qué solución mostrarle al usuario
     */
    function oneclickError(message, kind) {
      let subtitle = message + '\r\n\r\nPaga ';

      subtitle += kind === 0 ? 'contra entrega' : 'en tienda';
      subtitle += ' o usa otra tarjeta';

      showSystemAlert(WARNING, PURCHASE_FAILED, subtitle, null, DAMN);
    }

    /**
     * Error en la compra. El servidor sabe el por qué y nos da la razón
     * @param message Mensaje del servidor
     */
    function purchaseServerError(message) {
      showSystemAlert(WARNING, PURCHASE_FAILED, message, null, ACCEPT);
    }

    /**
     * Salir del rating sin haber respondido todas las preguntas
     * @param affirmativeFunction Función de la acción "positiva"
     * @param negativeFunction Función de la acción "negativa"
     */
    function quitRating(affirmativeFunction, negativeFunction) {
      showSystemAlert(
        INFO,
        QUIT_RATING,
        QUIT_RATING_ANYWAY,
        null,
        NOT_YET,
        affirmativeFunction,
        YES_SEND,
        negativeFunction
      );
    }

    /**
     * El bloque de reparto seleccionado ya no se encuentra disponible
     */
    function scheduleUnavailable() {
      showSystemAlert(WARNING, SCHEDULE_UNAVAILABLE, TRY_ANOTHER_SCHEDULE, null, ACCEPT);
    }

    /**
     * Diferencia de stock cuando el usuario cambia la tienda
     * @param {object} targetConfigDelivery Configuración de entrega destino
     * @param {object[]} dropItems Objetos que fueron eliminados del carro
     * @param {function} affirmativeFunction Función del botón de la acción 'afirmativa'
     * @param {function} negativeFunction Función del botón de la acción 'negativa'
     */
    function stockDifference(
      targetConfigDelivery,
      dropItems,
      affirmativeFunction,
      negativeFunction
    ) {
      let title = '',
        subtitle = '',
        negativeMessage = '';

      // Los mensajes son plurales, dependiendo de la cantidad de objetos que se eliminaron del carro.
      if (dropItems.length === 1 && dropItems[0].cantidad === 1) {
        title = ITEM_WILL_LOSE_SING;
        subtitle = ITEM_NOT_AVAILABLE_SING;
      } else {
        title = ITEM_WILL_LOSE_PLUR;
        subtitle = ITEM_NOT_AVAILABLE_PLUR;
      }

      subtitle +=
        targetConfigDelivery.kind === 0
          ? ` para la dirección "${targetConfigDelivery.address.name}"`
          : ` en tienda ${targetConfigDelivery.store.name}`;

      if (negativeFunction) {
        negativeMessage = BETTER_NOT;
      }

      showSystemAlert(
        WARNING,
        title,
        subtitle,
        dropItems,
        YES_CONTINUE,
        affirmativeFunction ? affirmativeFunction : null,
        negativeMessage,
        negativeFunction ? negativeFunction : null
      );
    }

    /**
     * Mostrar ventana modal que representa un alerta de sistema
     * @param {string} type Tipo del alerta. Por ejemplo, 'warning', 'info'...
     * @param {string} title Título del modal
     * @param {string} subtitle Subtítulo/Mensaje del modal
     * @param {object[]} details (Opcional) Detalles del mensaje. Por ejemplo, lista de productos
     * @param {string} affirmativeMessage Texto del botón de la acción 'positiva'
     * @param {function} affirmativeFunction Función del botón de la acción 'positiva'
     * @param {string} negativeMessage Texto del botón de la acción 'negativa'
     * @param {function} negativeFunction Functión del botón de la acción 'negativa'
     */
    function showSystemAlert(
      type,
      title,
      subtitle,
      details,
      affirmativeMessage,
      affirmativeFunction,
      negativeMessage,
      negativeFunction
    ) {
      $rootScope.$broadcast('openSystemAlertModal', {
        type: type,
        title: title,
        subtitle: subtitle,
        details: details,
        affirmativeMessage: affirmativeMessage,
        affirmativeFunction: affirmativeFunction,
        negativeMessage: negativeMessage,
        negativeFunction: negativeFunction,
      });
      console.info('Broadcast: ¡Abrir el modal de alerta!');
    }
  }
})();
