(function () {
  'use strict';

  angular.module('shared.addressModal').component('addressModalComponent', {
    templateUrl: 'shared/address-modal/address-modal.template.html',
    controller: AddressModalController,
    controllerAs: 'vm',
  });

  function AddressModalController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    NgMap,
    AddressService,
    AlertService,
    AnalyticsService,
    CartService,
    GlobalService,
    LogService,
    StoreService,
    UserService
  ) {
    var vm = this;

    $scope.global = GlobalService;

    // Variable para manejar qué mostrar en la vista
    vm.source = 0;
    vm.busy = false;
    vm.currentStep = 1;

    // Variables para saber de dónde se ha llamado el modal
    var CONFIG_DELIVERY = 'config-delivery-modal',
      ONBOARDING = 'onboarding',
      USER_NEW_ADDRESS = 'user-new-address',
      USER_EDIT_ADDRESS = 'user-edit-address';

    // Formulario
    vm.comment = '';

    var ADDRESS_EMPTY = {
      id: 0,
      name: '',
      street_name: '',
      number: '',
      unit_number: '',
      commune_id: null,
    };
    vm.address = angular.copy(ADDRESS_EMPTY);

    vm.readOnly = {
      street_name: false,
      number: false,
      commune_id: false,
    };

    // Google maps
    vm.map = null;
    vm.coordinates = {
      latitude: 0,
      longitude: 0,
    };

    vm.communes = [];

    // Mensaje al usuario
    vm.showUserMessage = false;
    vm.userMessage = '';
    vm.userMessageType = '';

    // Funciones enlazadas a la vista
    vm.$onInit = onInit;
    vm.closeAddressModal = closeAddressModal;
    vm.getGeoReference = getGeoReference;
    vm.saveAddress = saveAddress;
    vm.setCoords = setCoords;
    vm.stepBack = stepBack;

    // Funciones enlazadas al controlador
    $scope.addNewAddress = addNewAddress;
    $scope.adjustCart = adjustCart;
    $scope.checkCart = checkCart;
    $scope.editAddress = editAddress;
    $scope.editAddressWithoutUser = editAddressWithoutUser;

    // Funciones enlazadas al rootScope
    $rootScope.$on('openAddressModal', openAddressModal);

    function onInit() {
      // Obtener el objeto mapa de google maps
      NgMap.getMap().then((map) => {
        vm.map = map;
      });
    }

    /**
     * Agregar nueva dirección
     */
    function addNewAddress() {
      AddressService.addAddress(vm.address)
        .then((value) => {
          const newAddress = value.data;
          GlobalService.addresses.unshift(newAddress);
          console.info('Direcciones:', GlobalService.addresses);
          // Si ocurriera un error y el modal se invocó desde la configuración de entrega,
          // los "exitos" o "errores" se manejan desde allá
          if (vm.source === CONFIG_DELIVERY) {
            vm.closeAddressModal(true, newAddress);
            // De lo contrario, los manejamos desde aquí
          } else {
            if (!newAddress.delivery_available) {
              AlertService.addressWithoutDelivery();
            } else {
              // Si no hay problemas con la dirección, establecerla en la configuración de entrega
              GlobalService.configDelivery.kind = 0;
              GlobalService.configDelivery.address = newAddress;
              StoreService.getStore(newAddress.store_id).then((value) => {
                GlobalService.configDelivery.store = value.data;
                UserService.setUserConfig(GlobalService.configDelivery);
              });
            }
            vm.closeAddressModal(true);
            AnalyticsService.createAddress(newAddress);
          }
        })
        .catch(function (reason) {
          vm.closeAddressModal(false, reason.data.message);
        });
    }

    function adjustCart(dropItems) {
      CartService.adjustCart(GlobalService.cart, dropItems, GlobalService.configDelivery).then(
        (value) => {
          GlobalService.cart = value.data.response;
        }
      );
    }

    function checkCart() {
      if (GlobalService.cart.productos.length) {
        CartService.checkCart(GlobalService.configDelivery.store).then((value) => {
          const dropItems = value.data.response.productosNoEncontrados;

          if (dropItems && dropItems.length) {
            // Mostrar alerta del sistema
            AlertService.stockDifference(GlobalService.configDelivery, dropItems, () => {
              return $scope.adjustCart(dropItems);
            });
          }
        });
      }
    }

    /**
     * Canal de comunicación en el que el modal de direcciones pueda hacerle saber a otros
     * componentes qué es lo que está ocurriendo
     * @param {boolean} success La operación fue un exito
     * @param {string|object} data Información adicional. Puede ser una dirección o un mensaje de exito/error
     */
    function closeAddressModal(success, data) {
      $('#address-modal-component').modal('hide');

      if (vm.source === CONFIG_DELIVERY) {
        $timeout(() => {
          var state = 'address-modal-';
          state += success ? 'success' : 'failure';
          $rootScope.$broadcast('openConfigDeliveryModal', { state: state, data: data });
          console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
        });
      } else if (vm.source === USER_NEW_ADDRESS) {
        if (success) {
          $rootScope.$broadcast('addressCreated');
          console.info('Broadcast: ¡Se agregó una nueva dirección!');
        } else {
          $rootScope.$broadcast('addressError', { reason: data });
          console.info('Broadcast: ¡Ocurrió un error al agregar una nueva dirección!');
        }
      } else if (vm.source === USER_EDIT_ADDRESS) {
        if (success) {
          $rootScope.$broadcast('addressEdited');
          console.info('Broadcast: ¡Se modificó la dirección!');
        } else {
          $rootScope.$broadcast('addressError', { reason: data });
          console.info('Broadcast: ¡Ocurrió un error al intentar modificar la dirección!');
        }
      } else if (vm.source === ONBOARDING) {
        $timeout(() => {
          $rootScope.$broadcast('openConfigDeliveryModal', { state: ONBOARDING, data: data });
          console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
        });
      }
    }

    /**
     * Editar una dirección existente
     */
    function editAddress() {
      AddressService.modifyAddress(vm.address)
        .then((response) => {
          const configDelivery = GlobalService.configDelivery;
          const editedAddress = response.data;

          // Editar dirección de la lista
          const editedAddressIndex = GlobalService.addresses.findIndex((address) => {
            return address.id === editedAddress.id;
          });

          GlobalService.addresses[editedAddressIndex] = editedAddress;

          // Si no hay reparto disponible, mostrar alerta del sistema
          if (!editedAddress.delivery_available) {
            AlertService.addressWithoutDelivery();

            // OJO: Si la dirección no tiene reparto y está seleccionada en la modalidad de entrega,
            // cambiar la modalidad a retiro en tienda
            if (
              configDelivery.kind === 0 &&
              configDelivery.address &&
              editedAddress.id === configDelivery.address.id
            ) {
              StoreService.getStores().then((value) => {
                GlobalService.configDelivery.kind = 1;
                GlobalService.configDelivery.address = null;
                GlobalService.configDelivery.store = value[0];
              });
            }
          } else {
            StoreService.getStores().then((value) => {
              GlobalService.configDelivery.kind = 0;
              GlobalService.configDelivery.address = editedAddress;
              GlobalService.configDelivery.store = value.find((store) => {
                return store.id === editedAddress.store_id;
              });
            });
          }

          // Verificar carro
          $scope.checkCart();

          // Actualizar configuración de entrega
          UserService.setUserConfig(GlobalService.configDelivery);

          // Cerrar modal
          vm.closeAddressModal(true, null);

          AnalyticsService.editAddress(editedAddress);
        })
        .catch((reason) => {
          vm.closeAddressModal(false, reason.data.message);
        });
    }

    /**
     * Editar una dirección que no tiene usuario
     */
    function editAddressWithoutUser() {
      vm.address.without_user = true;

      AddressService.modifyAddress(vm.address)
        .then((response) => {
          const editedAddress = response.data;

          // Si no hay reparto disponible, mostrar alerta del sistema
          if (!editedAddress.delivery_available) {
            AlertService.addressWithoutDelivery(vm.getGeoReference);
          } else {
            GlobalService.addresses.push(editedAddress);
            StoreService.getStores().then((value) => {
              const stores = value;

              GlobalService.configDelivery.kind = 0;
              GlobalService.configDelivery.address = editedAddress;
              GlobalService.configDelivery.store = stores.find(function (el) {
                return el.id === editedAddress.store_id;
              });

              // Verificar carro
              $scope.checkCart();

              // Cerrar modal
              vm.closeAddressModal(true, editedAddress);

              // Ocultar la ruta onboarding
              $state.go('home');
            });
          }
        })
        .catch((reason) => {
          vm.error = reason.data.message;
        })
        .finally(() => {
          vm.busy = false;
        });
    }

    /**
     * Validar dirección
     */
    function getGeoReference() {
      vm.busy = true; // Mostrar loader
      vm.showUserMessage = false;

      const commune = vm.communes.find(function (el) {
        return el.id === vm.address.commune_id;
      });
      const address =
        vm.address.street_name + ' ' + vm.address.number + ' ' + commune.name + ' Chile';

      AddressService.getGeoReferenceAddress(address)
        .then(function (value) {
          const data = value.data;

          if (data.status === 'OK') {
            const { lat, lng } = data.results[0].geometry.location;
            vm.coordinates.latitude = lat;
            vm.coordinates.longitude = lng;

            vm.currentStep = 2;
          } else {
            vm.showUserMessage = true;
            vm.userMessage =
              'No hubo resultados en la búsqueda de la ubicación geográfica de Google Maps';
            vm.userMessageType = 'warning';
          }
        })
        .finally(() => {
          vm.busy = false;
        });
    }

    /**
     * Abrir modal de direcciones
     */
    function openAddressModal(event, opt) {
      vm.source = opt.source;
      vm.busy = false;
      vm.currentStep = 1;

      // Limpiar estado del formulario
      $scope.addressForm.$setPristine();
      $scope.addressForm.$setUntouched();

      // Cargar lista de comunas
      if (!vm.communes.length) {
        AddressService.getCommunes().then((value) => {
          vm.communes = value;
        });
      }

      // Establecer el valor de los campos del formulario
      if (vm.source === USER_EDIT_ADDRESS) {
        const { commune, id, name, number, street_name, unit_number, comment } = opt.address;

        vm.address.commune_id = commune.id;
        vm.address.id = id;
        vm.address.name = name;
        vm.address.number = number;
        vm.address.street_name = street_name;
        vm.address.unit_number = unit_number;
        vm.comment = comment;

        $('#address-modal-component').modal('show');
        AnalyticsService.initEditAddress(opt.address, vm.source);
      } else if (vm.source === USER_NEW_ADDRESS || vm.source === CONFIG_DELIVERY) {
        vm.address = angular.copy(ADDRESS_EMPTY);
        vm.comment = '';

        $('#address-modal-component').modal('show');
        AnalyticsService.initCreateAddress(vm.source);
      } else if (vm.source === ONBOARDING) {
        if ($stateParams && $stateParams.addressId) {
          vm.busy = true;
          AddressService.getAddressWithoutUser($stateParams.addressId)
            .then(function (value) {
              const { commune, id, number, street_name } = value.data;

              vm.address.id = id;

              vm.address.street_name = street_name;
              vm.address.number = number;
              vm.address.commune_id = commune ? commune.id : null;

              vm.readOnly.street_name = !!street_name;
              vm.readOnly.number = !!number;
              vm.readOnly.commune_id = !!commune;

              $('#address-modal-component').modal('show');
              AnalyticsService.initCreateAddress(vm.source);
            })
            .catch((reason) => {
              console.error(reason);
              $state.go('home');
            })
            .finally(() => {
              vm.busy = false;
            });
        } else {
          const message =
            'Se está intentando abrir el modal de direcciones en el flujo onboarding sin un id de dirección en la ruta';
          console.warn(message);
          LogService.unexpectedCase(vm.constructor.name, message);
          $state.go('home');
        }
      } else {
        const message =
          'Se está intentando abrir el modal de direcciones desde un origen desconocido';
        console.warn(message);
        LogService.unexpectedCase(vm.constructor.name, message);
        $state.go('home');
      }
    }

    /**
     * Agregar nueva dirección
     */
    function saveAddress() {
      vm.busy = true;
      vm.address.comment = vm.comment;
      vm.address.coordinates = vm.coordinates;
      if (vm.source === USER_EDIT_ADDRESS) {
        $scope.editAddress();
      } else if (vm.source === CONFIG_DELIVERY || vm.source === USER_NEW_ADDRESS) {
        $scope.addNewAddress();
      } else if (vm.source === ONBOARDING) {
        $scope.editAddressWithoutUser();
      } else {
        const message =
          'Se está intentando agregar/editar una dirección desde un origen desconocido';
        console.warn(message);
        LogService.unexpectedCase(vm.constructor.name, message);
      }
    }

    /**
     * Establecer las coordenadas del mapa después de haber modificado el cursor
     */
    function setCoords() {
      vm.coordinates.latitude = vm.map.getCenter().lat();
      vm.coordinates.longitude = vm.map.getCenter().lng();
    }

    /**
     * Volver al paso anterior. No permitir devolverse al paso 0 o -1...
     */
    function stepBack() {
      vm.currentStep = vm.currentStep - 1 || 1;
    }
  }
})();
