(function () {
  'use strict';

  angular.module('directives.productSchema').directive('productSchema', ProductSchema);

  function ProductSchema($document, $location) {
    return {
      scope: {
        product: '=',
      },
      link: function (scope, element) {
        if (scope.product) {
          var script = $document[0].createElement('script');
          script.type = 'application/ld+json';
          script.text = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            productID: scope.product.id,
            sku: scope.product.items[0].portion.sku,
            name: scope.product.title + ' ' + scope.product.subtitle,
            description: scope.product.description,
            url: $location.absUrl(),
            image: scope.product.pictures_web[0],
            brand: 'Fork',
            offers: [
              {
                '@type': 'Offer',
                price: scope.product.items[0].unit_price,
                priceCurrency: 'CLP',
                itemCondition: 'https://schema.org/NewCondition',
                availability: 'https://schema.org/InStock',
              },
            ],
          });

          element.append(script);
        }
      },
    };
  }
})();
