(function () {
  'use strict';

  angular.module('services.cookies').factory('CookiesService', CookiesService);

  function CookiesService($cookies, $http, config) {
    return {
      getForkLifeModal: getForkLifeModal,
      setForkLifeModal: setForkLifeModal,
      getAuthToken: getAuthToken,
      setUser: setUser,
      removeUser: removeUser,
      getGuestUser: getGuestUser,
      setGuestUser: setGuestUser,
      removeGuestUser: removeGuestUser,
      getPurchaseSucceed: getPurchaseSucceed,
      setPurchaseSucceed: setPurchaseSucceed,
    };

    /**
     * Obtiene la cookie del forkLifeModal.
     * Es para saber si el modal debe mostrarse o no
     * @param user
     * @returns {*}
     */
    function getForkLifeModal(user) {
      return $cookies.get(`forkLifeModal${user.id}`);
    }

    /**
     * Establece el forkLifeModal.
     * La idea es que no se vuelva a mostrar...
     * @param user
     * @param show
     */
    function setForkLifeModal(show, user) {
      const expires = getExpiresDate();
      $cookies.put(`forkLifeModal${user.id}`, show, { domain: config.DOMAIN, expires: expires });
    }

    function getAuthToken() {
      return $cookies.get(config.AUTH_TOKEN);
    }

    /**
     * Establecer el token del usuario
     * @param {string} token Token de usuario
     */
    function setUser(token) {
      const expires = getExpiresDate();
      $cookies.put(config.AUTH_TOKEN, token, { domain: config.DOMAIN, expires: expires });
      $http.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    function removeUser() {
      $cookies.remove(config.AUTH_TOKEN, { domain: config.DOMAIN });
    }

    /**
     * Usuario invitado
     */
    function getGuestUser() {
      let guestUser = $cookies.get(`fork_guest_user`);
      if (guestUser) {
        return JSON.parse(guestUser);
      }

      return false;
    }

    function setGuestUser(user) {
      const expires = getExpiresDate();
      user = JSON.stringify(user);
      $cookies.put('fork_guest_user', user, { domain: config.DOMAIN, expires: expires });
    }

    function removeGuestUser() {
      $cookies.remove(`fork_guest_user`);
    }

    /**
     * Éxito en la compra
     */
    function getPurchaseSucceed() {
      return $cookies.get('purchase');
    }

    function setPurchaseSucceed(orderId) {
      const expires = getExpiresDate();
      $cookies.put('purchase', orderId, { domain: config.DOMAIN, expires: expires });
    }

    /**
     * Obtener la fecha de expiración de una cookie
     */
    function getExpiresDate() {
      let expires = new Date();
      expires.setFullYear(expires.getFullYear() + 3);
      return expires;
    }
  }
})();
