(function () {
  'use strict';

  angular
    .module('pages.purchaseSucceed')
    .controller('PurchaseSucceedController', PurchaseSucceedController);

  function PurchaseSucceedController(
    $document,
    $cookies,
    $sce,
    $scope,
    $state,
    $stateParams,
    $timeout,
    confetti,
    AnalyticsService,
    CookiesService,
    CartService,
    GlobalService,
    OrderService,
    UserService
  ) {
    var vm = this;
    $scope.global = GlobalService;
    $scope.confetti = confetti;
    $scope.mostrarAnalytics = false;

    var unregisterCart = function () {
      return undefined;
    };

    vm.busy = false;
    vm.order = null;
    vm.subtitle = '';
    vm.html = '';
    vm.$onInit = onInit;
    vm.usuarioNoRegistrado = false;

    $scope.$on('$destroy', onDestroy);

    function onInit() {
      if (GlobalService.user === null) {
        vm.usuarioNoRegistrado = true;
      }
      // Scroll al inicio de la pagina
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;

      // Eliminar el carro, si es que el usuario pagó con WebPay
      unregisterCart = $scope.$watch('global.cart', function (newValue) {
        if (newValue) {
          CartService.deleteCart();
          GlobalService.cart = {
            productos: [],
          };
          unregisterCart();
        }
      });

      // Mostrar pedido, si es que los parámetros de la ruta son correctos
      if ($stateParams && $stateParams.orderId) {
        var orderId = $stateParams.orderId;

        // Tirar confetti
        $timeout(() => {
          $scope.confetti({
            particleCount: 60,
            spread: 90,
            ticks: 400,
          });
        }, 500);

        vm.busy = true;

        OrderService.getVoucher(orderId).then(function (value) {
          vm.html = $sce.trustAsHtml(value.data.html);
          vm.busy = false;
        });

        OrderService.getOrder(orderId).then(
          (value) => {
            vm.order = value.data;
            vm.subtitle =
              vm.order.kind === 0
                ? 'espéranos con la mesa puesta'
                : 'te esperamos en ' + vm.order.store.name;

            // Actualizar usuario, para ajustar créditos
            if (GlobalService.user) {
              UserService.getUser().then((value) => {
                GlobalService.user = value.data;
              });
            }
            // Enviar analíticas
            // Verificar en las cookies si la compra realizada con el order.id ya fue enviada a las analíticas, debido a
            // que el evento estaba siendo enviado múltiples veces si el usuario refrescaba la vista.
            const lastOrderId = CookiesService.getPurchaseSucceed();
            if (!lastOrderId || parseInt(lastOrderId) < vm.order.id) {
              AnalyticsService.purchaseSucceed(vm.order);
              CookiesService.setPurchaseSucceed(vm.order.id);
            }
          },
          function () {
            $state.go('home');
          }
        );
      } else {
        $state.go('home');
      }
    }

    function onDestroy() {
      unregisterCart();
    }
  }
})();
