(function () {
  'use strict';

  angular.module('services.order').service('OrderService', OrderService);

  function OrderService($http, config) {
    return {
      checkCart: checkCart,
      createOrder: createOrder,
      getOrder: getOrder,
      getOrderByHash: getOrderByHash,
      getOrderToRate: getOrderToRate,
      getOrderToRateByUserHash: getOrderToRateByUserHash,
      getUserOrders: getUserOrders,
      getVoucher: getVoucher,
      sendDetailOrder: sendDetailOrder,
    };

    function checkCart(storeId, cart) {
      return $http.post(`${config.URL_API}/orders/check_cart/${storeId}`, cart);
    }

    function createOrder(objCart) {
      console.log("OBJ CART", objCart);
      return $http.post(`${config.URL_API}/orders`, objCart);
    }

    function getOrder(id) {
      return $http.get(`${config.URL_API}/orders/${id}`);
    }

    /**
     * Obtener el pedido por su id y el hash del usuario, con la finalidad de poder obtener sus datos
     * sin necesidad de que el usuario esté autenticado.
     * @param {number} orderId
     * @param {string} hash
     */
    function getOrderByHash(orderId, hash) {
      return $http.get(`${config.URL_API}/orders/${orderId}/hash/${hash}`);
    }

    /**
     * Obtener el próximo pedido a evaluar
     * @param {number} userId Número identificador del usuario
     */
    function getOrderToRate(userId) {
      return $http.get(`${config.URL_API}/venta/rating/${userId}`, {
        headers: {
          'fork-api-key': config.FORK_API_KEY,
        },
      });
    }

    /**
     * Obtiene el pedido a evaluar por el hash del usuario
     * @param {number} orderId Número identificador del pedido
     * @param {string} userHash Hash del usuario
     */
    function getOrderToRateByUserHash(orderId, userHash, digital) {
      return $http.get(
        `${config.URL_API}/venta/${orderId}/rating?hash=${userHash}&ventaDigital=${digital}`,
        {
          headers: {
            'fork-api-key': config.FORK_API_KEY,
          },
        }
      );
    }

    function getUserOrders() {
      return $http.get(`${config.URL_API}/venta/listado`);
    }

    function getVoucher(orderId) {
      return $http.get(`${config.URL_API}/orders/html/${orderId}`);
    }

    function sendDetailOrder(id) {
      return $http.post(`${config.URL_API}/orders/${id}/email_details`);
    }
  }
})();
