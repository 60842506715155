(function () {
  'use strict';

  angular
    .module('constants.externalLibraries')
    .constant('confetti', confetti)
    .constant('dataLayer', dataLayer)
    .constant('fbq', fbq)
    .constant('ga', ga)
    .constant('gtag', gtag)
    .constant('iDevice', iDevice)
    .constant('mixpanel', mixpanel)
    .constant('moment', moment);
})();
