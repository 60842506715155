(function () {
  'use strict';

  angular.module('pages.checkout', [
    'checkout.configDelivery',
    'checkout.details',
    'checkout.guest-user-form',
    'checkout.configBilling'
  ]);
})();
