(function () {
  'use strict';

  angular.module('shared.systemAlertModal').component('systemAlertModalComponent', {
    templateUrl: 'shared/system-alert-modal/system-alert-modal.template.html',
    controller: SystemAlertModalController,
    controllerAs: 'vm',
  });

  function SystemAlertModalController($rootScope) {
    var vm = this;

    var BASE_IMG_URL = 'assets/img/';

    // Contenido del modal
    vm.buttonClass = '';
    vm.imageUrl = '';
    vm.title = '';
    vm.subtitle = '';
    vm.details = '';

    // Funciones aceptar/cancelar
    vm.affirmativeMessage = null;
    vm.affirmativeFunction = null;
    vm.negativeMessage = null;
    vm.negativeFunction = null;

    $rootScope.$on('openSystemAlertModal', openSystemAlertModal);

    function openSystemAlertModal(e, opt) {
      vm.imageUrl = BASE_IMG_URL;

      switch (opt.type) {
        case 'warning':
          vm.buttonClass = 'closed-question-btn';
          vm.imageUrl += 'warning.svg';
          break;
        case 'info':
          vm.buttonClass = 'neutral-question-btn';
          vm.imageUrl += 'info.svg';
          break;
      }

      vm.title = opt.title;
      vm.subtitle = opt.subtitle;
      vm.details = opt.details;
      vm.affirmativeMessage = opt.affirmativeMessage;

      if (vm.subtitle === 'Sesión inválida en Edenred, vuelva a iniciar sesión') {
        vm.affirmativeFunction = function () {
          window.location.reload();
          return false;
        };
      } else {
        vm.affirmativeFunction =
          opt.affirmativeFunction ||
          function () {
            return false;
          };
      }
      vm.negativeMessage = opt.negativeMessage || null;
      vm.negativeFunction =
        opt.negativeFunction ||
        function () {
          return false;
        };

      $('#system-alert-modal').modal('show');
    }
  }
})();
