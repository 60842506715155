(function () {
  'use strict';

  angular.module('app').factory('FactoryUtil', [
    function () {
      return {
        checkAllPropertyNull: function (obj) {
          var contador = 0;
          for (var key in obj) {
            if (obj[key] && !angular.isUndefined(obj[key])) {
              contador++;
            }
          }
          return contador > 0;
        },
        checkLengthOfArray: function (list, minLength) {
          if (list && list.length) {
            return list.length > minLength;
          } else {
            return false;
          }
        },
        getPreviusDish: function (list, id) {
          var index = -1;
          for (var i = 0; i < list.length; i++) {
            var dish = list[i];
            if (dish.id === id) {
              index = i;
              break;
            }
          }
          var length = list.length - 1;
          if (index === 0) {
            return list[length];
          } else {
            index = index - 1;
            return list[index];
          }
        },
        getNextDish: function (list, id) {
          var index = -1;
          for (var i = 0; i < list.length; i++) {
            var dish = list[i];
            if (dish.id === id) {
              index = i;
              break;
            }
          }
          var length = list.length - 1;
          if (index === length) {
            return list[0];
          } else {
            index = index + 1;
            return list[index];
          }
        },
        getTypePortionKind: function (id) {
          var out = '';
          if (id === 1) {
            out = 'Simple';
          } else if (id === 2) {
            out = 'Doble';
          } else if (id > 2) {
            out = 'Familiar';
          }
          return out;
        },
      };
    },
  ]);
})();
