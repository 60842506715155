(function () {
  'use strict';

  angular.module('services.scroll').service('ScrollService', ScrollService);

  function ScrollService($document, $window, smoothScroll, CatalogService) {
    return {
      scrollToCatalog: scrollToCatalog,
    };

    /**
     * Hacer scroll hasta el catálogo. El efecto será animado sólo si es de arriba hacia abajo
     */
    function scrollToCatalog() {
      const catalogOffset = CatalogService.getCatalogOffset();

      if ($window.pageYOffset > catalogOffset) {
        $window.scrollTo(0, catalogOffset);
      } else {
        const catalog = $document[0].body;
        smoothScroll(catalog, {
          offset: -catalogOffset,
        });
      }
    }
  }
})();
