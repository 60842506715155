(function () {
  'use strict';

  angular.module('services.log').service('LogService', LogService);

  function LogService($injector, config, GlobalService) {
    const $http = $injector.get('$http');

    return {
      info: info,
      serviceError: serviceError,
      unexpectedCase: unexpectedCase,
    };

    /**
     * Obtiene si el usuario está autenticado o es invitado
     * @returns {string}
     */
    function getUser() {
      const user = GlobalService.user;
      return user ? user.id : 'guest';
    }

    function info(action, source, data, response) {
      const user = getUser();

      // Formato: level | user | env | action | source | data | response
      let log = 'level=INFO';
      log += ` | user=${user}`;
      log += ` | env=${config.ENV}`;
      log += ` | action=${action}`;
      log += ` | source=${source}`;
      log += ` | data=${JSON.stringify(data)}`;
      if (response) {
        log += ` | response=${JSON.stringify(response)}`;
      }

      sendLog(log);
    }

    function sendLog(log) {
      //se elimina la funcion que envia el log y se mantienen las demas funciones debido a que varios componentes y servicios lo llaman
      //si se desea eliminar por completo el servicio de logs se debiera realizar una limpieza del codigo
    }

    /**
     * Enviar log del error de un servicio
     * @param response Respuesta del servidor
     */
    function serviceError(response) {
      // Si el LogService fue el que falló, entonces no enviar el log
      if (response.config.url !== config.URL_API_LOG) {
        const user = getUser();

        // Formato: level | user | env | response
        let log = 'level=ERROR';
        log += ` | user=${user}`;
        log += ` | env=${config.ENV}`;
        log += ` | response=${angular.toJson(response)}`;

        sendLog(log);
      }
    }

    /**
     * Enviar un caso inesperado
     * @param source Origen de la excepción
     * @param description Descripción de la excepción
     */
    function unexpectedCase(source, description) {
      const user = getUser();

      // Formato: level | user | env | source | description
      let log = 'level=ERROR';
      log += ` | user=${user}`;
      log += ` | env=${config.ENV}`;
      log += ` | source=${source}`;
      log += ` | description=${description}`;

      sendLog(log);
    }
  }
})();
