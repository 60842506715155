(function () {
  'use strict';

  angular.module('services.billing').service('BillingService', BillingService);

  function BillingService($http, config) {
    let communes = [];

    return {
      getDatosFactura : getDatosFactura,
      addBilling      : addBilling,
      deleteBilling   : deleteBilling,
      editBilling     : editBilling
    };

    function getDatosFactura() {
      return $http.get(`${config.URL_API}/datos_facturacion/usuario`);
    }

    function addBilling(billing) {
      return $http.post(`${config.URL_API}/datos_facturacion/usuario`, angular.toJson(billing));
    }

    function deleteBilling(billing_id) {
      return $http.delete(`${config.URL_API}/datos_facturacion/${billing_id}`);
    }

    function editBilling(billing) {
      return $http.put(`${config.URL_API}/datos_facturacion/${billing.id}`, angular.toJson(billing));
    }
  }
})();
