(function () {
  'use strict';

  angular.module('checkout.guest-user-form').component('checkoutGuestUserForm', {
    controller: CheckoutGuestUserForm,
    templateUrl:
      'pages/checkout/components/checkout-guest-user-form/checkout-guest-user-form.template.html',
    controllerAs: 'vm',
    bindings: {
      phone: '=',
      currentCheckoutStep: '=',
      showGuestUserForm: '=',
      guestUser: '=',
    },
  });

  function CheckoutGuestUserForm(
    $anchorScroll,
    $rootScope,
    $scope,
    FormExceptionHandlerFactory,
    PunycodeFactory,
    AnalyticsService,
    CookiesService,
    UserService,
    GlobalService
  ) {
    const vm = this;

    vm.busy = false;
    vm.busyEmailValidation = false;
    vm.busyRutValidation = false;
    vm.userWithoutRut = false;
    vm.acceptTerms = false;
    vm.showRutReason = false;
    vm.showRegisterUserForm = false;
    vm.registerError = '';
    vm.guestUser = {};

    vm.$onInit = onInit;
    vm.registerUser = registerUser;
    vm.continueWithGuestUser = continueWithGuestUser;
    vm.hideGuestUserForm = hideGuestUserForm;
    vm.openLoginModal = openLoginModal;
    vm.openForgotPasswordModal = openForgotPasswordModal;
    vm.resetEmailUniqueValidator = resetEmailUniqueValidator;
    vm.resetRutUniqueValidator = resetRutUniqueValidator;

    function onInit() {
      vm.busy = true;

      const guestUser = CookiesService.getGuestUser();
      if (guestUser) {
        vm.guestUser = guestUser;
      } else {
        vm.guestUser = {
          name: '',
          lastname: '',
          email: '',
          phone_number: '',
          rut_dv: '',
        };
      }

      if (vm.phone) {
        vm.guestUser.phone_number = vm.phone;
      }
      vm.busy = false;
    }

    function continueWithGuestUser() {
      vm.busy = true;
      CookiesService.setGuestUser(vm.guestUser);
      vm.currentCheckoutStep++;
      vm.busy = false;
    }

    function hideGuestUserForm() {
      vm.showGuestUserForm = false;
      vm.busy = false;
      $anchorScroll();
    }

    /**
     * Mostrar el modal de inicio de sesión
     */
    function openLoginModal() {
      $rootScope.$broadcast('openLoginModal', { source: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de inicio de sesión!');
    }

    /**
     * Mostrar el modal de registro de usuarios
     */
    function openForgotPasswordModal() {
      $rootScope.$broadcast('openForgotPasswordModal', { source: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de recuperar contraseña!');
    }

    /**
     * Registrar usuario invitado
     */
    function registerUser() {
      vm.busy = true;
      vm.registerError = '';

      vm.guestUser.auth_token = CookiesService.getAuthToken();
      vm.guestUser.email = PunycodeFactory.emailToUnicode(vm.guestUser.email);
      UserService.registerGuest(vm.guestUser, document.referrer)
        .then((value) => {
          vm.currentCheckoutStep++;

          // Enviar analíticas
          AnalyticsService.userRegister(GlobalService.user);
          AnalyticsService.login(GlobalService.user);
        })
        .catch((reason) => {
          console.error(reason);
          const formErrors = FormExceptionHandlerFactory.getListErrors(reason.data);

          if (formErrors.email && formErrors.email === 'El email está en uso.') {
            $scope.registerForm.email.$setValidity('unique', false);
          } else if (
            formErrors.email &&
            formErrors.email === 'El formato de email es incorrecto.'
          ) {
            $scope.registerForm.email.$setValidity('email', false);
          } else if (formErrors.rut_dv && formErrors.rut_dv === 'El rut ya está en uso') {
            $scope.registerForm.rut_dv.$setValidity('unique', false);
          } else if (reason.data && reason.data.message) {
            vm.registerError = reason.data.message;
          } else {
            vm.registerError = 'Ocurrió un error en el registro del usuario.';
          }
        })
        .finally(() => {
          vm.busy = false;
        });
    }

    /**
     * Resetear el validador personalizado para el campo email
     */
    function resetEmailUniqueValidator() {
      $scope.guestUserForm.email.$setValidity('unique', true);
    }

    /**
     * Resetear el validador personalizado para el campo rut
     */
    function resetRutUniqueValidator() {
      $scope.guestUserForm.rut_dv.$setValidity('unique', true);
    }
  }
})();
