(function () {
  'use strict';

  angular.module('directives.sticky').directive('sticky', Sticky);

  function Sticky($document, $interval, $rootScope, $timeout, $window) {
    return {
      restrict: 'A',
      scope: {
        offsetDesktop: '<',
        offsetMobile: '<',
        position: '<',
      },
      link: function (scope, element) {
        // Iniciar variables para que el elemento pueda ser sticky
        $timeout(function () {
          element[0].style.left = 0;
          element[0].style.right = 0;
          element[0].style.zIndex = 10;
          element[0].style.position = 'relative';
          scope.elementOffsetTop = element[0].offsetTop;
          updateParentPadding(element, scope);
        });

        // Si el valor offset cambia, hay que volver a calcular el offsetTop del elemento
        scope.$watch('offsetDesktop', function (newValue, oldValue) {
          if (newValue && oldValue) {
            $timeout(function () {
              if (scope.position === 'top') {
                let offsetDifference = newValue - oldValue;
                scope.elementOffsetTop = scope.elementOffsetTop + offsetDifference;
              }
            });
          }
        });

        $rootScope.$on('scrollEvent', function () {
          updateElementPosition(element, scope);
        });

        $rootScope.$on('resizeEvent', function () {
          updateParentPadding(element, scope);
          updateElementPosition(element, scope);
        });

        // Funciones enlazadas al rootScope, para poder actualizar el elemento por algún otro medio que no sea el
        // scroll. Por ejemplo, el cambio de altura de un elemento
        let update;
        $rootScope.$on('updateAlwaysVisible', function () {
          updateParentPadding(element, scope);
          updateElementPosition(element, scope);
        });
        $rootScope.$on('updateAlwaysVisibleInterval', function () {
          if (!angular.isDefined(update)) {
            update = $interval(function () {
              updateElementPosition(element, scope);
            }, 5);
          }
        });
        $rootScope.$on('cancelUpdateAlwaysVisible', function () {
          if (angular.isDefined(update)) {
            $interval.cancel(update);
            update = undefined;
          }
        });
      },
    };

    // Actualizar la posición del elemento, de acuerdo a la posición del scroll
    function updateElementPosition(element, scope) {
      $timeout(function () {
        const offsetDevice = $window.innerWidth < 576 ? scope.offsetMobile : scope.offsetDesktop;

        // Para determinar la posición, es necesario saber si el elemento se va a mantener siempre al top o al bottom
        // Si es el top, será la diferencia del total scrolleado desde el top menos el offset señalado
        // Si es en el bottom, el trigger será la diferencia del alto total de la página menos la altura del footer
        if (scope.position === 'top') {
          if ($window.pageYOffset + offsetDevice >= scope.elementOffsetTop) {
            element[0].style.top = `${offsetDevice}px`;
            element[0].style.position = 'fixed';
            element[0].nextElementSibling.style.paddingTop = `${element[0].offsetHeight}px`;
          } else {
            element[0].nextElementSibling.style.paddingTop = 0;
            element[0].style.position = 'initial';
          }
        } else {
          /**
           * Para el caso del always visible bottom también se podría usar la librería sticky, pero esta no funciona
           * cuando la altura del contenido de la pagina es variable.
           *
           * Un ejemplo de cómo usar "correctamente" la directiva, lo podemos encontrar en el componente checkout-details.
           */
          const pageHeight = $document[0].body.scrollHeight - $window.innerHeight;
          const footerElement = $document[0].querySelector('#footer');
          const footerHeight = footerElement ? footerElement.getBoundingClientRect().height : 0;
          const offset = pageHeight - footerHeight + offsetDevice;
          const sticky = offset > $window.pageYOffset;

          element[0].style.bottom = sticky ? (offsetDevice || 0) + 'px' : 0;
          element[0].style.position = sticky ? 'fixed' : 'absolute';
        }
      });
    }

    function updateParentPadding(element, scope) {
      if (scope.position === 'bottom') {
        $timeout(function () {
          element[0].parentNode.style.paddingBottom = `${element[0].offsetHeight + 20}px`;
        });
      }
    }
  }
})();
