(function () {
  'use strict';

  angular.module('pages.userProfile').controller('UserProfileController', UserProfileController);

  function UserProfileController($scope, GlobalService, NavigationService) {
    const vm = this;

    $scope.global = GlobalService;

    vm.user = null;
    vm.internalUrls = NavigationService.getInternalUrls();

    vm.$onInit = onInit;

    function onInit() {
      $scope.$watch('global.user', function (newVal) {
        if (newVal) {
          vm.user = newVal;
        }
      });
    }
  }
})();
