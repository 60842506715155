(function () {
  'use strict';

  angular.module('pages.userProfile.userData').controller('UserDataController', UserDataController);

  function UserDataController($anchorScroll, $scope, $rootScope, AnalyticsService, GlobalService) {
    var vm = this;

    $scope.global = GlobalService;

    vm.user = null;

    vm.$onInit = onInit;
    vm.openChangePasswordModal = openChangePasswordModal;
    vm.openEditUserModal = openEditUserModal;
    $scope.$on('$destroy', onDestroy);
    $scope.$on('passwordEdited', passwordEdited);
    $scope.$on('userEdited', userEdited);

    var unregisterUser;

    function onInit() {
      $anchorScroll();

      unregisterUser = $scope.$watch('global.user', function (newVal) {
        if (newVal) {
          vm.user = newVal;
        }
      });

      AnalyticsService.profileView('/perfil');
    }

    function onDestroy() {
      unregisterUser();
    }

    function openChangePasswordModal() {
      $scope.$broadcast('openChangePasswordModal');
    }

    function openEditUserModal() {
      $scope.$broadcast('openEditUserModal');
    }

    function passwordEdited() {
      vm.message = 'Tu contraseña ha sido guardada correctamente';
      vm.messageType = 'success';
    }

    function userEdited() {
      vm.message = 'Tus datos han sido modificados correctamente';
      vm.messageType = 'success';
    }
  }
})();
