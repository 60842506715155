(function () {
  'use strict';

  angular.module('pages.userProfile.userData.editUserModal').component('editUserModalComponent', {
    templateUrl:
      'pages/user-profile/pages/user-data/components/edit-user-modal/edit-user-modal.template.html',
    controller: EditUserModalController,
    controllerAs: 'vm',
  });

  function EditUserModalController($scope, AnalyticsService, GlobalService, UserService) {
    var vm = this;

    vm.busy = false;

    // Variable para dejar de escuchar los cambios en el rootScope
    var unregisterOpenEditUserModal;

    // Input del usuario
    vm.name = '';
    vm.lastname = '';
    vm.phone_number = '';

    // Variables enlazadas a la plantilla
    vm.editUserError = false;

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.closeEditUserModal = closeEditUserModal;
    vm.editUser = editUser;

    function onInit() {
      unregisterOpenEditUserModal = $scope.$on('openEditUserModal', function () {
        var user = GlobalService.user;

        vm.name = user.name;
        vm.lastname = user.lastname;
        vm.phone_number = user.phone_number;
        vm.editUserError = false;
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
        $('#edit-user-modal-component').modal('show');
        AnalyticsService.initEditProfile();
      });
    }

    function closeEditUserModal() {
      $('#edit-user-modal-component').modal('hide');
    }

    function editUser() {
      vm.busy = true;
      vm.editUserError = false;

      var user = {
        email: GlobalService.user.email,
        name: vm.name,
        lastname: vm.lastname,
        phone_number: vm.phone_number,
      };

      UserService.updateUserData(user).then(
        function (value) {
          var user = value.data;

          vm.busy = false;

          GlobalService.user.name = user.name;
          GlobalService.user.lastname = user.lastname;
          GlobalService.user.email = user.email;
          GlobalService.user.phone_number = user.phone_number;

          vm.closeEditUserModal();

          $scope.$emit('userEdited');
          AnalyticsService.editProfile();
        },
        function () {
          vm.busy = false;
          vm.editUserError = true;
        }
      );
    }

    $scope.$on('$destroy', function () {
      unregisterOpenEditUserModal();
    });
  }
})();
