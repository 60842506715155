/* jslint bitwise: true */
(function () {
  'use strict';

  angular.module('factories.punycode').factory('PunycodeFactory', PunycodeFactory);

  function PunycodeFactory() {
    // Parámetros por defecto
    var initial_n = 0x80;
    var initial_bias = 72;
    var delimiter = '\x2D';
    var base = 36;
    var damp = 700;
    var tmin = 1;
    var tmax = 26;
    var skew = 38;
    var maxint = 0x7fffffff;

    return {
      emailToUnicode: emailToUnicode,
    };

    /**
     * Google Chrome tiene a mala costumbre de convertir a ASCII el valor del campo email.
     * La siguiente función evita que ésto suceda.
     * @see https://stackoverflow.com/questions/37166639/prevent-angular-from-mangling-email-inputs/37205099#37205099
     * @param {string} email Correo a corregir, si es que contiene caracteres especiales permitidos
     */
    function emailToUnicode(email) {
      var emailArray = email.split('@');

      if (emailArray.length === 2) {
        var username = emailArray[0];
        var domain = emailArray[1];

        if (username && domain) {
          var out = [];
          var domainArray = domain.split('.');
          var domainArrayLength = domainArray.length;

          for (var x = 0; x < domainArrayLength; x++) {
            var s = domainArray[x];
            out.push(s.match(/^xn--/) ? decode(s.slice(4)) : s);
          }

          return username + '@' + out.join('.');
        }
      }

      return email;
    }

    /**
     * Función principal para decodificar
     * @see https://stackoverflow.com/questions/183485/converting-punycode-with-dash-character-to-unicode/301287#301287
     * @param {string} input String a decodificar
     */
    function decode(input) {
      var output = [];
      var input_length = input.length;

      var n, out, i, bias, basic, j, ic, oldi, w, k, digit, t;

      n = initial_n;
      i = 0;
      bias = initial_bias;

      // Handle the basic code points: Let basic be the number of input code
      // points before the last delimiter, or 0 if there is none, then
      // copy the first basic code points to the output.

      basic = input.lastIndexOf(delimiter);
      if (basic < 0) {
        basic = 0;
      }

      for (j = 0; j < basic; ++j) {
        if (input.charCodeAt(j) >= 0x80) {
          throw new RangeError('Illegal input >= 0x80');
        }
        output.push(input.charCodeAt(j));
      }

      // Main decoding loop: Start just after the last delimiter if any
      // basic code points were copied; start at the beginning otherwise.

      for (ic = basic > 0 ? basic + 1 : 0; ic < input_length; ) {
        // ic is the index of the next character to be consumed,

        // Decode a generalized variable-length integer into delta,
        // which gets added to i. The overflow checking is easier
        // if we increase i as we go, then subtract off its starting
        // value at the end to obtain delta.
        for (oldi = i, w = 1, k = base; ; k += base) {
          if (ic >= input_length) {
            throw RangeError('punycode_bad_input(1)');
          }
          digit = decode_digit(input.charCodeAt(ic++));

          if (digit >= base) {
            throw RangeError('punycode_bad_input(2)');
          }
          if (digit > Math.floor((maxint - i) / w)) {
            throw RangeError('punycode_overflow(1)');
          }
          i += digit * w;
          t = k <= bias ? tmin : k >= bias + tmax ? tmax : k - bias;
          if (digit < t) {
            break;
          }
          if (w > Math.floor(maxint / (base - t))) {
            throw RangeError('punycode_overflow(2)');
          }
          w *= base - t;
        }

        out = output.length + 1;
        bias = adapt(i - oldi, out, oldi === 0);

        // i was supposed to wrap around from out to 0,
        // incrementing n each time, so we'll fix that now:
        if (Math.floor(i / out) > maxint - n) {
          throw RangeError('punycode_overflow(3)');
        }
        n += Math.floor(i / out);
        i %= out;

        // Insert n at position i of the output:

        output.splice(i, 0, n);
        i++;
      }

      return utf16Encode(output);
    }

    /**
     * Decodificar de un dígito
     * @see https://stackoverflow.com/questions/183485/converting-punycode-with-dash-character-to-unicode/301287#301287
     * @param {number} cp Code point
     * @returns {number} Valor numérico de un code point en el rango desde 0 a base-1, o base si el cp no representa un valor
     */
    function decode_digit(cp) {
      return cp - 48 < 10 ? cp - 22 : cp - 65 < 26 ? cp - 65 : cp - 97 < 26 ? cp - 97 : base;
    }

    function adapt(delta, numpoints, firsttime) {
      var k;
      delta = firsttime ? Math.floor(delta / damp) : delta >> 1;
      delta += Math.floor(delta / numpoints);

      for (k = 0; delta > ((base - tmin) * tmax) >> 1; k += base) {
        delta = Math.floor(delta / (base - tmin));
      }
      return Math.floor(k + ((base - tmin + 1) * delta) / (delta + skew));
    }

    /**
     * Necesario para convertir desde la representación de  caracteres internos de javascripts a unicode.
     * @see https://stackoverflow.com/questions/183485/converting-punycode-with-dash-character-to-unicode/301287#301287
     * @param {string} input String a codificar
     */
    function utf16Encode(input) {
      var output = [],
        i = 0,
        len = input.length,
        value;
      while (i < len) {
        value = input[i++];
        if ((value & 0xf800) === 0xd800) {
          throw new RangeError('UTF-16(encode): Illegal UTF-16 value');
        }
        if (value > 0xffff) {
          value -= 0x10000;
          output.push(String.fromCharCode(((value >>> 10) & 0x3ff) | 0xd800));
          value = 0xdc00 | (value & 0x3ff);
        }
        output.push(String.fromCharCode(value));
      }
      return output.join('');
    }
  }
})();
