(function () {
  'use strict';

  angular
    .module('pages.userProfile.creditCard')
    .controller('CreditCardController', CreditCardController);

  function CreditCardController(
    $anchorScroll,
    $document,
    $rootScope,
    $sce,
    $scope,
    $state,
    $stateParams,
    $timeout,
    AlertService,
    AnalyticsService,
    GlobalService,
    LogService,
    PaymentService,
    UserService
  ) {
    var vm = this;

    vm.busy = false;
    vm.messageType = '';
    vm.message = '';

    var unregisterWatchGroup = function () {
      return undefined;
    };
    var unregisterCreditCards = function () {
      return undefined;
    };

    vm.$onInit = onInit;
    vm.addCreditCard = addCreditCard;
    vm.openDeleteCreditCardModal = openDeleteCreditCardModal;

    $scope.$on('$destroy', onDestroy);
    $scope.deleteCreditCard = deleteCreditCard;

    function onInit() {
      $anchorScroll();

      vm.creditCards = GlobalService.creditCards;

      if ($stateParams.status && $stateParams.message) {
        vm.message = $stateParams.message;

        if ($stateParams.status === 'true') {
          vm.messageType = 'success';
          AnalyticsService.addPaymentMethod();
        } else if ($stateParams.status === 'false') {
          vm.messageType = 'warning';
        } else {
          LogService.unexpectedCase(
            vm.constructor.name,
            'Volvió de Transbank con un status que no está definido: ' + $stateParams.status
          );
        }

        LogService.info('Volver de Transbank', vm.constructor.name, {
          cart: GlobalService.cart,
          configDelivery: GlobalService.configDelivery,
          status: $stateParams.status,
          message: $stateParams.message,
        });
      }

      if (!vm.creditCards || !vm.creditCards.length) {
        unregisterCreditCards = $scope.$watch('global.creditCards', function (newVal) {
          if (newVal) {
            vm.creditCards = newVal;
            unregisterCreditCards();
          }
        });
      }

      AnalyticsService.profileView('/pagos');
    }

    function onDestroy() {
      unregisterCreditCards();
      unregisterWatchGroup();
    }

    function addCreditCard() {
      vm.busy = true;

      var user = GlobalService.user;
      var request = {
        full_name: user.name + ' ' + user.lastname,
        email: user.email,
        tipo_forma_pago: '1',
        card_name: '---',
        freezer: !!$stateParams.freezer,
        checkout: false,
      };

      PaymentService.addCardPayment(request).then(
        function (value) {
          // Intentar obtener el token de transbank
          $scope.formTbkHtml = $sce.trustAsHtml(
            '<form id="formulario" action="' +
              value.data.tbk_url +
              '" method="POST">' +
              '<input type="hidden" name="TBK_TOKEN" value="' +
              value.data.tbk_token +
              '">' +
              '</form>'
          );

          $scope.$$postDigest(function () {
            // Intentar redireccionar la página
            LogService.info('Ir a Transbank', 'credit-card.controller.js', {
              cart: GlobalService.cart,
              configDelivery: GlobalService.configDelivery,
            });
            $document[0].forms.formulario.submit();
          });
        },
        function () {
          vm.messageType = 'warning';
          vm.message =
            'Transbank presenta inestabilidad en su servicio y no nos deja asociar tu tarjeta. Por favor espera unos minutos e intenta nuevamente.';
          vm.busy = false;
        }
      );
    }

    function deleteCreditCard(cardId) {
      vm.busy = true;

      PaymentService.deletePayment(cardId).then(
        function () {
          vm.messageType = 'success';
          vm.message = 'Tu medio de pago ha sido eliminado.';

          GlobalService.creditCards = GlobalService.creditCards.filter(function (el) {
            return el.id !== cardId;
          });

          vm.creditCards = GlobalService.creditCards;

          // Actualizar el método de pago en las preferencias del usuario
          if (
            GlobalService.configDelivery.paymentMethod &&
            GlobalService.configDelivery.paymentMethod.id === cardId
          ) {
            GlobalService.configDelivery.paymentMethod = null;
            UserService.setUserConfig(GlobalService.configDelivery);
          }

          vm.busy = false;
        },
        function (reason) {
          vm.messageType = 'warning';
          vm.message =
            'Ocurrió un error al intentar eliminar el medio de pago. ' + reason.data.message;
          vm.busy = false;
        }
      );
    }

    function openDeleteCreditCardModal(cardId) {
      AlertService.deletePaymentMethod(function () {
        $scope.deleteCreditCard(cardId);
      });
    }
  }
})();
