(function () {
  'use strict';

  angular.module('directives.equalityFormValidation').directive('equality', EqualityFormValidation);

  function EqualityFormValidation() {
    return {
      require: 'ngModel',
      scope: {
        equality: '=equality',
      },
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.equality = function (modelValue, viewValue) {
          // No validar si el texto viene vacío
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }

          return scope.equality === viewValue;
        };

        scope.$watch('equality', function () {
          ctrl.$validate();
        });
      },
    };
  }
})();
