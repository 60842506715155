(function () {
  'use strict';

  angular.module('services.address').service('AddressService', AddressService);

  function AddressService($http, config) {
    let communes = [];

    return {
      addAddress: addAddress,
      defaultAddress: defaultAddress,
      deleteAddress: deleteAddress,
      getAddress: getAddress,
      getAddressById: getAddressById,
      getAddressWithoutUser: getAddressWithoutUser,
      getCommunes: getCommunes,
      getGeoRef: getGeoRef,
      getGeoReferenceAddress: getGeoReferenceAddress,
      modifyAddress: modifyAddress,
      validateAddress: validateAddress,
    };

    function addAddress(address) {
      return $http.post(`${config.URL_API}/addresses`, angular.toJson(address));
    }

    function defaultAddress(id) {
      return $http.patch(`${config.URL_API}/addresses/${id}/default`);
    }

    function deleteAddress(id) {
      return $http.delete(`${config.URL_API}/addresses/${id}`);
    }

    function getAddress() {
      return $http.get(`${config.URL_API}/addresses`);
    }

    function getAddressById(id) {
      return $http.get(`${config.URL_API}/addresses/${id}`);
    }

    function getAddressWithoutUser(id) {
      return $http.get(`${config.URL_API}/addresses/${id}/without-user`);
    }

    function getCommunes() {
      if (!communes.length) {
        communes = $http.get(`${config.URL_API}/communes`).then((value) => {
          // console.info('Comunas: ', value.data.communes);
          return value.data.communes;
        });
      }

      return communes;
    }

    function getGeoRef(address) {
      return $http.get('http://maps.google.com/maps/api/geocode/json', {
        params: { address: address, sensor: false },
      });
    }

    function getGeoReferenceAddress(address) {
      return $http.get(`${config.URL_API}/addresses/google_geo`, {
        params: { direccion: address },
      });
    }

    function modifyAddress(address) {
      return $http.patch(`${config.URL_API}/addresses/${address.id}`, angular.toJson(address));
    }

    function validateAddress(address) {
      return $http.post(`${config.URL_API}/util/POST/validateDirection`, address);
    }
  }
})();
