(function () {
  'use strict';

  angular.module('shared.ratingModal.commentsModal').component('commentsModalComponent', {
    templateUrl: 'shared/rating-modal/components/comments-modal/comments-modal.template.html',
    controller: CommentsModalController,
    controllerAs: 'vm',
    bindings: {
      order: '<',
      orderComments: '=',
      productComments: '=',
      isDigital: '<',
    },
  });

  function CommentsModalController($scope, $stateParams, RatingService) {
    // Constantes
    let COMMENT;
    let COMMENT_DISH;

    // Componente
    var vm = this;

    vm.type = '';

    // Input del usuario
    vm.comments = '';

    // Funciones enlazadas con la plantilla
    vm.closeCommentsModal = closeCommentsModal;
    vm.saveComments = saveComments;

    // Funciones enlazadas al controlador
    $scope.openCommentsModal = openCommentsModal;

    $scope.$on('openCommentsModal', $scope.openCommentsModal);

    /**
     * Abrir modal de comentarios
     * @param event
     * @param opt
     */
    function openCommentsModal(event, opt) {
      vm.type = opt.type;
      vm.comments = '';

      COMMENT = vm.isDigital ? 'COMMENT' : 'COMENTARIO_PEDIDO';
      COMMENT_DISH = vm.isDigital ? 'COMMENT_DISH' : 'COMENTARIO_PRODUCTO';

      $('#comments-modal').modal('show');
    }

    /**
     * Guardar comentarios
     */
    function saveComments() {
      if (vm.type === COMMENT) {
        vm.orderComments = vm.comments;
      } else if (vm.type === COMMENT_DISH) {
        vm.productComments = vm.comments;
      }

      const source = $stateParams.source || vm.isDigital ? 'WEB' : 'EMAIL';

      RatingService.setRating(source, vm.isDigital, vm.type, vm.comments, vm.order.id);
      vm.closeCommentsModal();
    }

    /**
     * Cerrar modal de comentarios
     */
    function closeCommentsModal() {
      $('#comments-modal').modal('hide');
    }
  }
})();
