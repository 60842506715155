(function () {
  'use strict';

  angular.module('services.user').service('UserService', UserService);

  function UserService(
    $http,
    $q,
    $rootScope,
    config,
    CartService,
    GlobalService,
    CookiesService,
    ZendeskWidget
  ) {
    return {
      addCode: addCode,
      changePassword: changePassword,
      changePasswordPefil: changePasswordPerfil,
      checkRecoverPassword: checkRecoverPassword,
      getCurrentUserData: getCurrentUserData,
      getUser: getUser,
      getUserConfig: getUserConfig,
      getUserDiscounts: getUserDiscounts,
      getUserFBLogin: getUserFBLogin,
      getUserGuestToken: getUserGuestToken,
      getUserLogin: getUserLogin,
      logout: logout,
      recoverPassword: recoverPassword,
      registerGuest: registerGuest,
      setUserConfig: setUserConfig,
      updateUserData: updateUserData,
      validateEmail: validateEmail,
      validateRut: validateRut,
      activateNewUserAccount: activateNewUserAccount,
    };

    /**
     * Agregar un código de promoción
     * @param code {string} Código
     * @param thisPurchase {bool} ¿El código será aplicado para ésta compra o la próxima compra?
     * @returns {*}
     */
    function addCode(code, thisPurchase) {
      return $http.post(`${config.URL_API}/users/code?thisPurchase=${thisPurchase}`, {
        code: code,
      });
    }

    function changePassword(id, email, hash, objForm) {
      return $http.patch(`${config.URL_API}/users/change_pass/${id}/${email}/${hash}`, objForm);
    }

    function changePasswordPerfil(objPerfil) {
      return $http.patch(`${config.URL_API}/users/change_pass`, objPerfil);
    }

    function checkRecoverPassword(id, email, hash) {
      return $http.post(`${config.URL_API}/users/recover_validate/${id}/${email}/${hash}`);
    }

    function getCurrentUserData() {
      return $http.get(`${config.URL_API}/users/current`);
    }

    function getUser() {
      const defer = $q.defer();
      const authToken = CookiesService.getAuthToken();
      $rootScope.userToken = false;

      // Si el usuario ha iniciado sesión...
      if (authToken) {
        $http.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        $rootScope.userToken = true;

        $http
          .get(`${config.URL_API}/users/current`)
          .then((value) => {
            // En ocasiones, la API indica que hay un usuario autenticado y devuelve un usuario
            // nulo, creando una serie de inconsistencias en el sistema.
            if (!value.data.full_name) {
              getUserGuestToken()
                .catch((reason) => {
                  console.log(reason);
                })
                .finally(() => {
                  defer.resolve({ data: null });
                });
            } else if (!value.data.email) {
              defer.resolve({ data: null });
            } else {
              defer.resolve(value);
            }
          })
          .catch((reason) => {
            console.error(reason);
            getUserGuestToken()
              .catch((reason) => {
                console.log(reason);
              })
              .finally(() => {
                defer.resolve({ data: null });
              });
          });
      } else {
        // Si el usuario no tiene token de nada
        getUserGuestToken()
          .catch((reason) => {
            console.log(reason);
          })
          .finally(() => {
            defer.resolve({ data: null });
          });
      }

      return defer.promise;
    }

    /**
     * Obtener configuración del usuario
     * @see https://gitlab.com/forkTI/buttler/wikis/Servicio/PUT
     * @returns {*}
     */
    function getUserConfig() {
      return $http.get(`${config.URL_API}/user/configuration`);
    }

    /**
     * Obtener el listado de descuentos del usuario
     * @returns {*}
     */
    function getUserDiscounts() {
      return $http.get(`${config.URL_API}/paperbag/discount/user`);
    }

    function getUserFBLogin(objLoginFacebookForm) {
      return $http.post(`${config.URL_API}/users/login_facebook`, objLoginFacebookForm);
    }

    function getUserGuestToken() {
      return $http.post(`${config.URL_API}/users/guest`).then((value) => {
        CookiesService.setUser(value.data.auth_token);
        $rootScope.userToken = true;
      });
    }

    function getUserLogin(objLoginForm) {
      return $http.post(`${config.URL_API}/users/login`, objLoginForm);
    }

    function logout() {
      return $http.delete(`${config.URL_API}/users/logout`);
    }

    function recoverPassword(objFormRecover) {
      return $http.post(`${config.URL_API}/users/recover`, angular.toJson(objFormRecover));
    }

    /**
     * Registra un usuario anónimo
     * @param formRegister Formulario de registro
     * @param referral Ruta del referido
     * @returns {*}
     */
    function registerGuest(formRegister, referral) {
      let defer = $q.defer();
      formRegister.rut_dv = formRegister.rut_dv.toLowerCase();

      $http
        .post(`${config.URL_API}/users/register?referral=${referral}`, formRegister)
        .then((value) => {
          defer.resolve(value);
        })
        .catch((reason) => {
          console.error(reason);
          defer.reject(reason);
        });

      return defer.promise;
    }

    /**
     * Establecer la configuración de entrega
     * @see https://gitlab.com/forkTI/buttler/wikis/Servicio/PUT
     * @param userConfig
     * @returns {*}
     */
    function setUserConfig(userConfig) {
      console.log(userConfig);
      return $http.put(`${config.URL_API}/user/configuration`, {
        delivery: userConfig.kind === 0 ? 'DELIVER' : 'TO_GO',
        store_id: userConfig.store ? userConfig.store.id : null,
        address_id: userConfig.address ? userConfig.address.id : null,
        payment_method_id: userConfig.paymentMethod ? userConfig.paymentMethod.id : null,
      });
    }

    function updateUserData(objUser) {
      return $http.post(`${config.URL_API}/users/current`, angular.toJson(objUser));
    }

    /**
     * Validar email en el servidor
     * @param email
     * @returns {*}
     */
    function validateEmail(email) {
      return $http.get(`${config.URL_API}/users/validate-email/${email}`);
    }

    /**
     * Validar rut en el servidor
     * @param rut
     * @returns {*}
     */
    function validateRut(rut) {
      return $http.get(`${config.URL_API}/users/validate-rut/${rut}`);
    }

    // Activar cuenta nuevo usuario
    function activateNewUserAccount(hash) {
      return $http.post(`${config.URL_API}/users/activacion/${hash}`);
    }
  }
})();
