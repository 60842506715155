(function () {
  'use strict';

  angular.module('services.rating').service('RatingService', RatingService);

  function RatingService($http, config) {
    return {
      getDefaultScores: getDefaultScores,
      getTags: getTags,
      setRating: setRating,
    };

    /**
     * Obtener scores, la escala usada en la primera pregunta donde se muestra una secuencia de valores
     * numéricos que puede ir desde X a Y, donde X y Y pertenecen al conjunto de números naturales.
     * @see https://gitlab.com/forkTI/fork-api/wikis/NPS#obtener-scores
     */
    function getDefaultScores() {
      return $http.get(`${config.URL_API}/ratings/scores`);
    }

    /**
     * Obtener etiquetas.
     * Retornará todas las etiquetas relacionadas a la nota seleccionada en una pregunta anterior.
     * @see https://gitlab.com/forkTI/fork-api/wikis/NPS#ratingsscoresntags
     * @param {number} score Calificación del usuario
     */
    function getTags(score) {
      return $http.get(`${config.URL_API}/ratings/scores/${score}/tags`);
    }

    /**
     * Enviar evaluación
     * @see https://gitlab.com/forkTI/fork-api/wikis/NPS#enviar-evaluaci%C3%B3n
     * @param {string} origen Puede ser EMAIL o WEB
     * @param {boolean} es_digital ¿El pedido es digital?
     * @param {string} type Puede ser SCORE, TAG, COMMENT...
     * @param {string} value Valor de la calificación, según el tipo seleccionado
     * @param {number} orderId Pedido a evaluar
     * @param {string} sku (Opcional) Producto a evaluar
     * @param {string} preguntaId (Opcional) Pregunta a evaluar
     */
    function setRating(source, isDigital, type, value, orderId, sku, preguntaId) {
      return $http.put(`${config.URL_API}/ratings/submit`, {
        origen: source || 'WEB',
        es_digital: isDigital,
        type: type,
        value: value,
        order_id: orderId,
        sku: sku || null,
        pregunta_id: preguntaId || null,
      });
    }
  }
})();
