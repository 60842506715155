(function () {
  'use strict';

  angular.module('services.animate').service('AnimateService', AnimateService);

  function AnimateService($document) {
    return {
      animate: animate,
    };

    function animate(element, animationName, callback) {
      const node = $document[0].querySelector(element);

      function handleAnimationEnd() {
        node.classList.remove('animated', animationName);
        node.removeEventListener('animationend', handleAnimationEnd);

        if (typeof callback === 'function') {
          callback();
        }
      }

      if (node) {
        node.classList.add('animated', animationName);
        node.addEventListener('animationend', handleAnimationEnd);
      } else {
        console.warn('No se consiguió el elemento para animar');
      }
    }
  }
})();
