(function () {
  'use strict';

  angular.module('app').factory('FactoryError', [
    function () {
      return {
        geLocalErrors: function (err) {
          var errors = {};
          if (err && err.length) {
            for (var index = 0; index < err.length; index++) {
              var field = index;
              errors[field] = err[index];
            }
          }
          return errors;
        },
        getListErrors: function (err) {
          var errors = {};

          if (angular.isDefined(err.list_error)) {
            var listError = err.list_error;
            if (listError && listError.length) {
              angular.forEach(listError, function (value) {
                if (!listError.hasOwnProperty(value.field)) {
                  var field = value.field;
                  var message = value.message;
                  errors[field] = message;
                }
              });
            } else {
              errors = {
                message: err.message,
              };
            }
          }

          return errors;
        },
        getErrorsPasswordPerfil: function (password, passwordNew, passwordConfirm) {
          if (
            password !== null &&
            password !== '' &&
            passwordNew !== null &&
            passwordNew !== '' &&
            passwordConfirm !== null &&
            passwordConfirm !== ''
          ) {
            if (passwordNew !== passwordConfirm) {
              return {
                list_error: [
                  {
                    field: 'contraseniaNueva2',
                    message: 'Contraseña no coincide',
                  },
                ],
              };
            }
          } else {
            if (
              !(password !== null && password !== '') &&
              !(passwordNew !== null && passwordNew !== '') &&
              !(passwordConfirm !== null && passwordConfirm !== '')
            ) {
              return {
                list_error: [
                  {
                    field: 'contraseniaVieja',
                    message: 'Contraseña actual es requerida',
                  },
                  {
                    field: 'contraseniaNueva',
                    message: 'Contraseña nueva es requerida',
                  },
                  {
                    field: 'contraseniaNueva2',
                    message: 'Contraseña de confirmación es requerida',
                  },
                ],
              };
            } else {
              if (
                !(password !== null && password !== '') ||
                !(passwordNew !== null && passwordNew !== '') ||
                !(passwordConfirm !== null && passwordConfirm !== '')
              ) {
                var error = { list_error: [] };
                if (!(password !== null && password !== '')) {
                  error.list_error.push({
                    field: 'contraseniaVieja',
                    message: 'Contraseña actual es requerida',
                  });
                }

                if (!(passwordNew !== null && passwordNew !== '')) {
                  error.list_error.push({
                    field: 'contraseniaNueva',
                    message: 'Contraseña nueva es requerida',
                  });
                }

                if (!(passwordConfirm !== null && passwordConfirm !== '')) {
                  error.list_error.push({
                    field: 'contraseniaNueva2',
                    message: 'Contraseña de confirmación es requerida',
                  });
                }

                return error;
              }
            }
          }
        },
        getErrorsPassword: function (password, contraseniaNueva2) {
          var error = null;

          if (
            password !== null &&
            password !== '' &&
            contraseniaNueva2 !== null &&
            contraseniaNueva2 !== ''
          ) {
            if (password !== contraseniaNueva2) {
              return {
                list_error: [
                  {
                    field: 'password_confirm',
                    message: 'Contraseña no coincide',
                  },
                ],
              };
            } else {
              return error;
            }
          } else {
            if (
              !(password !== null && password !== '') &&
              !(contraseniaNueva2 !== null && contraseniaNueva2 !== '')
            ) {
              return {
                list_error: [
                  {
                    field: 'contrasenia',
                    message: 'Contraseña es requerida',
                  },
                  {
                    field: 'password_confirm',
                    message: 'Contraseña de confirmación es requerida',
                  },
                ],
              };
            } else {
              if (
                !(password !== null && password !== '') ||
                !(contraseniaNueva2 !== null && contraseniaNueva2 !== '')
              ) {
                if (!(password !== null && password !== '')) {
                  return {
                    list_error: [
                      {
                        field: 'contrasenia',
                        message: 'Contraseña es requerida',
                      },
                    ],
                  };
                }
                if (!(contraseniaNueva2 !== null && contraseniaNueva2 !== '')) {
                  return {
                    list_error: [
                      {
                        field: 'password_confirm',
                        message: 'Contraseña de confirmación es requerida',
                      },
                    ],
                  };
                }
              }
            }
          }
        },
      };
    },
  ]);
})();
