(function () {
  'use strict';

  angular.module('services.cart').service('CartService', CartService);

  function CartService(
    $http,
    $q,
    $rootScope,
    config,
    AlertService,
    AnalyticsService,
    CatalogService,
    GlobalService
  ) {
    return {
      addCode: addCode,
      addToCart: addToCart,
      adjustCart: adjustCart,
      checkCart: checkCart,
      deleteCart: deleteCart,
      deleteFromCart: deleteFromCart,
      getCart: getCart,
      removeFromCart: removeFromCart,
      setCart: setCart,
    };

    function addCode(code) {
      const storeId = GlobalService.configDelivery.store.id;

      return $http.post(
        `${config.URL_API}/coordinador/carro/descuento/true`,
        {
          codigoDescuento: code,
          idSucursal: storeId,
        },
        {
          headers: {
            'coordinador-api-key': config.COORDINADOR_API_KEY,
          },
        }
      );
    }

    /**
     * Agregar producto al carro
     * @param {object} item
     * @param {object[]} itemsPack
     * @param {string} source - Origen desde donde se está agregando el producto (Catálogo, Detalles de producto, etc)
     * @returns {*}
     */
    function addToCart(item, itemsPack, source) {
      const defer = $q.defer();
      const cart = angular.copy(GlobalService.cart);
      const sameItemIndex = getExistingItemIndex(item, itemsPack, cart);

      // Sumamos uno a la cantidad del producto o agregamos el producto al carro,
      // dependiendo del resultado de las validaciones
      if (sameItemIndex === -1) {
        cart.productos.push({
          sku: item.sku,
          productosPack: itemsPack,
          cantidad: 1,
        });
      } else {
        cart.productos[sameItemIndex].cantidad++;
      }

      setCart(cart, null)
        .then((value) => {
          const newCart = value.data.response;
          const dropItems = newCart.productosNoEncontrados;

          if (dropItems.length) {
            // Actualizar catálogo de productos
            CatalogService.updateCatalog();

            // Mostrar alerta del sistema
            AlertService.notEnoughProducts(dropItems);
          } else {
            // Si el usuario tiene la configuración de delivery por defecto, mostrar la ventana
            if (GlobalService.userConfig.default) {
              $rootScope.$broadcast('openConfigDeliveryModal', { state: 'first-add-to-cart' });
              console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
            }
          }

          // Enviar analíticas, si es que el producto está en el carro
          const itemInCart = newCart.productos.find((product) => product.sku === item.sku);

          if (itemInCart) {
            AnalyticsService.addToCart(source, item, itemInCart.quantity);
          }

          defer.resolve(newCart);
        })
        .catch((reason) => {
          console.error(reason);
          defer.reject(reason.data);
        });

      return defer.promise;
    }

    /**
     * Ajustar carro
     * @param currentCart Carro actual
     * @param dropItems Productos que se quitarán del carro
     * @param configDelivery Configuración de entrega
     * @returns {*}
     */
    function adjustCart(currentCart, dropItems, configDelivery) {
      const defer = $q.defer();
      let sameItemIndex;

      // Recorrer los productos que se eliminarán del carro.
      dropItems.forEach((dropItem) => {
        // Obtener el índice del producto a eliminar en el carro.
        sameItemIndex = getExistingItemIndex(dropItem, dropItem.productosPack, currentCart);

        // Validar que el producto a eliminar exista dentro del carro.
        if (sameItemIndex !== -1) {
          currentCart.productos[sameItemIndex].cantidad -= dropItem.cantidad;

          // Si el producto llega a la cantidad 0, eliminarlo del arreglo.
          if (currentCart.productos[sameItemIndex].cantidad <= 0) {
            currentCart.productos.splice(sameItemIndex, 1);
          }
        }
      });

      setCart(currentCart, configDelivery)
        .then((value) => {
          defer.resolve(value);
        })
        .catch((reason) => {
          defer.reject(reason);
        });

      return defer.promise;
    }

    /**
     * Verificar que los productos en el carro de compras estén disponibles los productos
     * en la tienda seleccionada
     * @param {object} store Tienda en la cual se verificará el carro
     */
    function checkCart(store) {
      const defer = $q.defer();

      getCart(store.id)
        .then((value) => {
          defer.resolve(value);
        })
        .catch((reason) => {
          defer.reject(reason);
        });

      return defer.promise;
    }

    /**
     * Quitar un producto del carro
     * @param {object} defer Objeto que viene desde la función removeFromCart
     * @param {object} product
     * @returns {*}
     */
    function deleteFromCart(defer, product) {
      const cart = angular.copy(GlobalService.cart);

      const sameItemIndex = getExistingItemIndex(product, product.productosPack, cart);
      if (sameItemIndex !== -1) {
        cart.productos.splice(sameItemIndex, 1);

        setCart(cart)
          .then((value) => {
            defer.resolve(value);
          })
          .catch((reason) => {
            defer.reject(reason);
          });
      }
    }

    /**
     * Eliminar carro de compras del usuario, según la tienda
     */
    function deleteCart() {
      return $http.delete(`${config.URL_API}/paperbag/carro`);
    }

    /**
     * Formatea los productos del pack, para que su forma tenga la que espera el servidor
     * @param {object[]} itemsPack Productos del pack
     */
    function formatItemsPack(itemsPack) {
      let formattedItemsPack = [];
      let index;

      itemsPack.forEach((itemPack) => {
        index = formattedItemsPack.findIndex((item) => item.sku === itemPack.sku);

        // Agregar o sumar el producto, si es que se encuentra en el arreglo de respuesta o no
        if (index === -1) {
          formattedItemsPack.push({
            sku: itemPack.sku,
            cantidad: itemPack.inCart || itemPack.cantidad || 1,
          });
        } else {
          formattedItemsPack[index].cantidad += itemPack.inCart || itemPack.cantidad || 1;
        }
      });

      return formattedItemsPack;
    }

    /**
     * Obtener el carro de compras del usuario, según la tienda
     * @param {number} storeId Número identificador de la tienda
     */
    function getCart(storeId) {
      return $http.get(`${config.URL_API}/paperbag/carro/${storeId}`);
    }

    /**
     * Obtener el índice del producto se encuentra en el carro.
     * La comprobación de los productos en el carro se complejizó cuando llegaron los packs dinámicos, debido a que
     * puede haber más de un producto con el mismo sku.
     * @param item {object}
     * @param itemsPack {object[]}
     * @param cart {object}
     */
    function getExistingItemIndex(item, itemsPack, cart) {
      let indexes = [];

      cart.productos.reduce((acc, cur, idx) => {
        if (cur.sku === item.sku) {
          indexes.push(idx);
        }
        return indexes;
      }, []);

      if (!indexes.length) {
        // No hubo coincidencias.
        return -1;
      } else {
        let currentIndex = 0;
        let existingItemFound = false;

        // La lista de índices representan las coincidencias del sku en el carro, así que hay que recorrerlas.
        // El ciclo se va a repetir mientras que existan índices sin comparar y mientras no se haya encontrado un
        // producto igual.
        let i = 0;
        do {
          currentIndex = indexes[i];

          // Si el producto no es un pack, entonces ya tenemos un índice ganador.
          // Un producto no puede ser un pack y normal al mismo tiempo (por ahora).
          if (
            !itemsPack ||
            !itemsPack.length ||
            !cart.productos[currentIndex].productosPack ||
            !cart.productos[currentIndex].productosPack.length
          ) {
            existingItemFound = true;
            break;
          } else {
            // Si el producto es un pack, debemos verificar si los productos son iguales.
            let cartItemsPack = cart.productos[currentIndex].productosPack;
            const formattedItemsPack = formatItemsPack(itemsPack);

            // Si la longitud de las listas son diferentes, los packs no pueden ser iguales.
            if (cartItemsPack.length !== formattedItemsPack.length) {
              break;
            } else {
              // Antes de la comparación, asumimos que los objetos son iguales.
              // Si algún sku no coincide, lo estableceremos en falso.
              let sameItems = true;

              // Creamos una copia del carro actual para que no se modifique en la vista.
              let itemsPackCopy = angular.copy(formattedItemsPack);

              // Organizamos las listas por sku para asegurarnos de que estamos comparando dos listas iguales.
              cartItemsPack.sort(sortBySku);
              itemsPackCopy.sort(sortBySku);

              for (let j = 0; j < cartItemsPack.length; j++) {
                if (
                  cartItemsPack[j].sku !== itemsPackCopy[j].sku ||
                  (cartItemsPack[j].sku === itemsPackCopy[j].sku &&
                    cartItemsPack[j].cantidad !== itemsPackCopy[j].cantidad)
                ) {
                  sameItems = false;
                  break;
                }
              }

              // Si es el mismo pack, ya tenemos un índice ganador.
              if (sameItems) {
                existingItemFound = true;
                break;
              }
            }
          }

          i++;
        } while (!existingItemFound && i < indexes.length);

        return existingItemFound ? currentIndex : -1;
      }
    }

    /**
     * Disminuir la cantidad de productos del carro
     * @param {object} item Elemento a disminuir
     * @param {string} source Origen del llamado de la función para analíticas
     * @returns {*}
     */
    function removeFromCart(item, itemsPack, source) {
      const defer = $q.defer();
      const cart = angular.copy(GlobalService.cart);

      // Obtener índice del producto en el carro
      const sameItemIndex = getExistingItemIndex(item, itemsPack, cart);
      if (sameItemIndex !== -1) {
        // Confirmar si el producto se va a eliminar del carro
        if (cart.productos[sameItemIndex].cantidad <= 1) {
          // El usuario debe confirmar que desea eliminar el producto
          // Mostrar alerta del sistema
          const productName = item.titulo || item.name || item.dish_name || item.nombre;

          AlertService.deleteProduct(
            productName,
            function () {
              defer.resolve(false);
            },
            function () {
              deleteFromCart(defer, item);
            }
          );
        } else {
          // Disminuir producto
          cart.productos[sameItemIndex].cantidad--;

          setCart(cart)
            .then((value) => {
              const newCart = value.data.response;

              // Enviar analíticas, si es que el producto está en el carro
              const itemInCart = newCart.productos.find((product) => product.sku === item.sku);
              if (itemInCart) {
                AnalyticsService.removeFromCart(source, item, itemInCart.cantidad);
              }

              defer.resolve(value);
            })
            .catch((reason) => {
              defer.reject(reason);
            });
        }
      } else {
        defer.resolve(data);
      }

      return defer.promise;
    }

    /**
     * Establecer el carro de compras del usuario, según la tienda
     * @param {object} cart Carro de compras
     * @param {object} configDelivery
     */
    function setCart(cart, configDelivery) {
      if (!configDelivery) {
        configDelivery = GlobalService.configDelivery;
      }

      const storeId = configDelivery.store.id;
      const kind = configDelivery.kind;
      const bloque = cart.bloque;

      /**
       * Para minimizar errores 500, hay que formatear el carro -_-
       *
       * @example
       * data: {
       *   productos: [{
       *     sku: 'PackDinamico009',
       *     cantidad: 1,
       *     productosPack: [
       *     {
       *       sku: 'PTPP02023',
       *       cantidad: 3
       *     },
       *   ],
       *   tipoDistribucion: 'ENVIO',
       *   tipoDescuento: 'PERCENTAGE',
       *   alcancia: {
       *     usar: true,
       *   }
       * }
       */
      let products = [];
      let product;
      cart.productos.forEach((cartDetail) => {
        product = {
          sku: cartDetail.sku,
        };

        // Validar si es un pack
        if (cartDetail.productosPack && cartDetail.productosPack.length) {
          product.productosPack = formatItemsPack(cartDetail.productosPack);
        }

        // Asignar cantidad.
        product.cantidad = cartDetail.cantidad;

        // Agregar a la lista de productos.
        products.push(product);
      });

      // Preparar objeto.
      const data = {
        bloque: bloque,
        productos: products,
        tipoDistribucion: kind === 0 ? 'ENVIO' : 'RETIRO',
        tipoDescuento: cart.tipoDescuento || 'NINGUNO',
        alcancia: {
          usar: cart.alcancia ? cart.alcancia.usar : false,
        },
      };

      return $http.put(`${config.URL_API}/paperbag/carro/${storeId}/WEB`, data);
    }

    /**
     * Ordenar los productos por sku
     * @param a Primer producto
     * @param b Segundo producto
     * @returns {number}
     */
    function sortBySku(a, b) {
      if (a.sku < b.sku) {
        return -1;
      }
      if (a.sku > b.sku) {
        return 1;
      }
      return 0;
    }
  }
})();
