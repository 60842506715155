(function () {
  'use strict';

  angular.module('app.core', [
    'core.factories',
    'core.services',
    'core.directives',
    'core.filters',
  ]);
})();
