(function () {
  'use strict';

  angular.module('app.pages', [
    'pages.checkout',
    'pages.externalRating',
    'pages.home',
    'pages.productDetails',
    'pages.purchaseFailed',
    'pages.purchaseSucceed',
    'pages.recoverPassword',
    'pages.transbankTimeout',
    'pages.userProfile',
    'pages.activateAccount',
  ]);
})();
