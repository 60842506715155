(function () {
  'use strict';

  angular
    .module('pages.userProfile.addresses')
    .controller('AddressesController', AddressesController);

  function AddressesController(
    $anchorScroll,
    $rootScope,
    $scope,
    AlertService,
    AnalyticsService,
    AddressService,
    GlobalService
  ) {
    var vm = this;
    $scope.global = GlobalService;

    vm.message = '';
    vm.messageType = '';
    vm.addresses = null;
    vm.addressesWithDelivery = [];
    vm.addressesWithoutDelivery = [];

    vm.addressesWithoutDeliveryInfo = '';

    var unregisterAddresses = function () {
      return undefined;
    };

    vm.$onInit = onInit;
    vm.openCreateAddressModal = openCreateAddressModal;
    vm.openDeleteAddressAlert = openDeleteAddressAlert;
    vm.openEditAddressModal = openEditAddressModal;

    $scope.deleteAddress = deleteAddress;
    $scope.verifyDelivery = verifyDelivery;
    $scope.$on('$destroy', onDestroy);

    $rootScope.$on('addressCreated', addressCreated);
    $rootScope.$on('addressEdited', addressEdited);
    $rootScope.$on('addressError', addressError);

    function onInit() {
      $anchorScroll();
      vm.addresses = GlobalService.addresses;
      $scope.verifyDelivery();

      $scope.$watch(
        'global.addresses',
        function (newVal) {
          if (newVal) {
            vm.addresses = newVal;
            $scope.verifyDelivery();
          }
        },
        true
      );

      AnalyticsService.profileView('/direcciones');
    }

    function onDestroy() {
      unregisterAddresses();
    }

    function addressCreated() {
      vm.message = 'Agregaste una nueva dirección';
      vm.messageType = 'success';
    }

    function addressEdited() {
      vm.message = 'Editaste la dirección';
      vm.messageType = 'success';
    }

    function addressError(event, opt) {
      vm.message = opt.reason;
      vm.messageType = 'warning';
    }

    /**
     * Eliminar dirección
     * @param address
     */
    function deleteAddress(address) {
      AddressService.deleteAddress(address.id).then(
        function () {
          GlobalService.addresses = $scope.global.addresses.filter(function (el) {
            return el.id !== address.id;
          });

          vm.message = 'Eliminaste una dirección';
          vm.messageType = 'success';

          AnalyticsService.deleteAddress(address);
        },
        function (reason) {
          vm.message = reason.data.message;
          vm.messageType = 'warning';
        }
      );
    }

    function openCreateAddressModal() {
      $rootScope.$broadcast('openAddressModal', { source: 'user-new-address' });
      console.info('Broadcast: ¡Abrir modal de dirección!');
    }

    /**
     * Abrir el modal para editar la dirección
     * @param address
     */
    function openEditAddressModal(address) {
      $rootScope.$broadcast('openAddressModal', { source: 'user-edit-address', address: address });
      console.info('Broadcast: ¡Abrir modal de dirección!');
    }

    /**
     * Abrir el modal de alerta de confirmación para eliminar la dirección
     * @param address
     */
    function openDeleteAddressAlert(address) {
      // No permitir eliminar la dirección que está siendo usada como reparto
      if (
        GlobalService.configDelivery.kind === 0 &&
        GlobalService.configDelivery.address.id === address.id
      ) {
        AlertService.deleteDeliveryAddress();
      } else {
        AlertService.deleteAddress(function () {
          $scope.deleteAddress(address);
        });
      }
    }

    /**
     * Verificar las direcciones que tienen delivery o no
     */
    function verifyDelivery() {
      if (vm.addresses && vm.addresses.length) {
        vm.addressesWithDelivery = vm.addresses.filter(function (address) {
          return address.delivery_available;
        });

        vm.addressesWithoutDelivery = vm.addresses.filter(function (address) {
          return !address.delivery_available;
        });

        // Actualiza mensaje de deliveries sin dirección
        if (vm.addressesWithoutDelivery.length) {
          vm.addressesWithoutDeliveryInfo = 'Estamos trabajando para llegar cada vez más lejos.';

          if (vm.addressesWithoutDelivery.length === 1) {
            vm.addressesWithoutDeliveryInfo += ' Esta dirección todavía está';
          } else if (vm.addressesWithoutDelivery.length > 1) {
            vm.addressesWithoutDeliveryInfo += ' Estas direcciones todavía están';
          }

          vm.addressesWithoutDeliveryInfo += ' fuera de nuestra área de delivery.';
        } else {
          vm.addressesWithoutDeliveryInfo = '';
        }
      } else {
        vm.addressesWithDelivery = [];
        vm.addressesWithoutDelivery = [];
        vm.addressesWithoutDeliveryInfo = '';
      }
    }
  }
})();
