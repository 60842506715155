(function () {
  'use strict';

  angular.module('shared.billingModal').component('billingModalComponent', {
    templateUrl: 'shared/billing-modal/billing-modal.template.html',
    controller: BillingModalController,
    controllerAs: 'vm',
  });

  function BillingModalController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    NgMap,
    AddressService,
    AlertService,
    AnalyticsService,
    CartService,
    GlobalService,
    LogService,
    StoreService,
    UserService,
    BillingService
  ) {
    var vm = this;
    vm.validateRUT = validateRUT;

    $scope.global = GlobalService;

    // Variable para manejar qué mostrar en la vista
    vm.source = 0;
    vm.busy = false;
    vm.currentStep = 1;

    // Variables para saber de dónde se ha llamado el modal
    var CONFIG_DELIVERY = 'config-delivery-modal',
      ONBOARDING = 'onboarding',
      USER_NEW_BILLING = 'user-new-billing',
      USER_EDIT_BILLING = 'user-edit-billing';

    // Formulario

    var ADDRESS_EMPTY = {
      id: 0,
      name: '',
      street_name: '',
      number: '',
      unit_number: '',
      commune_id: null,
      comuna: ''
    };
    vm.billing = angular.copy(ADDRESS_EMPTY);    

    vm.readOnly = {
      street_name: false,
      number: false,
      commune_id: false,
      comuna: false
    };

    // Google maps
    vm.map = null;

    vm.communes = [];

    // Mensaje al usuario
    vm.showUserMessage = false;
    vm.userMessage = '';
    vm.userMessageType = '';
    vm.isRUTValid = true; 

    // Funciones enlazadas a la vista
    vm.$onInit = onInit;
    vm.closeBillingModal = closeBillingModal;
    vm.saveBilling = saveBilling;
    vm.setCoords = setCoords;
    vm.stepBack = stepBack;
    vm.selectBilling = selectBilling;
    vm.openCreateBillingModal = openCreateBillingModal;

    // Funciones enlazadas al controlador
    $scope.addNewBilling = addNewBilling;
    $scope.editAddress = editAddress;
    $scope.editAddressWithoutUser = editAddressWithoutUser;

    // Funciones enlazadas al rootScope
    $rootScope.$on('openBillingModal', openBillingModal);

    function onInit() {
      $rootScope.$on('openBillingModal', openBillingModal); 
         
    }


    

        $scope.$watch('vm.billing.rut', function (newValue) {
            vm.isRUTValid = validateRUT(newValue);
        });

    /**
     * Agregar nueva dirección
     */
    function addNewBilling() {      
      BillingService.addBilling(vm.billing)
        .then((value) => {
          const newBilling = value.data.response;
          GlobalService.billing.response.unshift(newBilling);
          console.info('facturaciones:', GlobalService.billing);
          // Si ocurriera un error y el modal se invocó desde la configuración de entrega,
          // los "exitos" o "errores" se manejan desde allá
          if (vm.source === CONFIG_DELIVERY) {
            vm.closeBillingModal(true, newBilling);
            // De lo contrario, los manejamos desde aquí
          } else {
            vm.closeBillingModal(true,newBilling);
            AnalyticsService.createAddress(newBilling);
          }
        })
        .catch(function (reason) {
          console.log("reasonreason",reason);
          vm.closeBillingModal(false, reason.data.message);
        });
    }


    /**
     * Canal de comunicación en el que el modal de billing pueda hacerle saber a otros
     * componentes qué es lo que está ocurriendo
     * @param {boolean} success La operación fue un exito
     * @param {string|object} data Información adicional. Puede ser una dirección o un mensaje de exito/error
     */
    function closeBillingModal(success, data) {
      $('#billing-modal-component').modal('hide');

      if (vm.source === CONFIG_DELIVERY) {
        $timeout(() => {
          var state = 'billing-modal-';
          state += success ? 'success' : 'failure';
          $rootScope.$broadcast('openConfigDeliveryModal', { state: state, data: data });
          console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
        });
      } else if (vm.source === USER_NEW_BILLING) {
        if (success) {
          $rootScope.$broadcast('billingCreated', {billing:data});
          console.info('Broadcast: ¡Se agregó datos de facturacion!');
        } else {
          $rootScope.$broadcast('billingError', { reason: data });
          console.info('Broadcast: ¡Ocurrió un error al agregar datos!');
        }
      } else if (vm.source === USER_EDIT_BILLING) {
        if (success) {
          $rootScope.$broadcast('billingEdited');
          console.info('Broadcast: ¡Se modificó la dirección!');
        } else {
          $rootScope.$broadcast('billingError', { reason: data });
          console.info('Broadcast: ¡Ocurrió un error al intentar modificar la dirección!');
        }
      } else if (vm.source === ONBOARDING) {
        $timeout(() => {
          $rootScope.$broadcast('openConfigDeliveryModal', { state: ONBOARDING, data: data });
          console.info('Broadcast: ¡Abrir modal de configuración de entrega!');
        });
      }
    }

    /**
     * Editar una dirección existente
     */
    function editAddress() {
      console.log("EN EDIT",vm.billing);
      
      BillingService.editBilling(vm.billing).then((res) =>  {
        let editedBilling = res.data.response;
        const editedBillingIndex = GlobalService.billing.response.findIndex((billing) => {
          return billing.id === editedBilling.id;
        });
        GlobalService.billing.response[editedBillingIndex] = editedBilling;
        vm.closeBillingModal(true,res.data.message);
      });
    }

    /**
     * Editar una billing que no tiene usuario
     */
    function editAddressWithoutUser() {
      vm.billing.without_user = true;

      AddressService.modifyAddress(vm.billing)
        .then((response) => {
          const editedAddress = response.data;

            GlobalService.addresses.push(editedAddress);
            StoreService.getStores().then((value) => {
              const stores = value;

              GlobalService.configDelivery.kind = 0;
              GlobalService.configDelivery.address = editedAddress;
              GlobalService.configDelivery.store = stores.find(function (el) {
                return el.id === editedAddress.store_id;
              });

              // Verificar carro
              $scope.checkCart();

              // Cerrar modal
              vm.closeBillingModal(true, editedAddress);

              // Ocultar la ruta onboarding
              $state.go('home');
            });
        })
        .catch((reason) => {
          vm.error = reason.data.message;
        })
        .finally(() => {
          vm.busy = false;
        });
    }

    /**
     * Abrir modal de billing
     */
    function openBillingModal(event, opt) {
      vm.source = opt.source;
      vm.busy = false;
      vm.currentStep = 1;

      vm.allBilling =  $scope.global.billing.response;

      // Limpiar estado del formulario
      $scope.billingForm.$setPristine();
      $scope.billingForm.$setUntouched();

      // Cargar lista de comunas
      if (!vm.communes.length) {
        AddressService.getCommunes().then((value) => {
          vm.communes = value;
        });
      }

      // Establecer el valor de los campos del formulario
      if (vm.source === USER_EDIT_BILLING) {
        
        const { calle, id, ciudad, comuna, email, giro, nombre_compania, nombre_facturador, numero_calle, rut, usuario } = opt.address;


        vm.billing.id = id;
        vm.billing.calle = calle;
        vm.billing.ciudad = ciudad;
        vm.billing.comuna = comuna;
        vm.billing.email = email;
        vm.billing.giro = giro;
        vm.billing.nombre_compania = nombre_compania;
        vm.billing.nombre_facturador = nombre_facturador;
        vm.billing.numero_calle = numero_calle;
        vm.billing.rut = rut;
        vm.billing.usuario = usuario;

        $('#billing-modal-component').modal('show');
        //AnalyticsService.initEditAddress(opt.address, vm.source);
      } else if (vm.source === USER_NEW_BILLING || vm.source === CONFIG_DELIVERY) {
        vm.address = angular.copy(ADDRESS_EMPTY);
        vm.comment = '';

        $('#billing-modal-component').modal('show');
        AnalyticsService.initCreateAddress(vm.source);
      } else if (vm.source === ONBOARDING) {
        if ($stateParams && $stateParams.addressId) {
          vm.busy = true;
          AddressService.getAddressWithoutUser($stateParams.addressId)
            .then(function (value) {
              const { commune, id, number, street_name } = value.data;

              vm.address.id = id;

              vm.address.street_name = street_name;
              vm.address.number = number;
              vm.address.commune_id = commune ? commune.id : null;

              vm.readOnly.street_name = !!street_name;
              vm.readOnly.number = !!number;
              vm.readOnly.commune_id = !!commune;

              $('#-modal-component').modal('show');
              AnalyticsService.initCreateAddress(vm.source);
            })
            .catch((reason) => {
              console.error(reason);
              $state.go('home');
            })
            .finally(() => {
              vm.busy = false;
            });
        } else {
          const message =
            'Se está intentando abrir el modal de direcciones en el flujo onboarding sin un id de dirección en la ruta';
          console.warn(message);
          LogService.unexpectedCase(vm.constructor.name, message);
          $state.go('home');
        }
      } else if (vm.source == 'select-billing') {
        $('#billing-modal-component').modal('show');
      } else {
        const message =
          'Se está intentando abrir el modal de direcciones desde un origen desconocido';
        console.warn(message);
        LogService.unexpectedCase(vm.constructor.name, message);
        $state.go('home');
      }
    }

    /**
     * Agregar nueva dirección
     */
    function saveBilling() {
      vm.busy = true;
      vm.billing.comment = vm.comment;
      vm.billing.coordinates = vm.coordinates;
      if (vm.source === USER_EDIT_BILLING) {
        $scope.editAddress();
      } else if (vm.source === CONFIG_DELIVERY || vm.source === USER_NEW_BILLING) {
        $scope.addNewBilling();
      } else if (vm.source === ONBOARDING) {
        $scope.editAddressWithoutUser();
      } else {
        const message = 'Se está intentando agregar/editar una dirección desde un origen desconocido';
        console.warn(message);
        LogService.unexpectedCase(vm.constructor.name, message);
      }
    }

    /**
     * Establecer las coordenadas del mapa después de haber modificado el cursor
     */
    function setCoords() {
      vm.coordinates.latitude = vm.map.getCenter().lat();
      vm.coordinates.longitude = vm.map.getCenter().lng();
    }

    /**
     * Volver al paso anterior. No permitir devolverse al paso 0 o -1...
     */
    function stepBack() {
      vm.currentStep = vm.currentStep - 1 || 1;
    }

    function selectBilling(billing) {
      $rootScope.$broadcast('billingSelected', {billing});
      $('#billing-modal-component').modal('hide');
    }

    function openCreateBillingModal() {
      $rootScope.$broadcast('openBillingModal', { source: 'user-new-billing' });
      console.info('Broadcast: ¡Abrir modal de billing!');
    }

    function validateRUT(rut) {
      if (!rut || typeof rut !== 'string') return false;
  
      // Limpiar el RUT de puntos y guiones
      const cleanRUT = rut.replace(/\./g, '').replace(/-/g, '');
  
      const body = cleanRUT.slice(0, -1);
      const dv = cleanRUT.slice(-1).toUpperCase();
  
      if (body.length < 7) return false;
  
      let sum = 0;
      let multiplier = 2;
  
      // Calcula la suma de los productos
      for (let i = body.length - 1; i >= 0; i--) {
          sum += body[i] * multiplier;
          multiplier = multiplier === 7 ? 2 : multiplier + 1;
      }
  
      const remainder = sum % 11;
      const expectedDV = 11 - remainder;
      const finalDV = expectedDV === 11 ? '0' : expectedDV === 10 ? 'K' : expectedDV.toString();
  
      return finalDV === dv;
    }
  
  


  }
})();
