(function () {
  'use strict';

  angular.module('shared.categoriesMenu').component('categoriesMenuComponent', {
    templateUrl: 'shared/categories-menu/categories-menu.template.html',
    controller: CategoriesMenuController,
    controllerAs: 'vm',
    bindings: {
      toggleMenu: '&',
      clickOverlayMenu: '&',
      desktopMenuIsOpen: '<',
      mobileMenuIsOpen: '<',
    },
  });

  function CategoriesMenuController(
    $document,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $stateParams,
    $window,
    AnalyticsService,
    CatalogService,
    GlobalService,
    MetaTagService,
    HomeNavigationService,
    Slug,
    $location
  ) {
    const vm = this;
    $scope.global = GlobalService;

    vm.busy = true;
    vm.menuItems = [];

    // Variable para dejar de escuchar los cambios en el rootScope
    let unregisterMenuData;

    // Variables enlazadas al controlador
    $scope.swiper = null;
    $scope.swipeRightAnalytics = false;
    $scope.swipeLeftAnalytics = false;
    $scope.clickRightAnalytics = false;
    $scope.clickLeftAnalytics = false;
    $scope.prevSwipePosition = null;

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.changeItem = changeItem;
    vm.onClick = onClick;
    vm.navToCategory = navToCategory;
    vm.scrollTop = scrollTop;

    // Funciones enlazadas al controlador
    $scope.$on('$destroy', onDestroy);
    $scope.updateMenuItems = updateMenuItems;

    // Funciones enlazadas al rootScope
    $rootScope.$on('changeCategory', changeCategory);

    // Nuevo menu
    vm.desktopCurrentSubcategories = null;
    vm.mobileCurrentSubcategories = null;
    vm.currentMenuItemId = null;
    vm.currentSubcategoryImage = null;
    vm.mobileCatHeight = {};
    $scope.showSubCategories = showSubCategories;
    $scope.selectSubcategoryFromMenu = selectSubcategoryFromMenu;
    $scope.showSubcategoryImage = showSubcategoryImage;

    function onInit() {
      $scope.updateMenuItems();
      vm.mobileCatHeight.height = $(window).height().toString() + 'px';
    }

    function onDestroy() {
      unregisterMenuData();
    }

    /**
     * Cambiar categoría
     * @param event
     * @param opt
     */
    function changeCategory(event, opt) {
      const categoryId = parseInt(opt.categoryId || opt.category.id);
      const categoryName = opt.categoryName || opt.category.name;

      // Encontrar la posición de la categoría, ignorando las categorías invisibles
      const categories = vm.menuItems.filter((item) => item.visible);
      const position = categories.findIndex((item) => {
        return item.id === categoryId;
      });

      if (categoryId) {
        $state
          .go('home.category', {
            categoryId: categoryId,
            categoryName: Slug.slugify(categoryName),
          })
          .then(() => {
            vm.changeItem(position);
          });

        // Verificar que la categoría no esté invisible
        if (position === -1) {
          $timeout(CatalogService.updateCatalog);
        }
      }
    }

    /**
     * Cambiar elemento del menú
     * @param {number} index Posición en la que se encuentra el elemento seleccionado
     */
    function changeItem(index) {
      GlobalService.productCategories.forEach((item) => {
        item.active = false;
      });

      if (index !== -1) {
        vm.menuItems[index].active = true;

        GlobalService.activeCategory = vm.menuItems[index];
        // console.info('Categoría activa: ', GlobalService.activeCategory);

        $rootScope.$broadcast('activeCategoryUpdated');
        // console.info('Broadcast: ¡Se actualizó la categoría activa!');

        // Enviar analíticas
        AnalyticsService.clickCategory(vm.menuItems[index]);

        // Actualizar metatags
        MetaTagService.updateMetaTags('home.category', GlobalService.activeCategory);

        // Hack para mostrar el elemento del menú, si es que estaba oculto cuando fue seleccionado
        const slider = $document[0].querySelectorAll('.swiper-slide')[index];
        const component = $document[0].querySelectorAll('menubar-component')[0];

        if (slider && component) {
          const overflowLeft = slider.offsetLeft < 0;
          const overflowRight = slider.offsetLeft + slider.offsetWidth > component.offsetWidth;

          // @TODO Esto siempre es verdadero!
          if (overflowLeft || overflowRight) {
            // $scope.swiper.slideTo(index);
          }
        }
      }
    }

    /**
     * Manejar cuando se hace click a algún slider
     * @param {object} swiper Objeto Swiper
     * @param {object} event Objeto del evento
     */
    function onClick(swiper, event) {
      let element;

      // Debemos asegurarnos de obtener el elemento `slide` y no el `a`
      if (event.target.classList.contains('menubar-url')) {
        element = event.target.parentNode;
      } else {
        element = event.target;
      }

      // Verificar si el elemento se encuentra dentro del DOM
      const rect = element.getBoundingClientRect();
      const visible =
        rect.left >= 0 &&
        rect.right <= ($window.innerWidth || $document[0].documentElement.clientWidth);

      if (!visible) {
        const index = [...element.parentElement.children].indexOf(element);
        swiper.slideTo(index);
      }
    }

    function updateMenuItems() {
      unregisterMenuData = $scope.$watch('global.menuData', (newValue) => {
        if (newValue && newValue.length) {
          const menuItems = newValue;
          // Crearle un slug name a cada categoría, para que sea amigable con la ruta
          menuItems.forEach((category, position) => {
            category.slugName = Slug.slugify(category.nombre);
            category.position = position;
          });
          vm.busy = false;
          vm.menuItems = menuItems;
        } else {
          vm.menuItems = [];
          vm.busy = false;
        }
      });
      
    }

    function scrollTop(duration) {
      $('html,body').animate({ scrollTop: 0 }, duration);
    }

    function showSubCategories(id, device) {
      let menuCategory = vm.menuItems.find((item) => item.id === id);

      menuCategory.secciones.forEach((seccion, index) => {
        seccion.slugName = Slug.slugify(seccion.nombre);
        seccion.categoryId = menuCategory.id;
        seccion.subcategoryIndex = index;
        seccion.selected = false;
        seccion.categoryName = menuCategory.slugName;
      });

      if (device === 'desktop') {
        vm.desktopCurrentSubcategories = menuCategory;
        vm.currentSubcategoryImage = menuCategory.secciones[0].imagen;
      }

      if (device === 'mobile') {
        vm.mobileCurrentSubcategories = menuCategory;
        // obteniendo cantidad de subcategorias para multipicar
        // por el alto + el margin-bottom que tiene cada li, lo que da 27px
        // pero lo aproximé a 30px
        let result = menuCategory.secciones.length * 30;
        let currentHeight = parseInt(vm.mobileCatHeight.height.split('px')[0]); //valor actual

        vm.mobileCurrentSubcategories.position = vm.menuItems.findIndex((item) => item.id === id);
        if (!vm.mobileCurrentSubcategories.showMobileContent) {
          let newResult = currentHeight + result;
          vm.mobileCatHeight.height = newResult.toString() + 'px';
          console.log('element vm.mobileCatHeight.height 4', vm.mobileCatHeight.height);

          console.log('element currentHeight 2', newResult, currentHeight, $(window).height());
          // Si el alto actual es mayor al de la ventana que se agregue el scroll
          if (currentHeight > $(window).height()) {
            $('ul#mobileCategories').addClass('overflowY');
          } else {
            $('ul#mobileCategories').removeClass('overflowY');
          }
          vm.mobileCurrentSubcategories.showMobileContent = true;
        } else {
          // quitar alto de categoria seleccionada
          let newResult = currentHeight - result;
          vm.mobileCatHeight.height = newResult.toString() + 'px';

          vm.mobileCurrentSubcategories.showMobileContent =
            !vm.mobileCurrentSubcategories.showMobileContent;
        }
      }
    }

    function selectSubcategoryFromMenu(params) {
      vm.clickOverlayMenu();
      let categoryId = $location.path().split('/')[2];

      // Scroll horizontal en contenedor de botones subcategorias
      HomeNavigationService.scrollHorizontalNavMobile(params.subcategoryIndex);

      if (categoryId == params.categoryId) {
        HomeNavigationService.scrollToSubcategory(params.subcategoryIndex);
        return;
      } else {
        HomeNavigationService.goToSubCategory(
          params.categoryId,
          params.categoryName,
          params.subcategoryIndex
        );
      }
    }

    function showSubcategoryImage(img) {
      vm.currentSubcategoryImage = img;
    }

    $scope.$watch(
      'vm.mobileMenuIsOpen',
      function handleFlagssChange() {
        if (!vm.mobileMenuIsOpen) {
          vm.mobileCurrentSubcategories = null;
          vm.menuItems.forEach((category) => {
            delete category.showMobileContent;
          });
        }
      },
      true
    );

    function navToCategory(catId, catName) {
      vm.toggleMenu({ device: 'desktop' });
      $rootScope.$broadcast('changeCategory', {
        categoryId: catId,
        categoryName: catName,
      });
      scrollTop(100);
    }
  }
})();
