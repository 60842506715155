(function () {
  'use strict';

  angular.module('shared.productCard').component('productCardComponent', {
    templateUrl: 'shared/product-card/product-card.template.html',
    controller: ProductCardController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
    },
  });

  function ProductCardController(
    $rootScope,
    $scope,
    $state,
    $timeout,
    Slug,
    AnalyticsService,
    CartService,
    GlobalService,
    ProductService
  ) {
    const vm = this;

    $scope.global = GlobalService;

    vm.busy = false;
    vm.slugName = '';

    vm.inCart = false;
    vm.showTags = false;
    vm.showLastUnitsMessage = false;
    vm.showFreshProduct = false;
    vm.isPack = false;

    // Variables para dejar de escuchar los cambios en el rootScope
    let unregisterCart = function () {};
    let unregisterConfigDeliveryKind = function () {};

    // Funciones enlazadas con la plantilla
    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.addToCart = addToCart;
    vm.decreaseProduct = decreaseProduct;
    vm.goToDetails = goToDetails;
    vm.gridRowsClass = gridRowsClass;
    vm.preventRedirection = preventRedirection;
    vm.viewDetail = viewDetail;

    // Funciones enlazadas al controlador
    $scope.updateFreshProduct = updateFreshProduct;
    $scope.updateProductInCart = updateProductInCart;
    $scope.updateSlugName = updateSlugName;

    /**
     * Constructor
     */
    function onInit() {
      $scope.updateFreshProduct();
      $scope.updateProductInCart();
      $scope.updateSlugName();

      // Una etiqueta se muestra si el producto es nuevo, destacado o es un pack
      // El orden de prioridad es pack > nuevo > destacado
      vm.showTags =
        vm.product.nuevo ||
        vm.product.destacado ||
        (vm.product.tipoPackDinamico && vm.product.tipoPackDinamico.descripcion);

      // El mensaje de últimas unidades se muestra si hay en stock menos de 3 productos
      vm.showLastUnitsMessage = vm.product.stock < 3 && vm.product.stock > 0;

      // Un producto es considerado un pack si tiene la propiedad `tipoPackDinamico`.
      // Sin embargo, el tipo de pack `N_IGUALES` es considerado un producto normal
      vm.isPack = vm.product.tipoPackDinamico && vm.product.tipoPackDinamico.codigo !== 'N_IGUALES';

      // Si el carro de compras cambia, actualizar la variable del controlador.
      // La idea es conectar el producto con el carro de compras, para saber cuantas
      // unidades hay disponibles.
      unregisterCart = $scope.$watch('global.cart', $scope.updateProductInCart);

      // Si el usuario tiene la configuración de retiro, se visualiza el mensaje de producto de consumo inmediato
      unregisterConfigDeliveryKind = $scope.$watch(
        'global.configDelivery.kind',
        $scope.updateFreshProduct
      );
    }

    function onChanges(changes) {
      if (!angular.equals({}, changes.product.previousValue)) {
        $scope.updateSlugName();
        $scope.updateProductInCart();
      }
    }

    $scope.$on('$destroy', function () {
      unregisterCart();
      unregisterConfigDeliveryKind();
    });

    /**
     * Agregar propiedades adicionales al producto
     */
    function updateSlugName() {
      const { titulo, subtitulo } = vm.product;
      vm.slugName = Slug.slugify(`${titulo} ${subtitulo}`);
    }

    /**
     * Agregar un producto al carro
     */
    function addToCart() {
      if (!vm.busy) {
        vm.busy = true;

        // Agregar producto al carro
        CartService.addToCart(vm.product, [], 'catalog')
          .then((value) => {
            $scope.global.cart = value;

            $timeout(() => {
              $rootScope.$broadcast('updateAlwaysVisible');
              console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
            });
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            vm.busy = false;
          });
      }
    }

    /**
     * Disminuir producto existente en el carro de compras
     */
    function decreaseProduct() {
      if (!vm.busy) {
        vm.busy = true;

        CartService.removeFromCart(vm.product, 'catalog')
          .then((value) => {
            if (value) {
              $scope.global.cart = value.data.response;
            }

            $timeout(function () {
              $rootScope.$broadcast('updateAlwaysVisible');
              console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
            });
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            vm.busy = false;
          });
      }
    }

    /**
     * Ir a los detalles del producto.
     * Este método se usa cuando no se pueda redireccionar a los detalles del producto vía ui-sref
     */
    function goToDetails() {
      $state.go('productDetails', { productId: vm.product.id, productName: vm.slugName });
    }

    /**
     * Agrega clases dependiendo de la información que tenga el producto
     * @returns {{"with-tags": (boolean|*), "with-last-units": boolean}}
     */
    function gridRowsClass() {
      return {
        'with-tags': vm.showTags,
        'with-last-units': vm.showLastUnitsMessage,
      };
    }

    /**
     * Evitar que al hacer click en ciertas partes de la tarjeta del producto, redireccione la página.
     * Útil para que se pueda usar el botón para agregar o quitar productos del carro
     * @param $event
     */
    function preventRedirection($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    /**
     * Actualizar si se debe mostrar la marca de producto fresco
     */
    function updateFreshProduct() {
      const { configDelivery } = $scope.global;
      vm.showFreshProduct =
        configDelivery && vm.product.consumoInmediato && configDelivery.kind === 1;
    }

    /**
     * Actualizar si el producto se encuentra en el carro
     */
    function updateProductInCart() {
      const { cart } = $scope.global;

      if (cart && cart.productos && cart.productos.length) {
        const itemInCart = cart.productos.find((product) => product.sku === vm.product.sku);
        vm.inCart = itemInCart ? itemInCart.cantidad : 0;
      } else {
        vm.inCart = 0;
      }
    }

    /**
     * Ir a detalles del producto.
     * Realmente es para fines analíticos
     */
    function viewDetail() {
      let productDetail = vm.product;
      productDetail.categoryId = GlobalService.activeCategory.id;
      productDetail.subcategoryId = GlobalService.activeCategory.secciones.findIndex(
        (elem) => elem.nombre === vm.product.sectionName
      );
      ProductService.setSelectedProduct(productDetail);

      // Enviar analíticas
      AnalyticsService.clickProduct(vm.product);
    }
  }
})();
