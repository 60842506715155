(function () {
  'use strict';

  angular.module('services.contentful').service('ContentfulService', ContentfulService);

  function ContentfulService($q, DateService, contentful, moment, Slug) {
    let contentfulBanners = null;
    let contentfulLandingData = null;
    let contentfulNews = null;
    let contentfulProductCategories = null;
    let contentfulPromoPopup = null;

    return {
      getBanners: getBanners,
      getLandingData: getLandingData,
      getNews: getNews,
      getProductCategories: getProductCategories,
      getPromoPopup: getPromoPopup,
    };

    /**
     * Obtener la lista de banners de Contentful
     * @returns {[]}
     */
    function getBanners() {
      if (!contentfulBanners) {
        contentfulBanners = contentful
          .entries('content_type=ecommercePromoV2&order=-sys.updatedAt') // Banners V2 solo web
          .then((value) => {
            const data = value.data;
            let banners = [];

            if (data.total > 0 && data.items && data.items.length) {
              DateService.getCurrentTimestamp().then((currentTimestamp) => {
                const today = new Date(currentTimestamp * 1000);

                data.items.forEach((item) => {
                  if (item.fields) {
                    const { bannerDesktopImage, bannerMobileImage, begins, ends } = item.fields;

                    if (
                      bannerDesktopImage &&
                      bannerDesktopImage.fields &&
                      bannerDesktopImage.fields.file &&
                      bannerDesktopImage.fields.file.url &&
                      bannerMobileImage &&
                      bannerMobileImage.fields &&
                      bannerMobileImage.fields.file &&
                      bannerMobileImage.fields.file.url &&
                      begins &&
                      ends
                    ) {
                      // ¿La promoción se encuentra dentro de la fecha?
                      if (moment(today).isAfter(begins) && moment(today).isBefore(ends)) {
                        const { title, repeatX, backgroundColor, url, openInNewTab } = item.fields;

                        // El objeto está correctamente configurado desde Contentful
                        banners.push({
                          title: title || '',
                          desktopImage: bannerDesktopImage.fields.file.url,
                          mobileImage: bannerMobileImage.fields.file.url,
                          repeatX: repeatX || false,
                          backgroundColor: backgroundColor || '#ffffff',
                          url: url || '',
                          target: openInNewTab ? '_blank' : '_self',
                        });
                      }
                    }
                  }
                });
              });
            }

            console.info('Contentful: Banners vigentes: ', banners);
            return banners;
          })
          .catch((reason) => {
            console.error(reason);
            return [];
          });
      }

      return contentfulBanners;
    }

    function getLandingData() {
      if (!contentfulLandingData) {
        contentfulLandingData = contentful
          .entries('content_type=ecommerceNewLandingV2&order=fields.position') // Nuevo Landing V2 (solo para Web)
          .then((value) => {
            const data = value.data;
            let landingData = [];
            if (data.total > 0 && data.items && data.items.length) {
              data.items.forEach((item) => {
                const {
                  categoryId,
                  categoryName,
                  categoryDescription,
                  categoryImage,
                  categoryIcon,
                } = item.fields;
                if (
                  categoryId &&
                  categoryName &&
                  categoryDescription &&
                  categoryImage.fields.file.url &&
                  categoryIcon.fields.file.url
                ) {
                  landingData.push({
                    id: categoryId,
                    name: categoryName,
                    description: categoryDescription,
                    image: categoryImage.fields.file.url,
                    icon: categoryIcon.fields.file.url,
                    initialDate: new Date(item.fields.initialDate).valueOf() || null,
                    endDate: new Date(item.fields.endDate).valueOf() || null,
                  });
                }
              });
            }
            return landingData;
          })
          .catch((error) => {
            console.error(error);
            return [];
          });
      }
      return contentfulLandingData;
    }

    /**
     * Obener la última noticia de Contentful
     */
    function getNews() {
      if (!contentfulNews) {
        contentfulNews = contentful
          .entries('content_type=popup&order=-sys.createdAt')
          .then((value) => {
            const data = value.data;
            let news = null;
            var defer = $q.defer();

            if (data.total > 0 && data.items && data.items.length) {
              DateService.getCurrentTimestamp().then((currentTimestamp) => {
                const today = new Date(currentTimestamp * 1000);

                // Recorrer cada noticia para evaluar si se debe mostrar el modal
                data.items.forEach((item) => {
                  if (item.fields) {
                    const { begins, ends, image, text, buttonText } = item.fields;

                    if (
                      begins &&
                      ends &&
                      buttonText &&
                      (text ||
                        (image && image.fields && image.fields.file && image.fields.file.url))
                    ) {
                      // ¿La noticia se encuentra dentro de la fecha?
                      if (moment(today).isAfter(begins) && moment(today).isBefore(ends)) {
                        const { modalTitle } = item.fields;

                        news = {
                          title: modalTitle || '',
                          text: text || '',
                          imageUrl: image ? image.fields.file.url : null,
                          buttonText: buttonText,
                        };
                      }
                    }
                  }
                });

                console.info('Contentful: Última noticia vigente: ', news);
                defer.resolve(news);
              });
            }

            return defer.promise;
          })
          .catch((reason) => {
            console.error(reason);
            return null;
          });
      }

      return contentfulNews;
    }

    /**
     * Obtener la lista de categorías de productos de Contentful
     */
    function getProductCategories() {
      if (!contentfulProductCategories) {
        contentfulProductCategories = contentful
          .entries('content_type=productCategory')
          .then((value) => {
            const data = value.data;
            let productCategories = [];

            if (data.total > 0 && data.items && data.items.length) {
              data.items.forEach((item) => {
                if (item.fields) {
                  const { id, description } = item.fields;
                  if (id && description) {
                    // El objeto está correctamente configurado desde Contentful
                    productCategories.push({
                      id,
                      description,
                    });
                  }
                }
              });
            }

            console.info('Contentful: Categorías de productos: ', productCategories);
            return productCategories;
          })
          .catch((reason) => {
            console.error(reason);
            return [];
          });
      }

      return contentfulProductCategories;
    }

    function getPromoPopup() {
      if (!contentfulPromoPopup) {
        contentfulPromoPopup = contentful
          .entries('content_type=ecommercePromos&order=-sys.createdAt')
          .then((value) => {
            const data = value.data;
            let promoPopup = null;

            if (data.total > 0 && data.items && data.items.length) {
              DateService.getCurrentTimestamp().then((currentTimestamp) => {
                const today = new Date(currentTimestamp * 1000);

                const validPromo = data.items.find((item) => {
                  if (item.fields) {
                    const { begins, ends, popupImage, minPromoValue } = item.fields;
                    if (
                      minPromoValue &&
                      popupImage &&
                      popupImage.fields &&
                      popupImage.fields.file &&
                      popupImage.fields.file.url
                    ) {
                      // ¿La promoción se encuentra dentro de la fecha?
                      if (moment(today).isAfter(begins) && moment(today).isBefore(ends)) {
                        return item;
                      }
                    }
                  }
                });

                if (validPromo) {
                  const { popupImage, title, minPromoValue } = validPromo.fields;
                  promoPopup = {
                    popupImage: popupImage.fields.file.url,
                    promoTitle: title,
                    minPromoValue: minPromoValue,
                  };
                }
              });
            }

            console.info('Contentful: Modal de promoción: ', promoPopup);
            return promoPopup;
          })
          .catch((reason) => {
            console.error(reason);
            return [];
          });
      }

      return contentfulPromoPopup;
    }
  }
})();
