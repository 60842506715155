(function () {
  'use strict';

  angular.module('directives.rutFormValidation').directive('rut', RutFormValidation);

  function RutFormValidation() {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.rut = function (modelValue, viewValue) {
          // No validar si el rut viene vacío o tiene menos de 8 caracteres
          if (ctrl.$isEmpty(modelValue) || viewValue.length < 8) {
            return true;
          }

          var rut = viewValue;

          // Validar que el rut tiene un guión
          if (!/[\-]/.test(rut)) {
            return false;
          }

          // Validar que el rut no tiene espacios en blanco
          if (/[\s]/.test(rut)) {
            return false;
          }

          // Despejar guión
          var valor = rut.replace('-', '');

          // Aislar Cuerpo y Dígito Verificador
          var cuerpo = valor.slice(0, -1);
          var dv = valor.slice(-1).toUpperCase();

          // Si no cumple con el mínimo ej. (n.nnn.nnn)
          if (cuerpo.length < 7) {
            return false;
          }

          // Calcular Dígito Verificador
          var suma = 0;
          var multiplo = 2;

          // Para cada dígito del Cuerpo
          for (var i = 1; i <= cuerpo.length; i++) {
            // Obtener su Producto con el Múltiplo Correspondiente
            var index = multiplo * valor.charAt(cuerpo.length - i);

            // Sumar al Contador General
            suma = suma + index;

            // Consolidar Múltiplo dentro del rango [2,7]
            if (multiplo < 7) {
              multiplo = multiplo + 1;
            } else {
              multiplo = 2;
            }
          }

          // Calcular Dígito Verificador en base al Módulo 11
          var dvEsperado = 11 - (suma % 11) + '';

          // Casos Especiales (0 y K)
          dv = dv === 'K' ? '10' : dv;
          dv = dv === '0' ? '11' : dv;

          // Validar que el Cuerpo coincide con su Dígito Verificador
          if (dvEsperado !== dv) {
            return false;
          }

          // Si llega hasta aqui, decretar que es válido
          return true;
        };
      },
    };
  }
})();
