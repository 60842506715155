(function () {
  'use strict';

  angular.module('filters.shortTime').filter('shortTime', ShortTimeFilter);

  function ShortTimeFilter() {
    return function (time) {
      if (time) {
        return time.replace(/:00|0(?=[0-9])/g, '');
      }
    };
  }
})();
