(function () {
  'use strict';

  angular.module('core.filters', [
    'filters.absoluteValue',
    'filters.shortTime',
    'filters.rutNumber',
    'filters.thousandsSeparator',
  ]);
})();
