(function () {
  'use strict';

  angular.module('productDetails.productPackN').component('productPackN', {
    templateUrl: 'pages/product-details/components/product-pack-n/product-pack-n.template.html',
    controller: ProductPackNController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
    },
  });

  function ProductPackNController($state, CartService, GlobalService) {
    var vm = this;

    // Cantidad máxima de productos que se pueden agregar al carro
    vm.maxProducts = 0;

    // Cantidad de productos agregadas al carro.
    // Siempre inicia en 0.
    vm.inCartQuantity = 0;

    // Mensaje al usuario
    vm.showUserMessage = false;

    vm.$onInit = onInit;
    vm.addPackToCart = addPackToCart;
    vm.addProductToCart = addProductToCart;
    vm.removeProductFromCart = removeProductFromCart;

    // Constructor
    function onInit() {
      // tipoPackDinamico.cantidadProductos viene como un string
      vm.maxProducts = parseInt(vm.product.tipoPackDinamico.cantidadProductos);

      /**
       * Todos los productos que conforman el pack vienen en el arreglo de la propiedad del objeto vm.product.
       * Es necesario crear la propiedad `inCart` e iniciarlos a todos en 0
       */

      if (
        vm.product &&
        vm.product.packDinamico &&
        vm.product.packDinamico[0].productos &&
        vm.product.packDinamico[0].productos.length
      ) {
        vm.product.packDinamico[0].productos.forEach(function (product) {
          product.inCart = 0;
        });
      } else {
        console.error('El pack no tiene productos...');
      }
    }

    /**
     * Agregar un pack al carro.
     */
    function addPackToCart() {
      if (vm.inCartQuantity !== vm.maxProducts) {
        vm.showUserMessage = true;
      } else {
        vm.busy = true;
        vm.showUserMessage = false;

        var item = vm.product.items[0].portion;
        var itemsPack = vm.product.packDinamico[0].productos.filter(function (product) {
          return product.inCart > 0;
        });

        // Necesitamos que el precio esté en la porción del producto para poder enviar las analíticas
        item.price = vm.product.items[0].unit_price;

        // mapeamos propiedades extras para analiticas
        item.title = vm.product.title;
        item.subtitle = vm.product.subtitle;
        item.imageUrl = vm.product.pictures_web[0];

        CartService.addToCart(item, itemsPack, 'product-pack-n').then(
          function (value) {
            GlobalService.cart = value;
            $state.go('home');
          },
          function (reason) {
            vm.busy = false;
            console.error(reason);
          }
        );
      }
    }

    /**
     * Agregar un producto al "carro" del pack.
     * Se suma uno (1) a la cantidad de productos en el carro para ese producto (product.inCart) y la cantidad de
     * productos en general (vm.inCartQuantity).
     * @param product {object} Objeto del producto del pack
     */
    function addProductToCart(product) {
      if (vm.inCartQuantity < vm.maxProducts) {
        product.inCart++;
        vm.inCartQuantity++;
      } else {
        console.error(
          'No se agregó el producto debido a que ha alcanzado el número máximo de productos que se ' +
            'pueden agregar'
        );
      }
    }

    /**
     * Quitar un producto del "carro" del pack.
     * Se resta uno (1) a la cantidad de productos en el carro para ese producto (product.inCart) y la cantidad de
     * productos en general (vm.inCartQuantity).
     * @param product {object} Objeto del producto del pack
     */
    function removeProductFromCart(product) {
      if (product.inCart) {
        product.inCart = product.inCart - 1;
        vm.inCartQuantity = vm.inCartQuantity - 1;
      } else {
        console.error('No se quitó el producto debido a que no hay productos para quitar');
      }
    }
  }
})();
