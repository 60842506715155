(function () {
  'use strict';

  angular.module('directives.scrollEvent').directive('scrollEvent', ScrollEvent);

  function ScrollEvent($rootScope, $window) {
    return {
      link: function () {
        $window.onscroll = function () {
          $rootScope.$broadcast('scrollEvent');
        };
      },
    };
  }
})();
