(function () {
  'use strict';

  angular.module('services.analytics').service('AnalyticsService', AnalyticsService);

  function AnalyticsService(
    $cookies,
    $location,
    config,
    dataLayer,
    fbq,
    ga,
    gtag,
    iDevice,
    mixpanel
  ) {
    // Cadena de caracteres comunes. Google Analytics tiene muchos strings que se repiten.
    var EC = 'ec',
      EVENT = 'event',
      REQUIRE = 'require',
      SEND = 'send',
      TRACK = 'track';

    /**
     * Eventos de Mixpanel
     * @type {object}
     */
    var mixpanelEvents = {
      ADD_PROMO_CODE: 'add-promo-code',
      ADD_TO_CART: 'add-to-cart',
      CLICK: 'click',
      CLICK_HOME_CATEGORY: 'click-home-category',
      CREATE_ADDRESS: 'create-address',
      DELETE_ADDRESS: 'delete-address',
      EDIT_PASSWORD: 'edit-password',
      EDIT_PROFILE: 'edit-profile',
      EDIT_ADDRESS: 'edit-address',
      INSTALL_APP: 'install-app',
      LOGIN: 'login',
      LOGOUT: 'logout',
      OPEN_MODAL: 'open-modal',
      PAGE_VIEW: 'page-view',
      PURCHASE_ATTEMPT: 'purchase-attempt',
      PURCHASE_FAILED: 'purchase-failed',
      PURCHASE_SUCCEED: 'purchase-succeed',
      REMOVE_FROM_CART: 'remove-from-cart',
      SCROLL: 'scroll',
      SIGN_UP: 'sign-up',
      SWIPE: 'swipe',
      VISIT_ECOMMERCE: 'visit-ecommerce',
    };

    /**
     * Eventos predeterminados de Facebook pixel
     * @see https://www.facebook.com/business/help/402791146561655
     * @see https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
     * @type {object}
     */
    var fbqEvents = {
      ADD_PAYMENT_INFO: 'AddPaymentInfo',
      ADD_TO_CART: 'AddToCart',
      COMPLETE_REGISTRATION: 'CompleteRegistration',
      INITIATE_CHECKOUT: 'InitiateCheckout',
      PURCHASE: 'Purchase',
      VIEW_CONTENT: 'ViewContent',
    };

    /**
     * Tipos de datos del comercio electrónico mejorado de Google analytics
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
     * @type {object}
     */
    var gaEcDataType = {
      IMPRESSION: ':addImpression',
      PRODUCT: ':addProduct',
      ACTION: ':setAction',
    };

    /**
     * Categoría de eventos de Google analytics
     * @type {object}
     */
    var gaEventCategory = {
      ADDRESS: 'Address',
      LANDING_CATEGORY: 'Categorias Home',
      CATEGORY: 'Category',
      CHECKOUT: 'Checkout',
      LOGOUT: 'Logout',
      PASSWORD: 'Password',
      PRODUCT: 'Product',
      PROFILE: 'Profile',
      PURCHASE: 'Purchase',
      SIGN_IN: 'Sign In',
      SIGN_UP: 'Sign Up',
    };

    /**
     * Acciones de eventos de Google analytics
     * @type {object}
     */
    var gaEventActions = {
      ADD: 'add',
      ADD_TO_CART: 'add to cart',
      ATTEMPT: 'attempt',
      CHECKOUT: 'checkout',
      CLICK: 'click',
      CLICK_HOME_CATEGORY: 'click home category',
      CREATE: 'create',
      DELETE: 'delete',
      DETAIL: 'detail',
      EDIT: 'edit',
      FAILED: 'failed',
      IMPRESS: 'impress',
      INIT: 'init',
      PURCHASE: 'purchase',
      REMOVE: 'remove',
      REMOVE_FROM_CART: 'remove from cart',
      SUCCEED: 'succeed',
      VIEW: 'view',
    };

    /**
     * Eventos predeterminados de Google tag manager
     * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=es#default_google_analytics_events
     * @type {object}
     */
    var gtagEvents = {
      ADD_PAYMENT_INFO: 'add_payment_info',
      ADD_TO_CART: 'add_to_cart',
      BEGIN_CHECKOUT: 'begin_checkout',
      CHECKOUT_PROGRESS: 'checkout_progress',
      LOGIN: 'login',
      LOGOUT: 'logout',
      PURCHASE: 'purchase',
      REMOVE_FROM_CART: 'remove_from_cart',
      SIGN_UP: 'sign_up',
      VIEW_ITEM: 'view_item',
    };

    ga(REQUIRE, EC);

    return {
      addPaymentMethod: addPaymentMethod,
      addPromoCode: addPromoCode,
      addToCart: addToCart,
      categoryView: categoryView,
      clickCategory: clickCategory,
      clickLandingCategory: clickLandingCategory,
      clickMenuArrow: clickMenuArrow,
      clickNextCheckoutStep: clickNextCheckoutStep,
      clickNextRegisterStep: clickNextRegisterStep,
      clickPrevCheckoutStep: clickPrevCheckoutStep,
      clickPrevRegisterStep: clickPrevRegisterStep,
      clickProduct: clickProduct,
      clickSpecialOrder: clickSpecialOrder,
      createAddress: createAddress,
      deleteAddress: deleteAddress,
      editAddress: editAddress,
      editPassword: editPassword,
      editProfile: editProfile,
      impressProduct: impressProduct,
      initChangePassword: initChangePassword,
      initCheckout: initCheckout,
      initConfigDelivery: initConfigDelivery,
      initCreateAddress: initCreateAddress,
      initEditAddress: initEditAddress,
      initEditProfile: initEditProfile,
      initLogin: initLogin,
      initPromoCode: initPromoCode,
      initRegister: initRegister,
      initSelectPaymentMethod: initSelectPaymentMethod,
      installApp: installApp,
      login: login,
      logout: logout,
      orderView: orderView,
      profileView: profileView,
      productView: productView,
      purchaseAttempt: purchaseAttempt,
      purchaseFailed: purchaseFailed,
      purchaseSucceed: purchaseSucceed,
      removeFromCart: removeFromCart,
      sectionScroll: sectionScroll,
      selectKindDelivery: selectKindDelivery,
      selectPaymentMethod: selectPaymentMethod,
      swipeMenu: swipeMenu,
      toggleRutReason: toggleRutReason,
      userRegister: userRegister,
      visitEcommerce: visitEcommerce,
    };

    function addPaymentMethod() {
      // Facebook píxel
      fbq(TRACK, fbqEvents.ADD_PAYMENT_INFO);

      // Google tag manager
      gtag(EVENT, gtagEvents.ADD_PAYMENT_INFO);
    }

    function addPromoCode(promoCode, promoCodeMessage) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.ADD_PROMO_CODE, {
        promo_code: promoCode,
        promo_code_message: promoCodeMessage,
      });
    }

    function addToCart(source, item, itemCount) {
      var data = getProductData(item, itemCount);

      // Facebook pixel
      fbq(TRACK, fbqEvents.ADD_TO_CART, {
        content_ids: [data.id],
        content_name: data.name,
        content_type: 'product',
        currency: 'CLP',
        value: data.price,
      });

      // Google analytics enhanced ecommerce
      // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=es-419#add-remove-cart
      ga(EC + gaEcDataType.PRODUCT, data);
      ga(EC + gaEcDataType.ACTION, gaEventActions.ADD);
      ga(SEND, EVENT, gaEventCategory.PRODUCT, gaEventActions.ADD_TO_CART, data.name);

      // Google tag manager
      gtag(EVENT, gtagEvents.ADD_TO_CART);

      // Mixpanel
      data.source = source;
      data.item_count = itemCount;
      mixpanel.track(mixpanelEvents.ADD_TO_CART, data);
    }

    function categoryView(category, afterLogin) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.PAGE_VIEW, {
        page: '/home',
        category_id: category.id,
        category_name: category.name,
        category_position: category.position,
        after_login: !!afterLogin,
      });
    }

    function clickCategory(category) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.CATEGORY, gaEventActions.CLICK, category.name);

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'category',
        category_id: category.id,
        category_name: category.name,
        category_position: category.position,
      });
    }

    function clickLandingCategory(categoryId, categoryName) {
      // Google analytics
      ga(
        SEND,
        EVENT,
        gaEventCategory.LANDING_CATEGORY,
        gaEventActions.CLICK_HOME_CATEGORY,
        categoryId
      );

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK_HOME_CATEGORY, {
        target: 'click-home-category',
        category_id: categoryId,
        category_name: categoryName,
      });
    }

    function clickMenuArrow(direction) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'menubar-arrow',
        direction: direction,
      });
    }

    function clickNextCheckoutStep(step) {
      // Google analytics enhanced ecommerce
      ga(EC + gaEcDataType.ACTION, gaEventActions.CHECKOUT, { step: step });
      ga(SEND, EVENT, gaEventCategory.CHECKOUT, gaEventActions.CLICK, 'next step');

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'next-step-checkout-button',
        go_to_step: step,
      });
    }

    function clickNextRegisterStep(step) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'next-step-register-button',
        go_to_step: step,
      });
    }

    function clickPrevCheckoutStep(step) {
      // Google analytics enhanced ecommerce
      ga(EC + gaEcDataType.ACTION, gaEventActions.CHECKOUT, { step: step });
      ga(SEND, EVENT, gaEventCategory.CHECKOUT, gaEventActions.CLICK, 'previous step');

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'prev-step-checkout-button',
        go_to_step: step,
      });
    }

    function clickPrevRegisterStep(step) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'prev-step-register-button',
        go_to_step: step,
      });
    }

    function clickProduct(product) {
      var data = getProductData(product);

      // Google analytics enhanced ecommerce
      // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=es-419#product-click
      ga(EC + gaEcDataType.PRODUCT, data);
      ga(EC + gaEcDataType.ACTION, gaEventActions.CLICK, { list: 'Catalog' });
      ga(SEND, EVENT, gaEventCategory.PRODUCT, gaEventActions.CLICK, data.name);

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'product',
        product_sku: data.id,
        product_name: data.name,
      });
    }

    function clickSpecialOrder() {
      var categoryName = 'Fiestas Patrias';

      // Google analytics
      ga(SEND, EVENT, gaEventCategory.CATEGORY, gaEventActions.CLICK, categoryName);

      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'category',
        category_id: 0,
        category_name: categoryName,
        category_position: 0,
      });
    }

    function createAddress(address) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.ADDRESS, gaEventActions.CREATE);

      // Mixpanel
      mixpanel.track(mixpanelEvents.CREATE_ADDRESS, {
        address_id: address.id,
        address_name: address.name,
      });
    }

    function deleteAddress(address) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.ADDRESS, gaEventActions.DELETE);

      // Mixpanel
      mixpanel.track(mixpanelEvents.DELETE_ADDRESS, {
        address_id: address.id,
        address_name: address.name,
      });
    }

    function editAddress(address) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.ADDRESS, gaEventActions.EDIT);

      // Mixpanel
      mixpanel.track(mixpanelEvents.EDIT_ADDRESS, {
        address_id: address.id,
        address_name: address.name,
      });
    }

    function editPassword() {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.PASSWORD, gaEventActions.EDIT);

      // Mixpanel
      mixpanel.track(mixpanelEvents.EDIT_PASSWORD);
    }

    function editProfile() {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.PROFILE, gaEventActions.EDIT);

      // Mixpanel
      mixpanel.track(mixpanelEvents.EDIT_PROFILE);
    }

    function impressProduct(product) {
      // Google analytics
      // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=es-419#product-impression
      ga(EC + gaEcDataType.IMPRESSION, {
        id: product.items[0].portion.sku,
        name: product.title + ' ' + product.subtitle,
        category: product.categoryName + '/' + product.sectionName,
        list: 'Catalog',
        position: product.position,
      });
      ga(SEND, EVENT, gaEventCategory.PRODUCT, gaEventActions.IMPRESS);
    }

    function initChangePassword() {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'password-edition',
        source: 'user-profile',
      });
    }

    function initCheckout(source, items) {
      // Facebook pixel
      fbq(TRACK, fbqEvents.INITIATE_CHECKOUT, {
        num_items: items.length,
      });

      // Google analytics enhanced ecommerce
      items.forEach((item) => {
        ga(EC + gaEcDataType.PRODUCT, {
          id: item.sku,
          name: `${item.nombre} ${item.descripcion}`,
          price: item.precio,
          quantity: item.cantidad,
        });
      });
      ga(EC + gaEcDataType.ACTION, gaEventActions.CHECKOUT, { step: 1 });
      ga(SEND, EVENT, gaEventCategory.CHECKOUT, gaEventActions.INIT);

      // Google tag manager
      gtag(EVENT, gtagEvents.BEGIN_CHECKOUT);

      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'checkout',
        source: source,
        num_items: items.length,
      });
    }

    function initConfigDelivery(source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'config-delivery',
        source: source,
      });
    }

    function initCreateAddress(source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'address',
        source: source,
      });
    }

    function initEditAddress(address, source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'address',
        source: source,
        address_id: address.id,
        address_name: address.name,
      });
    }

    function initEditProfile() {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'user-data-edition',
        source: 'user-profile',
      });
    }

    function initLogin(source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'login',
        source: source,
      });
    }

    function initRegister() {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'sign-up',
        source: 'login-modal',
      });
    }

    function initPromoCode(source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'promo-code',
        source: source,
      });
    }

    function initSelectPaymentMethod() {
      // Mixpanel
      mixpanel.track(mixpanelEvents.OPEN_MODAL, {
        target: 'payment-methods',
      });
    }

    function installApp() {
      // Mixpanel
      mixpanel.track(mixpanelEvents.INSTALL_APP);
    }

    function login(user) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.SIGN_IN, gaEventActions.CLICK);

      // Google tag manager
      gtag(EVENT, gtagEvents.LOGIN);

      // Mixpanel
      identifyUser(user);
      mixpanel.people.increment('Count of logins');
      mixpanel.register({ user_logged_in: true });
      mixpanel.track(mixpanelEvents.LOGIN);
    }

    function logout() {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.LOGOUT, gaEventActions.CLICK);

      // Google tag manager
      gtag(EVENT, gtagEvents.LOGOUT);

      // Mixpanel
      mixpanel.track(mixpanelEvents.LOGOUT);
      mixpanel.reset();
      mixpanel.register({ user_logged_in: false });
    }

    function orderView(order) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'order',
        order_id: order.id,
      });
    }

    function profileView(page) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.PAGE_VIEW, {
        page: '/usuario' + page,
      });
    }

    function productView(product, store) {
      var data = getProductData(product, 0);

      // Facebook pixel
      fbq(TRACK, fbqEvents.VIEW_CONTENT, {
        content_ids: [data.sku],
        content_type: 'product',
        content_name: data.name,
        currency: 'CLP',
        value: data.price,
      });

      // Google analytics enhanced ecommerce
      // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=es-419#product-detail-view
      ga(EC + gaEcDataType.PRODUCT, data);
      ga(EC + gaEcDataType.ACTION, gaEventActions.DETAIL);
      ga(SEND, EVENT, gaEventCategory.PRODUCT, gaEventActions.VIEW, data.name);

      // Google tag manager
      gtag(EVENT, gtagEvents.VIEW_ITEM, {
        ecomm_prodid: data.id,
        sku: data.sku,
        name: data.name,
        price: data.price,
        value: data.price,
        url: data.url,
        image_url: data.imageUrl,
      });

      // Mixpanel
      mixpanel.track(mixpanelEvents.PAGE_VIEW, {
        page: '/producto',
        store_id: store.id,
        store_name: store.name,
        product_id: product.sku,
        product_name: data.name,
        product_price: data.price,
        stock: product.items[0].stock,
      });
    }

    function purchaseAttempt(paymentMethod) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.PURCHASE, gaEventActions.ATTEMPT);

      // Mixpanel
      var data = {
        payment_name: getPaymentMethodName(paymentMethod),
      };
      mixpanel.track(mixpanelEvents.PURCHASE_ATTEMPT, data);
    }

    function purchaseFailed(reason, paymentMethod) {
      // Google analytics
      ga(SEND, EVENT, gaEventCategory.PURCHASE, gaEventActions.FAILED);

      // Mixpanel
      var data = {
        server_reason: JSON.stringify(reason),
        payment_name: getPaymentMethodName(paymentMethod),
      };
      mixpanel.track(mixpanelEvents.PURCHASE_FAILED, data);
    }

    function purchaseSucceed(order) {
      // Verificar en las cookies si la compra realizada con el order.id ya fue enviada a las analíticas, debido a
      // que el evento estaba siendo enviado múltiples veces si el usuario refrescaba la vista de celebración.
      var lastOrderId = $cookies.get('purchase');
      if (!lastOrderId || parseInt(lastOrderId) < order.id) {
        // Google tag manager
        // @see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases
        const products = order.items.map((item) => ({
          name: item.portion.dish_name,
          id: item.portion_sku,
          quantity: item.quantity,
          price: item.unit_price,
        }));
        const transactionDetails = {
          ecommerceGA: {
            purchase: {
              actionField: {
                id: order.id,
                affiliation: 'Online Store',
                revenue: order.paid_amount,
              },
              products: products,
            },
          },
        };
        dataLayer.push({ ecommerceGA: null });
        dataLayer.push(transactionDetails);

        // GA4 UPDATE
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: 'CLP',
            transaction_id: order.id.toString(),
            value: order.paid_amount,
            items: order.items.map((item) => {
              return {
                item_id: item.portion.sku,
                item_name: item.portion.dish_name,
                price: item.unit_price,
                quantity: item.quantity,
              };
            }),
          },
        });

        // Facebook pixel
        let pixelContent = [];
        let numItems = 0;

        order.items.forEach((item) => {
          pixelContent.push({
            id: item.portion_sku,
            quantity: item.quantity,
            item_price: item.unit_price,
          });
          numItems += item.quantity;
        });

        fbq('track', 'Purchase', {
          currency: 'CLP',
          value: order.paid_amount,
          content_type: 'product',
          contents: pixelContent,
          num_items: numItems,
        });

        // Almacenar en las cookies que el evento fue enviado
        var expires = new Date();
        expires.setFullYear(expires.getFullYear() + 3);
        $cookies.put('purchase', order.id, { domain: config.DOMAIN, expires: expires });
      }
    }

    function removeFromCart(source, product, itemCount) {
      var data = getProductData(product, itemCount);

      // Google analytics
      // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=es-419#add-remove-cart
      ga(EC + gaEcDataType.PRODUCT, data);
      ga(EC + gaEcDataType.ACTION, gaEventActions.REMOVE);
      ga(SEND, EVENT, gaEventCategory.PRODUCT, gaEventActions.REMOVE_FROM_CART, data.name);

      // Google tag manager
      gtag(EVENT, gtagEvents.REMOVE_FROM_CART);

      // Mixpanel
      data.source = source;
      data.item_count = itemCount;
      mixpanel.track(mixpanelEvents.REMOVE_FROM_CART, data);
    }

    function sectionScroll(percent, category, section, position, sections_total) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.SCROLL, {
        page: 'home',
        percent: percent,
        category: category.name,
        section: section.name,
        category_section: category.name + '-' + section.name,
        position: position,
        sections_total: sections_total,
      });
    }

    function selectKindDelivery(kind) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'kind-delivery-button',
        source: 'config-delivery-modal',
        delivery: kind === 0,
      });
    }

    function selectPaymentMethod(payment, source) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'payment-method-button',
        source: source,
        payment_id: payment.id,
        payment_title: getPaymentMethodName(payment),
      });
    }

    function swipeMenu(direction) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.SWIPE, {
        target: 'menubar',
        direction: direction,
      });
    }

    function toggleRutReason(open) {
      // Mixpanel
      mixpanel.track(mixpanelEvents.CLICK, {
        target: 'rut-reason-trigger',
        open: open,
      });
    }

    function userRegister(userId) {
      console.log(userId);
      // Facebook pixel
      fbq(TRACK, fbqEvents.COMPLETE_REGISTRATION, {
        status: true,
        value: 1,
        currency: 'CLP',
      });

      // Google analytics
      ga(SEND, EVENT, gaEventCategory.SIGN_UP, gaEventActions.CLICK);

      // Google tag manager
      gtag(EVENT, gtagEvents.SIGN_UP);

      // Mixpanel
      mixpanel.alias(userId);
      mixpanel.people.set({ 'First Seen': new Date() });
      mixpanel.track(mixpanelEvents.SIGN_UP);
    }

    function visitEcommerce(user, pwa) {
      // Identificar al usuario si es que está autenticado
      if (user) {
        identifyUser(user);
      }

      // Determinar si el usuario está ingresando desde la PWA o no
      // Además, registrar si el usuario está autenticado... o no
      mixpanel.register({
        app: pwa === '/pwa' ? 'Proggressive Web App' : 'Web App',
        user_logged_in: !!user,
      });

      mixpanel.track(mixpanelEvents.VISIT_ECOMMERCE, {
        // Enviar analíticas si el dispositivo es un iOS
        'iDevice.js': iDevice || 'Otro',
      });
    }

    /**
     * Indicarle a mixpanel que todos los eventos estarán asociados al usuario
     * @param {object} user - Usuario a identificar
     */
    function identifyUser(user) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $first_name: user.name,
        $last_name: user.lastname,
        $email: user.email,
        $phone: user.phone_number,
        $last_login: new Date(),
      });
    }

    /**
     * Obtener el nombre del método de pago.
     * La idea es estandarizar los nombres que vienen de distintas fuentes
     * @param {object} paymentMethod - Objeto del método de pago
     * @returns {string}
     */
    function getPaymentMethodName(paymentMethod) {
      var name = '';
      var title = paymentMethod ? paymentMethod.title : '';

      if (!paymentMethod || !title || title === 'Credito') {
        name = 'Créditos';
      } else if (
        title === '---' ||
        title === 'MasterCard' ||
        title === 'Visa' ||
        title === 'AmericanExpress'
      ) {
        name = 'Webpay One Click';
      } else if (title === 'Webpay' || title === 'Transbank' || title === 'WebPay Plus') {
        name = 'Webpay Plus';
      } else if (title === 'Paga en tienda') {
        name = 'Pago en tienda';
      } else if (title === 'Paga contra entrega') {
        name = 'Pago contra entrega';
      } else {
        name = paymentMethod.title;
      }

      return name;
    }

    /**
     * Obtener información del producto. La necesidad de la función nació cuando el producto puede venir
     * en formatos distintos.
     * @param {object} product Producto del cual se sacará la información
     * @param {number} quantity Cantidad de productos en cuestión
     */
    function getProductData(product, quantity) {
      var data = {};

      // Catálogo
      if (product.titulo) {
        data = {
          id: product.sku,
          name: `${product.titulo} ${product.subtitulo}`,
          price: product.precioUnitario,
          stock: product.stock,
          category: `${product.categoryName}/${product.sectionName}`,
        };
      }

      // carro
      else if (product.nombre) {
        data = {
          id: product.sku,
          name: `${product.nombre} ${product.descripcion}`,
          price: product.precio,
        };
      }

      //  detalle producto | addToCart
      else if (product.dish_name) {
        data = {
          id: product.dish_id,
          name: `${product.title} ${product.subtitle}`,
          sku: product.sku,
          price: product.price,
          url: $location.absUrl(),
          imageUrl: product.imageUrl,
        };
      }

      // Detalle producto
      else if (product.items && product.items.length) {
        data = {
          id: product.id,
          sku: product.items[0].portion.sku,
          name: `${product.title} ${product.subtitle}`,
          price: product.items[0].unit_price,
          url: $location.absUrl(),
          imageUrl: product.pictures_web[0],
        };
      } else {
        console.warn('No fue posible obtener los datos del producto para enviar analíticas');
      }

      // La cantidad de productos es opcional
      if (quantity) {
        data.quantity = quantity;
      }

      return data;
    }
  }
})();
