(function () {
  'use strict';

  angular.module('shared.devRibbon').component('devRibbonComponent', {
    templateUrl: 'shared/dev-ribbon/dev-ribbon.template.html',
    controller: DevRibbonController,
    controllerAs: 'vm',
  });

  function DevRibbonController($timeout, config) {
    var vm = this;

    vm.show = false;

    vm.$onInit = onInit;
    vm.hideStagingRibbon = hideStagingRibbon;

    /**
     * Mostrar el ribbon únicamente en el ambiente de desarrollo
     */
    function onInit() {
      vm.show = config.ENV !== 'prod';
    }

    /**
     * Ocultar momentáneamente el ribbon
     */
    function hideStagingRibbon() {
      vm.show = false;
      $timeout(function () {
        vm.show = true;
      }, 10000);
    }
  }
})();
