(function () {
  'use strict';

  angular.module('app').controller('HomeController', HomeController);

  function HomeController(
    AnalyticsService,
    $document,
    $rootScope,
    $scope,
    $state,
    $location,
    $stateParams,
    $timeout,
    $transitions,
    $window,
    ContentfulService,
    ProductService,
    CatalogService,
    GlobalService,
    MetaTagService,
    ScrollService,
    StoreService,
    HomeNavigationService,
    Slug,
    ZendeskWidget,
    UserService
  ) {
    const vm = this;

    vm.hasNoItems = false;
    vm.busy = true;
    vm.cart = null;
    vm.sections = [];
    vm.noDishes = false;

    vm.showLanding = false;
    vm.landingData = [];

    vm.showNavbarMessage = false;
    vm.navbarMessageType = '';
    vm.navbarMessageTitle = '';
    vm.navbarMessage = '';

    vm.showNavbarPromo = false;
    vm.navbarPromoTitle = '';
    vm.navbarPromoMessage = '';

    vm.showFooterMessage = false;

    vm.stickyTopOffset = 0;

    $scope.global = GlobalService;
    $scope.sections = [];

    // Variables para dejar de escuchar los cambios en el rootScope
    let unregisterFooterMessage = () => undefined;
    let unregisterUserConfig = () => undefined;

    // Funciones enlazadas al controlador
    vm.$onInit = onInit;
    vm.changeCategory = changeCategory;
    vm.category = null;
    vm.categorySectionsQuantity = null;

    // flechas scroll botones subcategorias
    vm.scrollPositionHorizontal = 0;

    // Variables enlazadas al scope
    $scope.htmlSections = [];
    $scope.visibleSections = 0;

    // La función de firstLoad es, si la primera categoría en cargar está vacía, entonces seleccionar la segunda.
    // Debido al landing, no diferenciaremos la primera carga de las demás. Así que lo inicializaremos en false.
    $scope.firstLoad = false;

    // Funciones enlazadas al scope
    $scope.calculateStickyOffset = calculateStickyOffset;
    $scope.dismissNavbarMessage = dismissNavbarMessage;
    $scope.dismissNavbarPromo = dismissNavbarPromo;
    $scope.loadProducts = loadProducts;
    $scope.showNavbarMessage = showNavbarMessage;
    $scope.updateMetaTags = updateMetaTags;
    $scope.verifyNavbarPromo = verifyNavbarPromo;
    $scope.scrollToSubcategoryFromBreadcrumbs = scrollToSubcategoryFromBreadcrumbs;
    $scope.goHomeFromBreadcrumbs = goHomeFromBreadcrumbs;
    $scope.scrollTop = scrollTop;
    $scope.scrollLeftArrow = scrollLeftArrow;
    $scope.scrollRightArrow = scrollRightArrow;

    // Variable para que llama el mensaje de productos frescos
    vm.consumoInmediatoMessage = false;

    $rootScope.$on('activeCategoryUpdated', $scope.loadProducts);
    $rootScope.$on('catalogUpdated', $scope.loadProducts);
    $rootScope.$on('dismissNavbarMessage', $scope.dismissNavbarMessage);
    $rootScope.$on('dismissNavbarPromo', $scope.dismissNavbarPromo);
    $rootScope.$on('showNavbarMessage', $scope.showNavbarMessage);

    function onInit() {
      $scope.calculateStickyOffset();

      // Si existe una categoría activa, cargar los productos
      if (GlobalService.activeCategory) {
        $scope.firstLoad = false;
        $scope.loadProducts();
      }

      // Si cambia el carro, cambiar el de la vista
      $scope.$watch('global.cart', (newVal) => {
        vm.cart = newVal;
      });

      // Mensaje en el footer
      vm.showFooterMessage = !!GlobalService.footerMessage;
      unregisterFooterMessage = $scope.$watch('global.showFooterMessage', (newVal, oldVal) => {
        vm.showFooterMessage = newVal;
        if (oldVal && !newVal) {
          unregisterFooterMessage();
        }
      });

      // Si el usuario tiene la configuración de retiro, se visualiza el mensaje de producto de consumo inmediato
      $scope.$watch('global.configDelivery.kind', () => {
        vm.consumoInmediatoMessage = $scope.global.configDelivery.kind === 1;
      });

      // Hacer scroll de forma automatica hacía una seccion/subcategoria si entramos directamente desde la url
      $scope.$watch('global.productCategories', (newVal) => {
        if (
          newVal &&
          newVal.length > 0 &&
          vm.sections.length > 0 &&
          $location.url().includes('#subcategory')
        ) {
          HomeNavigationService.scrollToSubcategory($location.hash().split('-')[1]);
        }
      });

      // Verificar si se debe mostrar el modal de bienvenida.
      unregisterUserConfig = $scope.$watch('global.userConfig', (newVal) => {
        if (newVal) {
          if (newVal.default) {
            $rootScope.$broadcast('openConfigDeliveryModal', { state: 'default-config-delivery' });
            console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
          }
          $scope.verifyNavbarPromo();
          unregisterUserConfig();
        }
      });

      $scope.$watch('global.user', $scope.verifyNavbarPromo);

      if (
        $state.current.name === 'home.onboarding' ||
        $state.current.name === 'home.forkPiggyBank'
      ) {
        // La autenticidad del usuario se verificará luego de cargar las tiendas.
        StoreService.getStores().then(() => {
          // Flujo onboarding.
          // Se verificará qué modal se mostrará, dependiendo si el usuario está autenticado o no.
          if ($state.current.name === 'home.onboarding' && $stateParams.addressId) {
            // Si el usuario no está autenticado, mostrar modal de inicio de sesión.
            // Si el usuario está autenticado, mostrar modal de dirección.
            const openModal = GlobalService.user ? 'openAddressModal' : 'openLoginModal';
            $rootScope.$broadcast(openModal, { source: 'onboarding' });
            console.info('Broadcast: ¡Abrir modal que corresponde al flujo onboarding!');
          }
          // Flujo alcancia-fork
          // Se mostrará el modal de inicio de sesión, si es que el usuario no está autenticado
          else if ($state.current.name === 'home.forkPiggyBank') {
            if (!GlobalService.user) {
              $rootScope.$broadcast('openLoginModal', { source: 'fork-piggy-bank' });
              console.info('Broadcast: ¡Abrir modal de inicio de sesión!');
            } else {
              $state.go('user.piggyBank');
            }
          }
        });
      } else if ($state.current.name === 'home.help') {
        ZendeskWidget.activate();
      }
    }

    /**
     * Manejar cuando el usuario quiera navegar con los botones del navegador
     */
    $transitions.onSuccess({ from: 'home.**', to: 'home.**' }, (transition) => {
      const { categoryId, categoryName } = transition.router.globals.params;
      if (categoryId && categoryName && parseInt(categoryId) !== GlobalService.activeCategory.id) {
        vm.changeCategory(categoryId, categoryName);
      }
    });

    function calculateStickyOffset() {
      const fixedTopElement = $document[0].getElementById('fixed-top');
      if (fixedTopElement) {
        vm.stickyTopOffset = fixedTopElement.getBoundingClientRect().height;
        $timeout(() => {
          $rootScope.$broadcast('updateAlwaysVisible');
        });
      }
    }

    /**
     * Cambiar categoría desde algún item del catálogo
     * @param categoryId
     */
    function changeCategory(categoryId, categoryName) {
      $rootScope.$broadcast('changeCategory', {
        categoryId: categoryId,
        categoryName: categoryName,
      });
      console.info('Broadcast: ¡Cambiar categoría!');
    }

    /**
     * Ocultar el mensaje del navbar
     */
    function dismissNavbarMessage() {
      vm.showNavbarMessage = false;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    /**
     * Ocultar la promoción del navbar
     */
    function dismissNavbarPromo() {
      vm.showNavbarPromo = false;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    /**
     * Cargar productos en el catálogo
     */
    function loadProducts() {
      // Mostrar el landing si la categoría activa es la 34 (Destacados)
      if (GlobalService.activeCategory.id === 34) {
        vm.showLanding = true;

        // Nuevo landing V2 (solo Web)
        ContentfulService.getLandingData().then((value) => {
          vm.landingData = value;
          vm.busy = false;
        });

        return;
      } else {
        vm.showLanding = false;
      }

      // Obtener las secciones de la categoría
      vm.categorySectionsQuantity = GlobalService.activeCategory.secciones.length;
      const categoryId = GlobalService.activeCategory.id;
      const activeCategory = GlobalService.productCategories.find(
        (category) => category.id === categoryId
      );

      vm.hasNoItems =  areAllItemsZero(activeCategory);

      $scope.activeCategoryName = activeCategory.nombre;
      const sections = activeCategory.secciones;

      // Se actualizarán los productos sólo si hubo cambios en la categoría actual
      const shouldUpdate = JSON.stringify(sections) !== JSON.stringify($scope.sections);

      if (shouldUpdate) {
        vm.busy = true;

        if (sections && sections.length) {
          // Guardar el arreglo original de secciones para futuras referencias
          $scope.sections = angular.copy(sections);

          sections.forEach(function (section) {
            // Verificar si la sección tiene al menos un producto de consumo inmediato
            section.consumoInmediato = section.items.some((item) => item.consumoInmediato);
            // Enumerar la posición en la que se encuentra cada producto
            // Además, el producto debe contener la información de la sección y la categoría en la que se encuentra
            section.items.forEach((item, index) => {
              item.position = index;
              item.sectionName = section.nombre;
              item.categoryName = activeCategory.nombre;
            });
          });

          vm.sections = sections;
          vm.busy = false;
        } else {
          vm.noDishes = true;
          vm.sections = [];
        }

        $rootScope.$broadcast('updateAlwaysVisible');
        console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
        $scope.updateMetaTags();
      }
    }

    /**
     * Mostrar el mensaje del navbar
     * @param {*} event
     * @param {*} opt
     */
    function showNavbarMessage(event, opt) {
      const { type, title, message } = opt;
      vm.navbarMessageType = type;
      vm.navbarMessageTitle = title;
      vm.navbarMessage = message;

      vm.showNavbarMessage = true;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    /**
     * Actualizar meta tags
     */
    function updateMetaTags() {
      if ($stateParams && $stateParams.categoryId) {
        MetaTagService.updateMetaTags('home.category', GlobalService.activeCategory);
      } else {
        MetaTagService.updateMetaTags('home');
      }
    }

    /**
     * Verificar si hay que mostrar la promoción del navbar
     */
    function verifyNavbarPromo() {
      // Aquí debería ir la lógica para mostrar la promoción del navbar
      vm.showNavbarPromo = false;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    function goHomeFromBreadcrumbs() {
      $('html').animate({ scrollTop: 0 }, 0);
      $rootScope.$broadcast('changeCategory', {
        categoryId: 34,
        categoryName: 'destacados',
      });
      // Actualizar metatags
      MetaTagService.updateMetaTags('home');
    }

    function scrollToSubcategoryFromBreadcrumbs(index) {
      HomeNavigationService.scrollToSubcategory(index);
    }

    function scrollTop(duration) {
      $('html,body').animate({ scrollTop: 0 }, duration);
    }

    $(document).on('touchstart touchend touchcancel', function () {
      if (
        $('.subcategories-nav') &&
        window.innerWidth < 577 &&
        $location.url().includes('categoria') &&
        !$location.url().includes('categoria/34')
      ) {
        vm.scrollPositionHorizontal = $('.subcategories-nav').scrollLeft();
        let navOffsetWidth = document.querySelector('.subcategories-nav').offsetWidth;
        let totalScrollWidth = document.querySelector('.subcategories-nav').scrollWidth;

        if (vm.scrollPositionHorizontal > 20) {
          $('#prev-nav-btn').addClass('show-arrow');
        }
        if (vm.scrollPositionHorizontal < 20) {
          $('#prev-nav-btn').removeClass('show-arrow');
        }
        if (vm.scrollPositionHorizontal + navOffsetWidth < totalScrollWidth - 30) {
          $('#next-nav-btn').addClass('show-arrow');
        }
        if (vm.scrollPositionHorizontal + navOffsetWidth > totalScrollWidth - 30) {
          $('#next-nav-btn').removeClass('show-arrow');
        }
      }
    });

    function scrollLeftArrow() {
      let nav = document.querySelector('.subcategories-nav');
      if (nav.scrollLeft <= 120) {
        $('#prev-nav-btn').removeClass('show-arrow');
      }
      if (nav.scrollLeft + nav.offsetWidth < nav.scrollWidth) {
        $('#next-nav-btn').addClass('show-arrow');
      }
      nav.scrollLeft -= 120;
    }

    function scrollRightArrow() {
      let nav = document.querySelector('.subcategories-nav');
      if (nav.scrollLeft <= 15) {
        $('#prev-nav-btn').addClass('show-arrow');
      }
      if (nav.scrollLeft + nav.offsetWidth >= nav.scrollWidth - 120) {
        $('#next-nav-btn').removeClass('show-arrow');
      }
      nav.scrollLeft += 120;
    }

    function areAllItemsZero(activeCategory) {
      if (!activeCategory) {
        return false; // No hay categoría activa, así que retornamos false
      }
    
      return activeCategory.secciones.every(
        seccion => seccion.items.length === 0
      );
    }
  }
})();
