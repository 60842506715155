(function () {
  'use strict';

  angular.module('factories.formExceptionHandler').factory('FormExceptionHandlerFactory', [
    function () {
      return {
        getListErrors: getListErrors,
      };

      /**
       * Captura los errores devueltos por la particular forma de survalley para devolver los errores del servidor
       * @param {object} err Objeto de respuesta del servidor
       */
      function getListErrors(err) {
        var errors = {};

        if (angular.isDefined(err.list_error)) {
          var listError = err.list_error;
          if (listError && listError.length) {
            angular.forEach(listError, function (value) {
              if (!listError.hasOwnProperty(value.field)) {
                var field = value.field;
                var message = value.message;
                errors[field] = message;
              }
            });
          } else {
            errors = {
              message: err.message,
            };
          }
        }

        return errors;
      }
    },
  ]);
})();
