(function () {
  'use strict';

  angular.module('shared.loginModal.loginOptions').component('loginOptionsComponent', {
    templateUrl: 'shared/login-modal/components/login-options/login-options.template.html',
    controller: LoginOptionsController,
    controllerAs: 'vm',
    bindings: {
      currentForm: '<',
      onChangeForm: '&',
    },
  });

  function LoginOptionsController() {
    var vm = this;

    vm.changeForm = changeForm;

    /**
     * Cambiar el formulario del modal de inicio de sesión
     * @param form {string}
     */
    function changeForm(form) {
      vm.onChangeForm({ form: form });
    }
  }
})();
