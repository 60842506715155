(function () {
  'use strict';

  angular.module('shared.forkLifeModal').component('forkLifeModalComponent', {
    templateUrl: 'shared/fork-life-modal/fork-life-modal.template.html',
    controller: ForkLifeModalController,
    controllerAs: 'vm',
  });

  function ForkLifeModalController($scope, $timeout, CookiesService, GlobalService) {
    var vm = this;
    $scope.global = GlobalService;

    var unregisterUser;

    vm.ready = false;
    vm.user = null;
    vm.$onInit = onInit;

    $scope.openForkLifeModal = openForkLifeModal;

    function onInit() {
      unregisterUser = $scope.$watch(
        'global.user',
        function (newValue) {
          if (newValue && (newValue.life_discount || newValue.life_free_delivery)) {
            var forkLifeCookie = CookiesService.getForkLifeModal(newValue);
            if (!forkLifeCookie) {
              vm.ready = true;
              vm.user = newValue;
              $timeout(openForkLifeModal);
              CookiesService.setForkLifeModal(true, newValue);
            }
          }
        },
        true
      );
    }

    function openForkLifeModal() {
      $('#fork-life-modal').modal('show');
    }
  }
})();
