(function () {
  'use strict';

  angular.module('shared.loginModal', [
    'shared.loginModal.forgotPasswordForm',
    'shared.loginModal.loginForm',
    'shared.loginModal.loginOptions',
    'shared.loginModal.registerForm',
  ]);
})();
