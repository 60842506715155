(function () {
  'use strict';

  angular
    .module('factories.httpInterceptor')
    .factory('HttpInterceptorFactory', HttpInterceptorFactory);

  function HttpInterceptorFactory($injector, $q) {
    return {
      request: function (config) {
        return config;
      },
      requestError: function (response) {
        // Inyectado de forma manual para evitar el problema de la dependencia circular
        var LogService = $injector.get('LogService');

        LogService.serviceError(response);
        return $q.reject(response);
      },
      response: function (response) {
        return $q.resolve(response);
      },
      responseError: function (response) {
        // Inyectado de forma manual para evitar el problema de la dependencia circular
        var LogService = $injector.get('LogService');

        LogService.serviceError(response);
        return $q.reject(response);
      },
    };
  }
})();
