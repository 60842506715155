(function () {
  'use strict';

  angular.module('shared.loginModal.loginForm').component('loginFormComponent', {
    templateUrl: 'shared/login-modal/components/login-form/login-form.template.html',
    controller: LoginFormController,
    controllerAs: 'vm',
    bindings: {
      onChangeForm: '&',
      onCloseLoginModal: '&',
      source: '<',
    },
  });

  function LoginFormController(
    $rootScope,
    $scope,
    config,
    ZendeskWidget,
    AnalyticsService,
    CookiesService,
    AddressService,
    CartService,
    LogService,
    GlobalService,
    OrderService,
    PaymentService,
    StoreService,
    UserService,
    BillingService
  ) {
    const vm = this;
    const CTRL_NAME = vm.constructor.name;

    const CHECKOUT = 'checkout',
      CONFIG_DELIVERY = 'config-delivery-modal',
      CONFIG_DELIVERY_WELCOME = 'config-delivery-modal-welcome',
      FORKPIGGYBANK = 'fork-piggy-bank',
      NAVBAR = 'navbar',
      ONBOARDING = 'onboarding';

    const LOGIN_EMPTY = {
      email: '',
      password: '',
    };
    vm.login = angular.copy(LOGIN_EMPTY);

    vm.busy = false;

    vm.$onInit = onInit;
    vm.changeForm = changeForm;
    vm.closeLoginModal = closeLoginModal;
    vm.loginByEmail = loginByEmail;

    // Configuración de entrega
    $scope.kind = 0;
    $scope.store = null;
    $scope.address = null;

    $scope.cartMatchesConfigDelivery = cartMatchesConfigDelivery;
    $scope.getUserAddresses = getUserAddresses;
    $scope.getUserCart = getUserCart;
    $scope.getUserConfig = getUserConfig;
    $scope.getUserCreditCards = getUserCreditCards;
    $scope.loginFailed = loginFailed;

    function onInit() {
      vm.login = angular.copy(LOGIN_EMPTY);
    }

    /**
     * En ocaciones, el carro del usuario no corresponde con la configuración de entrega seleccionada por el usuario.
     * Si el carro tiene costo de delivery, la configuración no debería ser retiro en tienda.
     * Si el carro no tiene costo de delivery, la configuración no debería ser delivery
     * @param cart
     * @param configDelivery
     * @returns {boolean} True si coinciden, False si no
     */
    function cartMatchesConfigDelivery(cart, configDelivery) {
      return (
        (cart.delivery_price > 0 && configDelivery.kind !== 0) ||
        (cart.delivery_price === 0 && configDelivery.kind !== 1)
      );
    }

    /**
     * Cambiar el formulario del modal de inicio de sesión
     * @param form {string}
     */
    function changeForm(form) {
      vm.onChangeForm({ form: form });
    }

    /**
     * Cerrar el modal de inicio de sesión
     * @param success {boolean}
     */
    function closeLoginModal(success) {
      vm.onCloseLoginModal({ success: success });
      vm.busy = false;
    }

    /**
     * Obtener la lista de direcciones del usuario
     */
    function getUserAddresses(configDeliveryDefault) {
      AddressService.getAddress().then(
        (value) => {
          GlobalService.addresses = value.data.addresses;
          console.info('Direcciones:', GlobalService.addresses);

          // Actualizar la configuración de entrega, si es que es por defecto
          if (configDeliveryDefault) {
            $scope.address =
              GlobalService.addresses.find(function (address) {
                return address.id === GlobalService.userConfig.address_id;
              }) || null;

            // La dirección guardada en la configuración de entrega podría no existir...
            if (!$scope.address && GlobalService.configDelivery.kind === 0) {
              $scope.kind = 1;
            }
            // La dirección guardada podría no pertenecer a la tienda guardada
            else if ($scope.address && $scope.address.store_id !== $scope.store.id) {
              $scope.kind = 1;
            }

            // Actualizar configuración de entrega
            GlobalService.configDelivery.kind = $scope.kind;
            GlobalService.configDelivery.address = $scope.address;
            GlobalService.configDelivery.store = $scope.store;
          }

          vm.closeLoginModal(true);
        },
        (reason) => {
          // Si el usuario no tiene direcciones, el servicio devuelve un status 400 -_-
          if (reason.data.code === 'NGC') {
            GlobalService.addresses = [];
            vm.closeLoginModal(true);
          } else {
            $scope.loginFailed();
          }
        }
      );
    }

    /**
     * Obtener carro del usuario, si es que su carro está vacío
     */
    function getUserCart() {
      // Si el usuario tiene elementos en el carro de compras, guardarlo
      if (GlobalService.cart.productos.length) {
        CartService.setCart(GlobalService.cart).then(
          function (value) {
            GlobalService.cart = value.data.response;
          },
          function () {
            $scope.loginFailed();
          }
        );
      }

      // Si no, cargar el carro de compras
      else {
        CartService.getCart(GlobalService.configDelivery.store.id).then((value) => {
          const cart = value.data.response;

          // Verificar que el carro coincide con la configuración de entrega
          if ($scope.cartMatchesConfigDelivery(cart, GlobalService.configDelivery)) {
            GlobalService.cart = cart;
            console.info('Carro de compras', GlobalService.cart);
          } else {
            CartService.setCart(cart).then((value) => {
              GlobalService.cart = value.data.response;
              console.info('Carro de compras:', GlobalService.cart);
            });
          }
        }, $scope.loginFailed);
      }
    }

    /**
     * Obtener configuración del usuario, si es que el usuario no ha modificado la configuración por defecto
     */
    function getUserConfig() {
      // Si el usuario tiene la configuración por defecto, cargar la del servidor
      if (GlobalService.userConfig.default) {
        UserService.getUserConfig().then(
          function (value) {
            let userConfig = value.data;

            StoreService.getStores().then((value) => {
              const stores = value;
              $scope.store = stores.find((store) => {
                return store.id === userConfig.store_id;
              });

              // La tienda guardada en la configuración de entrega podría no existir...
              if (!$scope.store) {
                $scope.kind = 1;
                $scope.store = stores[0];
              } else {
                $scope.kind = GlobalService.userConfig.delivery === 'DELIVER' ? 0 : 1;
              }
            });

            $scope.getUserCart();
            $scope.getUserAddresses(GlobalService.userConfig.default);
            $scope.getUserCreditCards();

            GlobalService.userConfig = userConfig;
            console.info('Configuración del usuario:', userConfig);
          },
          function () {
            $scope.loginFailed();
          }
        );
      }

      // Si el usuario no tiene la configuración por defecto, enviarla al servidor
      else {
        $scope.getUserCart();
        $scope.getUserAddresses();
        $scope.getUserCreditCards();

        UserService.setUserConfig(GlobalService.configDelivery).then((value) => {
          GlobalService.userConfig = value;
        });
      }
    }

    /**
     * Obtener tarjetas de crédito del usuario
     */
    function getUserCreditCards() {
      PaymentService.getCreditCards().then(
        function (value) {
          GlobalService.creditCards = value.data;

          if (GlobalService.creditCards && GlobalService.creditCards.length) {
            // Establecer la tarjeta de crédito como método de pago por defecto
            GlobalService.configDelivery.paymentMethod = GlobalService.creditCards[0];
          } else {
            // Establecer el método de pago por defecto
            GlobalService.configDelivery.paymentMethod = GlobalService.paymentMethods.find(
              function (paymentMethod) {
                return paymentMethod.id === GlobalService.userConfig.payment_method_id;
              }
            );
          }
        },
        function () {
          $scope.loginFailed();
        }
      );
    }

    /**
     * Autenticar al usuario con su correo y contraseñalog
     */
    function loginByEmail() {
      vm.busy = true;

      UserService.getUserLogin(vm.login).then(
        function (value) {
          GlobalService.user = value.data;
          console.info('Usuario:', GlobalService.user);

          CookiesService.setUser(value.data.auth_token);

          // El orden en el que se carga la información del usuario autenticado dependerá de la fuente desde
          // donde se ha llamado el modal de inicio de sesión.

          if (
            vm.source === NAVBAR ||
            vm.source === CHECKOUT ||
            vm.source === ONBOARDING ||
            vm.source === FORKPIGGYBANK ||
            vm.source === CONFIG_DELIVERY_WELCOME
          ) {
            $scope.getUserConfig();
          } else if (vm.source === CONFIG_DELIVERY) {
            $scope.getUserCart();
            $scope.getUserAddresses();
            $scope.getUserCreditCards();
          } else {
            const message =
              'Se está intentando iniciar sesión desde un origen desconocido: ' + vm.source;
            $scope.loginFailed();
            console.warn(message);
            LogService.unexpectedCase(CTRL_NAME, message);
          }

          // Enviar analíticas
          AnalyticsService.login(GlobalService.user);
          if (GlobalService.activeCategory && GlobalService.activeCategory.id) {
            AnalyticsService.categoryView(GlobalService.activeCategory, true);
          }

          // Identificarlo en Zendesk
          ZendeskWidget.identify({
            name: GlobalService.user.name,
            email: GlobalService.user.email,
          });

          LogService.info(
            'Inicio de sesión',
            'login-form.component.js',
            { userConfig: GlobalService.userConfig },
            value.data
          );
        },
        (reason) => {
          vm.busy = false;

          if (
            reason.data.message === 'La contraseña no coincide' ||
            reason.data.message === 'El email no coincide'
          ) {
            vm.loginError = 'El email o la contraseña no coinciden';
          } else {
            $scope.loginFailed();
          }
        }
      ).finally(() => {
        BillingService.getDatosFactura()
              .then((value) => {
                GlobalService.billing = value.data;
                if (GlobalService.billing.response == undefined) {
                  GlobalService.billing.response = [];
                }
              })
              .catch((reason) => {
                GlobalService.billing = [];
                console.error(reason);
              });
      });
    }

    /**
     * Falló el inicio de sesión...
     */
    function loginFailed() {
      vm.busy = false;
      GlobalService.user = null;
      CookiesService.setUser(null);
      vm.loginError = 'Ocurrió un error al intentar iniciar sesión';
    }
  }
})();
