(function () {
  'use strict';

  angular
    .module('filters.thousandsSeparator')
    .filter('thousandsSeparator', ThousandsSeparatorFilter);

  function ThousandsSeparatorFilter() {
    return function (number) {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        return 0;
      }
    };
  }
})();
