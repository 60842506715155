(function () {
  'use strict';

  angular.module('checkout.configBilling').component('checkoutConfigBilling', {
    controller: CheckoutConfigBilling,
    controllerAs: 'vm',
    templateUrl:
      'pages/checkout/components/checkout-config-billing/checkout-config-billing.template.html',
    bindings: {
      currentStep: '<',
    },
  });

  function CheckoutConfigBilling($rootScope, $scope, DateService, GlobalService) {
    
    const vm = this;
    vm.configBillingInfo = null;
    vm.checkboxModel = false;

    vm.$onInit = onInit;
    vm.openConfigDeliveryModal = openConfigDeliveryModal;
    vm.billingSelected = billingSelected;
    vm.openCreateBillingModal = openCreateBillingModal;
    vm.checkboxSelectDatosFactura = checkboxSelectDatosFactura;
    vm.toggleCheckbox=toggleCheckbox;

    //$scope.updateConfigDeliveryInfo = updateConfigDeliveryInfo;
    $scope.loadFirstBilling = loadFirstBilling;

    $rootScope.$on('billingSelected', billingSelected);
    $rootScope.$on('billingCreated', billingSelected);

    function onInit() {
      GlobalService.defaultBilling = null;
      $scope.global = GlobalService;
      $scope.loadFirstBilling();
    }

    function openConfigDeliveryModal() {
      //$rootScope.$broadcast('openConfigDeliveryModal', { state: 'checkout' });
      $rootScope.$broadcast('openBillingModal', { source: 'select-billing' });
      console.info('Broadcast: ¡Abrir modal de select billing');
    }

    function loadFirstBilling() {
        vm.configBillingInfo = $scope.global.billing.response[0] ? $scope.global.billing.response[0] : null;
    }

    function billingSelected(e, opt) {
      vm.configBillingInfo = opt.billing;
      GlobalService.defaultBilling = opt.billing;
      if (!vm.checkboxModel) {
        vm.toggleCheckbox(opt.billing);
      }
    }

    function openCreateBillingModal() {
      $rootScope.$broadcast('openBillingModal', { source: 'user-new-billing' });
      console.info('Broadcast: ¡Abrir modal de billing!');
    }


    function checkboxSelectDatosFactura(billing) {
      if (vm.checkboxModel) {
        GlobalService.defaultBilling = billing;
      }else{
        GlobalService.defaultBilling = null;
      }
    }

    function toggleCheckbox(billing) {
      vm.checkboxModel = !vm.checkboxModel;
      vm.checkboxSelectDatosFactura(billing);
      $rootScope.$broadcast('checkBilling', { check: vm.checkboxModel });
    };
  }
})();
