(function () {
  'use strict';

  angular.module('pages.checkout').controller('CheckoutController', CheckoutController);

  function CheckoutController(
    $anchorScroll,
    $document,
    $rootScope,
    $sce,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $transitions,
    $window,
    AlertService,
    AnalyticsService,
    GlobalService,
    LogService,
    CartService,
    CatalogService,
    DateService,
    OrderService,
    PaymentService,
    StoreService,
    UserService
  ) {
    var vm = this;

    $scope.global = GlobalService;

    const PAYMENT_WEBPAY = 4;
    const PAYMENT_CREDITS = 5;
    const ONECLICK_DESDE = 100;

    // Variables para manejar qué mostrar en la vista
    vm.busy = false;
    vm.contentBusy = false;
    vm.currentStep = 1;
    vm.paymentMethodError = false;
    vm.scheduleError = false;
    vm.stickyTopOffset = 50;
    vm.showGuestUserForm = false;
    vm.ocultarInvitado = true;
    vm.registroInvitado = true;
    vm.pedido = "Continuar con boleta"

    // Información de la configuración de entrega
    vm.configDeliveryInfo = {
      title: '',
      subtitle: '',
      image: '',
      schedule: '',
    };

    // Data
    vm.availableSchedules = null;
    vm.scheduleNextDay = '';
    vm.configDelivery = null;
    vm.cart = null;
    vm.user = null;
    vm.currentDiscount = null;
    vm.currentDiscountType = '';
    vm.paymentMethods = [];
    vm.enableCreditCard = true;
    vm.enablePiggyBank = true;
    vm.creditCards = null;
    vm.disabledPaymentMethodMessage = 'Medio de pago no válido para el descuento seleccionado.';

    // Input del usuario
    vm.phone = '';
    vm.comments = '';
    vm.paymentMethod = null;
    vm.schedule = null;
    vm.isAGift = false;
    vm.silverware = false;

    // Usuario invitado
    vm.guestUser = {};

    // Códigos de promoción
    vm.promoCodeMessage = '';
    vm.promoCodeMessageType = '';

    // Webpay
    vm.webpayAction = '';
    vm.webpayToken = '';

    // Webpay Oneclick
    vm.oneClickAction = '';
    vm.oneClickToken = '';

    // Mensajes de transbank
    vm.transbankMessage = '';
    vm.transbankMessageType = '';

    // Variables enlazadas al scope
    $scope.sentAnalytics = false;
    $scope.steps = [
      { number: 1, name: 'entrega' },
      { number: 2, name: 'descuentos' },
      { number: 3, name: 'confirmar' },
    ];
    vm.totalSteps = $scope.steps.length;

    // Promoción en el navbar
    vm.showNavbarPromo = false;
    vm.navbarPromoTitle = '';
    vm.navbarPromoMessage = '';

    // Funciones enlazadas a la plantilla
    vm.$onInit = onInit;
    vm.nextStep = nextStep;
    vm.exitCheckout = exitCheckout;
    vm.goToSelectStore = goToSelectStore;
    vm.goToStep = goToStep;
    vm.goToTransbank = goToTransbank;
    vm.goToEdenred = goToEdenred;
    vm.openConfigDeliveryModal = openConfigDeliveryModal;
    vm.openLoginModal = openLoginModal;
    vm.openPaymentMethodsModal = openPaymentMethodsModal;
    vm.openPromoCodeModal = openPromoCodeModal;
    vm.openRegisterModal = openRegisterModal;
    vm.setDiscount = setDiscount;
    vm.setPaymentMethod = setPaymentMethod;
    vm.setPiggyBank = setPiggyBank;
    vm.toggleGuestUserForm = toggleGuestUserForm;
    vm.updateSchedule = updateSchedule;

    vm.checkForBilling = checkForBilling;

    // Funciones enlazadas al controlador
    $scope.calculateStickyOffset = calculateStickyOffset;
    $scope.checkDetailsInCart = checkDetailsInCart;
    $scope.checkIfBusy = checkIfBusy;
    $scope.dismissNavbarPromo = dismissNavbarPromo;
    $scope.initializeOrder = initializeOrder;
    $scope.onPromoCodeMessage = onPromoCodeMessage;
    $scope.purchase = purchase;
    $scope.purchaseIfDetailsInCart = purchaseIfDetailsInCart;
    $scope.sendAnalytics = sendAnalytics;
    $scope.setDefaultPaymentMethod = setDefaultPaymentMethod;
    $scope.updateCurrentDiscount = updateCurrentDiscount;
    $scope.updatePaymentMethods = updatePaymentMethods;
    $scope.updateSchedules = updateSchedules;
    $scope.validateFirstStep = validateFirstStep;
    $scope.verifyNavbarPromo = verifyNavbarPromo;

    $rootScope.$on('promoCodeMessage', $scope.onPromoCodeMessage);
    $rootScope.$on('dismissNavbarPromo', $scope.dismissNavbarPromo);

    $rootScope.$on('checkBilling', vm.checkForBilling);

    // Constructor
    function onInit() {
      $anchorScroll();
      vm.busy = true;

      // Iniciar el checkout siempre en el paso 1
      $state.go('checkout', { stepName: $scope.steps[0].name });
      vm.currentStep = 1;

      $scope.checkDetailsInCart();

      const watchInitialLoad = $scope.$watchGroup(
        ['global.configDelivery', 'global.cart', 'global.paymentMethods', 'global.creditCards'],
        () => {
          const { cart, configDelivery, creditCards, paymentMethods } = GlobalService;

          if (cart && configDelivery && configDelivery.store && paymentMethods && creditCards) {
            vm.cart = cart;
            vm.configDelivery = configDelivery;
            vm.creditCards = creditCards;
            vm.paymentMethods = paymentMethods;

            // Salir del checkout si el carro está vacío
            $scope.checkDetailsInCart();

            // Actualizar otras cosas
            $scope.updatePaymentMethods();
            $scope.setDefaultPaymentMethod();
            $scope.updateCurrentDiscount();
            $scope.sendAnalytics();

            /**
             * Asegurar que el usuario está entrando al checkout de la tienda
             * correspondiente a su configuración de entrega, con la intención de
             * evitar que el usuario realize una compra con despacho desde una
             * tienda que no corresponde.
             */
            UserService.getUserConfig()
              .then((value) => {
                const userConfig = value.data;
                GlobalService.userConfig = userConfig;
                console.info('Configuración del usuario:', userConfig);

                StoreService.getStoreDataById(userConfig.store_id)
                  .then((store) => {
                    vm.configDelivery.store = store;
                    GlobalService.configDelivery.store = store;
                  })
                  .catch((reason) => {
                    console.log(reason);
                  })
                  .finally(() => {
                    $scope.updateSchedules();
                  });
              })
              .catch((reason) => {
                console.log(reason);
              })
              .finally(() => {
                watchInitialLoad();

                // Mostrar vista
                vm.busy = false;
              });
          }
        }
      );

      $scope.$watch('global.configDelivery', (newVal) => {
        if (newVal) {
          vm.configDelivery = newVal;
          $scope.updateSchedules();
        }
      });

      // Si el carro de compras cambia, actualizar la variable en el controlador
      $scope.$watch('global.cart', (newValue) => {
        if (newValue) {
          vm.cart = newValue;
          $scope.checkDetailsInCart();
          $scope.updateCurrentDiscount();
          $scope.updatePaymentMethods();
        }
      });

      $scope.$watch('global.user', (newValue) => {
        if (newValue) {
          vm.user = newValue;
          vm.showGuestUserForm = false;
          if (!vm.phone) {
            vm.phone = newValue.phone_number;
          }

          $scope.verifyNavbarPromo();
        }
      });

      $scope.$watch('global.creditCards', (newValue) => {
        if (newValue) {
          vm.creditCards = newValue;
          $scope.setDefaultPaymentMethod();
        }
      });

      // Ocultar el loader, si es que ya lo que necesita el checkout está listo
      $scope.checkIfBusy();

      // Verificar si es que el usuario vuelve de transbank
      const { status, message } = $stateParams;
      if (status && message) {
        vm.transbankMessage = message;

        if (status === 'true') {
          vm.transbankMessageType = 'success';
          AnalyticsService.addPaymentMethod();
        } else if (status === 'false') {
          vm.transbankMessageType = 'warning';
        } else {
          LogService.unexpectedCase(
            vm.constructor.name,
            `Volvió de Transbank con un status que no está definido: ${status}`
          );
        }

        LogService.info('Volver de Transbank', vm.constructor.name, {
          cart: GlobalService.cart,
          configDelivery: GlobalService.configDelivery,
          status: $stateParams.status,
          message: $stateParams.message,
        });
      }
    }

    /**
     * Manejar cuando se intenta avanzar o retroceder pasos
     */
    $transitions.onSuccess({ from: 'checkout', to: 'checkout' }, (transition) => {
      const stepName = transition.router.globals.params.stepName;
      const step = $scope.steps.find((element) => element.name === stepName);
      vm.currentStep = step ? step.number : 1;
      $anchorScroll();
    });

    function calculateStickyOffset() {
      const fixedTopElementHeight = $document[0]
        .getElementById('fixed-top')
        .getBoundingClientRect().height;
      vm.stickyTopOffset = fixedTopElementHeight;
    }

    /**
     * Si no hay productos en el carro, el checkout no debe mostrarse
     */
    function checkDetailsInCart() {
      if (vm.cart && vm.cart.productos && !vm.cart.productos.length) {
        vm.exitCheckout();
      } else {
        $scope.checkIfBusy();
      }
    }

    function checkIfBusy() {
      if (!!vm.cart && !!vm.configDelivery) {
        vm.busy = false;
        $timeout(() => {
          $scope.verifyNavbarPromo();
          $rootScope.$broadcast('updateAlwaysVisible');
          // console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
        });
      }
    }

    /**
     * Ocultar la promoción del navbar
     */
    function dismissNavbarPromo() {
      vm.showNavbarPromo = false;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    /**
     * Salir del checkout
     */
    function exitCheckout() {
      $state.go('home');
    }

    /**
     * Mostrar el modal de configuración de entrega en la selección de tiendas
     */
    function goToSelectStore() {
      $rootScope.$broadcast('openConfigDeliveryModal', {
        state: 'checkout-without-delivery-schedules',
      });
      console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
    }

    function goToTransbank() {
      vm.contentBusy = true;

      const request = {
        full_name: `${vm.user.name} ${vm.user.lastname}`,
        email: vm.user.email,
        tipo_forma_pago: '1',
        card_name: '---',
        freezer: false,
        checkout: true,
      };

      PaymentService.addCardPayment(request).then(
        (value) => {
          const { tbk_url, tbk_token } = value.data;

          vm.oneClickAction = $sce.trustAsResourceUrl(tbk_url);
          vm.oneClickValue = tbk_token;

          // Redireccionar la página
          $timeout(() => {
            LogService.info('Ir a Transbank', vm.constructor.name, null);
            $document[0].getElementById('webpay-oneclick-form').submit();
          });
        },
        (reason) => {
          console.error(reason);
          vm.contentBusy = false;
          vm.transbankMessageType = 'warning';
          vm.transbankMessage =
            'Transbank presenta inestabilidad en su servicio y no nos deja asociar tu tarjeta. Por favor espera unos minutos e intenta nuevamente.';
        }
      );
    }

    function goToEdenred() {
      vm.contentBusy = true;
      PaymentService.addEdenredCardPayment().then(
        (value) => {
          $window.location.href = value.data.urlEdenred;
        },
        (reason) => {
          console.log(reason);
          vm.contentBusy = false;
          vm.transbankMessageType = 'warning';
          vm.transbankMessage =
            'Edenred presenta problemas en su servicio. Por favor espera unos minutos e intenta nuevamente.';
        }
      );
    }

    /**
     * Iniciar pedido
     */
    function initializeOrder() {
      var config = vm.configDelivery;

      // Es necesario elegir un método de pago si el total de la compra es mayor a 0
      if (!vm.paymentMethod && vm.cart.total > 0) {
        vm.paymentMethodError = true;
      }

      // Es necesario elegir un bloque de horario si el método de entrega es delivery
      else if (vm.configDelivery.kind === 0 && !vm.schedule) {
        vm.scheduleError = true;
        vm.currentStep = 1;
      }

      // Continuar con la compra
      else {
        vm.contentBusy = true;

        // Hacer una vez más la validación de los objetos del carro
        CartService.checkCart(config.store).then(
          (value) => {
            const cart = value.data.response;
            const dropItems = cart.productosNoEncontrados;

            if (!dropItems || !dropItems.length) {
              $scope.purchase();
            } else {
              // Antes de ajustar el carro tambien se preguntara si el tipo de distribucion es retiro el bloque se modifica a 0
              if (vm.cart.tipoDistribucion === 'RETIRO') {
                vm.cart.bloque = 0;
              }
              // Mostrar alerta del sistema
              AlertService.stockDifference(
                config,
                dropItems,
                function () {
                  CartService.adjustCart(cart, dropItems, config).then((value) => {
                    GlobalService.cart = value.data;
                    $scope.purchaseIfDetailsInCart();
                  });
                },
                function () {
                  vm.contentBusy = false;
                }
              );
            }
          },
          (reason) => {
            console.error(`Ocurrió un error consultando el carro de compras: ${reason}`);
            AlertService.purchaseServerError(reason.message);
            vm.contentBusy = false;
          }
        );
      }
    }

    /**
     * Continuar con la compra
     */
    function nextStep() {
      vm.nextStepAttempt = false;
      const formValid = $scope.validateFirstStep();
      if (
        (vm.currentStep === 1 && formValid) ||
        (vm.currentStep > 1 && vm.currentStep < vm.totalSteps)
      ) {
        // Avanzar al siguiente paso
        //Aqui se envia el bloque de horario al carro consulta si es delivery o retiro
        if (vm.currentStep === 1) {
          //Aqui se envia el bloque de horario al carro consulta si es delivery o retiro, y si el paso es 1
          if (vm.cart.tipoDistribucion === 'RETIRO') {
            vm.cart.bloque = 0;
          } else {
            //Aqui se envia el bloque de horario al carro
            vm.contentBusy = true;
            vm.cart.bloque = vm.schedule.id;
            CartService.setCart(vm.cart)
              .then((value) => {
                GlobalService.cart = value.data.response;
              })
              .catch((reason) => {
                console.error(reason);
              })
              .finally(() => {
                vm.contentBusy = false;
              });
          }
        }
        $anchorScroll();
        AnalyticsService.clickNextCheckoutStep(vm.currentStep + 1);
        $state.go('checkout', { stepName: $scope.steps[vm.currentStep].name });
      } else if (vm.currentStep === vm.totalSteps) {
        // Iniciar el pedido
        $scope.initializeOrder();
      } else {
        // No hacer nada, pero avisar que se intentó avanzar al siguiente paso
        vm.nextStepAttempt = true;
      }
    }

    /**
     * Ir a un paso en específico.
     * Sólo está permitido ir directamente a un paso anterior.
     * @param step
     */
    function goToStep(step) {
      if (step < vm.currentStep) {
        vm.currentStep = step;
        $state.go('checkout', { stepName: $scope.steps[step - 1].name });
      }
    }

    function onPromoCodeMessage(event, opt) {
      vm.promoCodeMessage = opt.message;
      vm.promoCodeMessageType = 'success';
    }

    /**
     * Mostar el modal de configuración de entrega
     */
    function openConfigDeliveryModal() {
      $rootScope.$broadcast('openConfigDeliveryModal', { state: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de configuración de entrega!');
    }

    /**
     * Mostrar el modal de inicio de sesión
     */
    function openLoginModal() {
      $rootScope.$broadcast('openLoginModal', { source: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de inicio de sesión!');
    }

    /**
     * Mostrar el modal de método de pago
     */
    function openPaymentMethodsModal() {
      $rootScope.$broadcast('openPaymentMethodsModal');
      console.info('Broadcast: ¡Abrir el modal de métodos de pago!');
    }

    /**
     * Mostrar el modal de códigos promo
     */
    function openPromoCodeModal() {
      $rootScope.$broadcast('openPromoCodeModal', { source: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de códigos de promoción!');
    }

    /**
     * Mostrar el modal de registro de usuarios
     */
    function openRegisterModal() {
      $rootScope.$broadcast('openRegisterModal', { source: 'checkout' });
      console.info('Broadcast: ¡Abrir el modal de registro de usuarios!');
    }

    /**
     * Enviar analíticas de que se ha iniciado el checkout
     */
    function sendAnalytics() {
      if (!$scope.sentAnalytics && vm.cart && vm.cart.productos && vm.cart.productos.length) {
        AnalyticsService.initCheckout('', vm.cart.productos);
        $scope.sentAnalytics = true;
      }
    }

    /**
     * Establecer método de pago por defecto
     */
    function setDefaultPaymentMethod() {
      if (vm.cart && vm.cart.totalPrevio) {
        if (vm.creditCards && vm.creditCards.length && vm.enableCreditCard) {
          vm.paymentMethod = vm.creditCards[0];
        } else {
          const webpay = vm.paymentMethods.find(
            (paymentMethod) => paymentMethod.id === PAYMENT_WEBPAY
          );

          if (webpay && webpay.habilitado) {
            vm.paymentMethod = webpay;
          } else {
            vm.paymentMethod =
              vm.paymentMethods.find(
                (paymentMethod) => paymentMethod.habilitado && paymentMethod.id !== 5
              ) || null;
          }
        }
      } else {
        vm.paymentMethod = null;
      }

      console.info('Método de pago por defecto:', vm.paymentMethod);
    }

    /**
     * Establece un descuento
     * @param discountType
     */
    function setDiscount(discountType) {
      vm.contentBusy = true;
      vm.cart.tipoDescuento = discountType;
      //Aqui se envia el bloque de horario al carro
      if (vm.cart.tipoDistribucion === 'RETIRO') {
        vm.cart.bloque = 0;
      }
      CartService.setCart(vm.cart)
        .then((value) => {
          GlobalService.cart = value.data.response;
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {
          vm.contentBusy = false;
        });
    }

    function setPaymentMethod(paymentMethod) {
      vm.paymentMethod = paymentMethod;

      // Si el total de la compra se puede pagar con la alcancía y el usuario decide usar otro medio de pago,
      // hay que desmarcar la alcancía
      if (vm.cart.totalPrevio <= 0) {
        vm.cart.alcancia.usar = false;
        vm.setPiggyBank();
      }
    }

    function setPiggyBank() {
      vm.contentBusy = true;
      //Aqui se envia el bloque de horario al carro
      if (vm.cart.tipoDistribucion === 'RETIRO') {
        vm.cart.bloque = 0;
      }
      CartService.setCart(vm.cart)
        .then((value) => {
          GlobalService.cart = value.data.response;

          // Si el total de la compra se puede pagar con la alcancía, quitar el método de pago seleccionado
          if (GlobalService.cart.alcancia.usar && GlobalService.cart.totalPrevio <= 0) {
            vm.paymentMethod = null;
          } else if (!vm.paymentMethod) {
            $scope.setDefaultPaymentMethod();
          }
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {
          vm.contentBusy = false;
        });
    }

    /**
     * Mostrar/Ocultar el formulario de usuario invitado
     */
    function toggleGuestUserForm(show) {
      const formValid = $scope.validateFirstStep();
      //validar primero si selecciono horario de entrega si es delivery
      if (vm.currentStep === 1 && !formValid) {
        vm.nextStepAttempt = true;
      } else {
        vm.showGuestUserForm = show;
        vm.registroInvitado = false;
        $anchorScroll();
      }
    }

    /**
     * Actualiza el descuento actual
     */
    function updateCurrentDiscount() {
      if (vm.cart && vm.cart.tipoDescuento && vm.cart.descuentos && vm.cart.descuentos.length) {
        vm.currentDiscountType = vm.cart.tipoDescuento;
        vm.currentDiscount =
          vm.cart.descuentos.find((discount) => {
            return discount.tipo === vm.cart.tipoDescuento;
          }) || null;
      }
    }

    /**
     * Quitar la condición de error del bloque de reparto
     */
    function updateSchedule() {
      if (vm.schedule) {
        vm.scheduleError = false;
      }
    }

    function updatePaymentMethods() {
      vm.paymentMethods = PaymentService.getPaymentMethods().filter(
        (medioPago) => medioPago.habilitado
      );

      // Actualizar el estado habilitado de oneClick
      let oneClick = vm.cart.metodosPago.find(
        (paymentMethod) => paymentMethod.id === 3 || paymentMethod.id > ONECLICK_DESDE
      );
      vm.enableCreditCard = oneClick.habilitado;

      // Actualizar el estado habilitado de Alcancía
      let piggyBank = vm.cart.metodosPago.find((paymentMethod) => paymentMethod.id === 5);
      if (piggyBank) {
        vm.enablePiggyBank = piggyBank.habilitado;
      }

      // Verificar si es que está seleccionado un método de pago que no está habilitado.
      // Si es así, entonces hay que cambiarlo.
      if (vm.paymentMethod) {
        const selectedPaymentMethod = vm.paymentMethods.find(
          (method) => method.id === vm.paymentMethod.id
        );
        if (!selectedPaymentMethod.habilitado) {
          $scope.setDefaultPaymentMethod();
        } else {
          if (selectedPaymentMethod.id > ONECLICK_DESDE) {
            vm.paymentMethod = vm.creditCards[0];
          } else {
            vm.paymentMethod = selectedPaymentMethod;
          }
        }
      } else {
        $scope.setDefaultPaymentMethod();
      }
    }

    function updateSchedules() {
      if (vm.configDelivery && vm.configDelivery.kind === 0 && vm.configDelivery.address) {
        vm.contentBusy = true;
        vm.schedule = null;

        StoreService.getSchedule(vm.configDelivery.address.store_id)
          .then((value) => {
            const store = value.data;

            // Verificar si hay bloques de delivery disponibles
            if (store.available_schedules.length) {
              vm.availableSchedules = store.available_schedules;

              // La entrega es para el día siguiente?
              if (vm.availableSchedules[0].dia) {
                const day = vm.availableSchedules[0].dia[2];
                const month = DateService.getMonthString(vm.availableSchedules[0].dia[1]);

                vm.scheduleNextDay = `${day} de ${month}`;
              } else {
                vm.scheduleNextDay = '';
              }
            } else {
              // Si no hay bloques disponibles, mostrar advertencia y la opción de cambiar a retiro en tienda...
              vm.availableSchedules = [];
              AlertService.deliveryUnavailable(vm.goToSelectStore);
            }

            $anchorScroll();
            $timeout(() => {
              $rootScope.$broadcast('updateAlwaysVisible');
              console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
            });
          })
          .finally(() => {
            vm.contentBusy = false;
          });
      }
    }

    /**
     * Paso final: Comprar
     */
    function purchase() {
      // Enviar analíticas
      AnalyticsService.purchaseAttempt(vm.paymentMethod);

      // Preparar objeto del pedido
      const config = vm.configDelivery;
      let item;
      let items_pack;
      let items = [];

      vm.cart.productos.forEach((detail) => {
        item = {
          portion_sku: detail.sku,
          quantity: detail.cantidad,
        };

        if (detail.productosPack && detail.productosPack.length) {
          items_pack = [];

          detail.productosPack.forEach((itemPack) => {
            items_pack.push({
              portion_sku: itemPack.sku,
              quantity: itemPack.cantidad,
            });
          });

          item.items_pack = items_pack;
        }

        items.push(item);
      });

      // En ocaciones, la api indica que el usuario tiene un descuento seleccionado que no se encuentra
      // en la lista de descuentos disponibles para el usuario.
      // Esto no debería ocurrir, pero de igual manera debemos verificar
      let currentDiscountType = 'NINGUNO';
      if (vm.cart.tipoDescuento !== currentDiscountType) {
        const currentDiscount = vm.cart.descuentos.find(
          (discount) => discount.tipo === vm.cart.tipoDescuento
        );
        if (currentDiscount) {
          currentDiscountType = currentDiscount.tipo;
        }
      }

      // Usuario invitado
      let guestUser;
      if (!vm.user) {
        const { rut_dv, name, lastname, email } = vm.guestUser;
        guestUser = {
          rut: rut_dv || null,
          correo: email,
          nombre: name,
          apellido: lastname,
        };
      } else {
        guestUser = null;
      }

      let defaultBillingId   = GlobalService.defaultBilling ? GlobalService.defaultBilling.id : null;

      const order = {
        items: items,
        kind: config.kind,
        payment_id: vm.cart.total > 0 && vm.paymentMethod ? vm.paymentMethod.id : PAYMENT_CREDITS,
        store_id: config.store.id,
        address_id: config.kind === 0 ? config.address.id : null,
        schedule_id: config.kind === 0 ? vm.schedule.id : null,
        silverware: vm.isAGift || vm.silverware,
        device: 1,
        currentDiscountType: currentDiscountType,
        usaAlcancia: vm.cart.alcancia.usar,
        telefono: vm.phone,
        comentario: vm.comments,
        usuarioInvitado: guestUser,
        id_datos_facturacion: defaultBillingId
      };

      OrderService.createOrder(order)
        .then((value) => {
          const orderSuccessfully = value.data;
          if (orderSuccessfully.url) {
            if (orderSuccessfully.token) {
              vm.webpayAction = $sce.trustAsResourceUrl(orderSuccessfully.url);
              vm.webpayToken = orderSuccessfully.token;

              $timeout(() => {
                $document[0].getElementById('webpay-form').submit();
              });
            } else {
              $window.location.href = orderSuccessfully.url;
            }
          } else {
            $state
              .go('purchaseSuccess', {
                orderId: orderSuccessfully.id,
              })
              .then(() => {
                // Actualizar catálogo
                CatalogService.updateCatalog();

                // Actualizar el dinero Fork del usuario
                if (vm.cart.alcancia.usar) {
                  UserService.getCurrentUserData().then((value) => {
                    GlobalService.user.unlimited_credits = value.data.unlimited_credits;
                  });
                }
              });
          }
        })
        .catch((reason) => {
          const orderFailure = reason.data;

          vm.contentBusy = false;

          // Enviar analíticas
          AnalyticsService.purchaseFailed(orderFailure, vm.paymentMethod);

          if (orderFailure && (orderFailure.code || orderFailure.message)) {
            if (
              orderFailure.code === 'ERR_BLOQUE_DELIVERY' ||
              orderFailure.message === 'No hay bloques disponibles' ||
              orderFailure.message === 'El bloque no existe'
            ) {
              // Actualizar los bloques de reparto...
              StoreService.getSchedule(config.address.store_id).then((value) => {
                const store = value.data;

                // Si hay bloques disponibles, mostrar advertencia
                if (store.available_schedules.length) {
                  vm.schedule = null;
                  vm.availableSchedules = store.available_schedules;

                  AlertService.scheduleUnavailable();
                } else {
                  // Si no hay bloques disponibles, mostrar advertencia y la opción de cambiar a retiro en tienda...
                  AlertService.deliveryUnavailable(vm.goToSelectStore);
                }

                // Devolver al primer paso
                vm.currentStep = 1;
              });
            } else if (
              orderFailure.code === 'ERR_CLOSED_XMASS' ||
              orderFailure.code === 'ERR_CLOSED_NEW_YEAR' ||
              orderFailure.code === 'ERR_CLOSED_LABOR_DAY'
            ) {
              AlertService.holiday(orderFailure.message);
            } else if (orderFailure.code === 'ERR_ONECLICK') {
              AlertService.oneclickError(orderFailure.message, order.kind);
            } else if (orderFailure.code === 'NGC' && orderFailure.message) {
              AlertService.purchaseServerError(orderFailure.message);
            } else if (orderFailure.message === 'Error obteniendo tarjeta tbk') {
              AlertService.cantLoadOneClick();
            } else {
              AlertService.genericPurchaseError();
            }
          } else {
            AlertService.genericPurchaseError();
          }
        });
    }

    /**
     * Comprar sólo si quedan productos en el carro
     */
    function purchaseIfDetailsInCart() {
      if (GlobalService.cart.productos.length) {
        $scope.purchase();
      } else {
        vm.exitCheckout();
      }
    }

    /**
     * Validar el primer paso
     */
    function validateFirstStep() {
      return !!(
        vm.phone &&
        (vm.configDelivery.kind === 1 || (vm.configDelivery.kind === 0 && vm.schedule))
      );
    }

    /**
     * Verificar si hay que mostrar la promoción del navbar
     */
    function verifyNavbarPromo() {
      // Aquí debería ir la lógica para mostrar la promoción del navbar
      // vm.showNavbarPromo = !user || user.nuevo;
      vm.showNavbarPromo = false;
      $timeout(() => {
        $scope.calculateStickyOffset();
      });
    }

    function checkForBilling(e,opt){
      if (opt.check) {
        vm.pedido = "Continuar con Factura"
      } else {
        vm.pedido = "Continuar con Boleta"
      }
    }

  }
})();
