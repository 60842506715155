(function () {
  'use strict';

  angular
    .module('pages.transbankTimeout')
    .controller('TransbankTimeoutController', TransbankTimeoutController);

  function TransbankTimeoutController($state, $stateParams, ZendeskWidget) {
    var vm = this;

    vm.orderId = 0;

    vm.$onInit = onInit;
    vm.showZendeskHelp = showZendeskHelp;

    function onInit() {
      if ($stateParams && $stateParams.orderId) {
        vm.orderId = $stateParams.orderId;
      } else {
        $state.go('home');
      }
    }

    function showZendeskHelp() {
      ZendeskWidget.activate();
    }
  }
})();
