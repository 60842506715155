(function () {
  'use strict';

  angular.module('checkout.details').component('checkoutDetails', {
    controller: CheckoutDetailsController,
    templateUrl: 'pages/checkout/components/checkout-details/checkout-details.template.html',
    controllerAs: 'vm',
    bindings: {
      cart: '<',
      configDelivery: '<',
      currentDiscount: '<',
      currentStep: '<',
      contentBusy: '<',
    },
  });

  function CheckoutDetailsController($rootScope, $timeout) {
    var vm = this;
    vm.$onInit = onInit;

    vm.collapsedDetails = false;
    vm.collapseDetails = collapseDetails;

    function onInit() {
      if (vm.currentStep == "3") {
        vm.collapsedDetails = true;                
      }
    }

    /**
     * Mostrar/Ocultar los detalles del pedido
     */
    function collapseDetails() {
      vm.collapsedDetails = !vm.collapsedDetails;
      $rootScope.$broadcast('updateAlwaysVisibleInterval');
      $timeout(function () {
        $rootScope.$broadcast('cancelUpdateAlwaysVisible');
      }, 350);
    }
  }
})();
