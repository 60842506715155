(function () {
  'use strict';

  angular.module('app').run(AppRun);

  function AppRun(
    $window,
    $rootScope,
    ZendeskWidget,
    CookiesService,
    AddressService,
    AnalyticsService,
    CartService,
    CatalogService,
    GlobalService,
    MetaTagService,
    PaymentService,
    StoreService,
    UserService,
    BillingService
  ) {
    // Evento stateChangeSuccess
    $rootScope.$on('$stateChangeSuccess', stateChangeSuccess);

    /**
     * Carga inicial de la página!
     * Primero que nada, obtener el token de usuario
     */
    UserService.getUser()
      .then((value) => {
        const user = value.data;
        GlobalService.user = user;
        console.info('Usuario:', user);

        /**
         * Con el token de usuario, ya se puede hacer cualquier otro request!
         * Obtener el listado de tiendas
         */
        StoreService.getStores()
          .then((value) => {
            const stores = value;

            /**
             * Obtener la configuración de entrega
             */
            UserService.getUserConfig().then((value) => {
              const userConfig = value.data;
              GlobalService.userConfig = userConfig;
              console.info('Configuración del usuario:', userConfig);

              /**
               * Si el usuario está autenticado, cargamos su información
               */
              if (user && user.name) {
                // Identificar el usuario en Zendesk
                ZendeskWidget.identify({
                  name: user.name,
                  email: user.email,
                });

                // Consultar si falta por evaluar algún pedido
                $rootScope.$broadcast('checkRatingModal');
                // console.info('Broadcast: ¡Consultar el modal de rating!');
              }

              // Obtener direcciones
              AddressService.getAddress()
                .then((value) => {
                  GlobalService.addresses = value.data.addresses;
                  console.info('Direcciones:', GlobalService.addresses);
                })
                .catch((reason) => {
                  // Ocurrió un error obteniendo las direcciones del usuario.
                  // La lista de direcciones del usuario estará vacía.
                  // usuario invitado no se guardan las direcciones
                  GlobalService.addresses = [];
                  console.error(reason);
                })
                .finally(() => {
                  setConfigDelivery(stores);
                });

              BillingService.getDatosFactura()
              .then((value) => {
                console.info('Billings:', value.data);
                GlobalService.billing = value.data;
                if (GlobalService.billing.response == undefined) {
                  GlobalService.billing.response = [];
                }
              })
              .catch((reason) => {
                GlobalService.billing = [];
                console.error(reason);
              });
            });
          })
          .catch((reason) => {
            // Ocurrió un error en la carga de la información básica. La página no se mostrará correctamente...
            console.error(reason);
          });

        // Enviar analíticas de visita al ecommerce
        AnalyticsService.visitEcommerce(user, $window.location.pathname);
      })
      .catch((reason) => {
        // Ocurrió un error al intentar obtener el token del usuario. La página no se mostrará
        console.error(reason);
      });

    /**
     * Obtener carro de compras
     */
    function getCart() {
      const storeId = GlobalService.configDelivery.store.id;

      CartService.getCart(storeId).then((value) => {
        const cart = value.data.response;

        /**
         * En ocasiones, el usuario puede tener un carro guardado desde otra sesión
         * en la que eligió despacho a domicilio. Sin embargo, pueda que en la sesión
         * actual el usuario tenga seleccionado retiro en tienda... O vice versa
         */
        const isDelivery = GlobalService.userConfig.delivery === 'DELIVER';
        const hasDeliveryPrice = cart.precioEnvio > 0;

        if ((isDelivery && hasDeliveryPrice) || (!isDelivery && !hasDeliveryPrice)) {
          // Si concuerda el costo de delivery con el modo de entrega, todo bien
          GlobalService.cart = cart;

          // Si el carro no trae los metodos de pago lo reseteamos
          if (!cart.metodosPago || !cart.metodosPago.length) {
            console.log('el carro no tiene metodos de pago');
            const { store_id, delivery } = GlobalService.userConfig;
            const configDelivery = {
              store: {
                id: store_id,
              },
              kind: delivery === 'DELIVER' ? 0 : 1,
            };
            CartService.setCart(cart, configDelivery).then((value) => {
              GlobalService.cart = value.data.response;
              console.info('Carro de compras:', GlobalService.cart);
              getPaymentMethods();
            });
          } else {
            console.info('Carro de compras:', GlobalService.cart);
            getPaymentMethods();
          }
        } else {
          // Si no concuerda el costo de delivery con el modo de entrega, lo reseteamos ¯\_(ツ)_/¯'
          const { store_id, delivery } = GlobalService.userConfig;
          const configDelivery = {
            store: {
              id: store_id,
            },
            kind: delivery === 'DELIVER' ? 0 : 1,
          };

          CartService.setCart(cart, configDelivery).then((value) => {
            GlobalService.cart = value.data.response;
            console.info('Carro de compras:', GlobalService.cart);
            getPaymentMethods();
          });
        }
      });
    }

    /**
     * Obtener métodos de pago
     */
    function getPaymentMethods() {
      GlobalService.paymentMethods = PaymentService.getPaymentMethods();
      console.info('Métodos de pago:', GlobalService.paymentMethods);

      if (GlobalService.user) {
        // Obtener tarjetas de crédito
        PaymentService.getCreditCards()
          .then((value) => {
            GlobalService.creditCards = value.data;
            console.info('Tarjetas de crédito:', GlobalService.creditCards);
          })
          .catch((reason) => {
            // Ocurrió un error obteniendo las tarjetas de crédito del usuario.
            // La aplicación creerá que no tiene ninguna tarjeta asociada y le sugerirá al usuario que puede agregar una.
            console.error(reason);
          });
      }
    }

    /**
     * Establecer la configuración de entrega
     * @param {object[]} stores Tiendas
     */
    function setConfigDelivery(stores) {
      let store;
      let address;

      const {
        delivery: deliveryKind,
        store_id: storeId,
        address_id: addressId,
      } = GlobalService.userConfig;

      // Caso en que la configuración de entrega sea retiro en tienda
      if (!deliveryKind || deliveryKind === 'TO_GO') {
        address = null;
        store = stores.find((store) => store.id === storeId);
      }

      // Caso en que la configuración de entrega sea despacho a domicilio
      if (deliveryKind === 'DELIVER') {
        address = GlobalService.addresses.find((address) => address.id === addressId) || null;

        // La dirección guardada en la configuración de entrega podría no existir...
        // O la dirección podría no tener una tienda asignada (store_id === 0)
        if (!address || !address.store_id) {
          address = null;
          store = null;
        } else {
          store = { id: address.store_id };
        }
      }

      // Si no se logró definir una tienda a partir de la configuración de entrega, se asigna la primera de la lista
      if (!store) {
        store = stores[0];
        address = null;
      }

      GlobalService.configDelivery.kind = address ? 0 : 1;
      GlobalService.configDelivery.store = store;
      GlobalService.configDelivery.address = address;
      GlobalService.activeStore = store;
      console.info('Configuración de entrega: ', GlobalService.configDelivery);

      CatalogService.updateCatalog();
      getCart();
    }

    function stateChangeSuccess(event, toState) {
      const stateName = toState.name;

      $rootScope.$broadcast('stateChangeSuccess');
      // console.info(`Broadcast: ¡Cambió el estado a ${stateName}!`);

      if (stateName !== 'home.category' && stateName !== 'productDetails') {
        MetaTagService.updateMetaTags();
      }
    }
  }
})();
