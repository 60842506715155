(function () {
  'use strict';

  angular
    .module('directives.uniqueEmailFormValidation')
    .directive('uniqueEmail', UniqueEmailFormValidation);

  function UniqueEmailFormValidation($q, UserService) {
    return {
      require: 'ngModel',
      scope: {
        busy: '=',
      },
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$asyncValidators.uniqueEmail = function (modelValue, viewValue) {
          // No validar si el texto viene vacío
          if (ctrl.$isEmpty(modelValue) || ctrl.$error.email) {
            return true;
          } else {
            scope.busy = true;
            return UserService.validateEmail(viewValue).then(function (value) {
              scope.busy = false;
              if (value.data.valid) {
                return true;
              } else {
                return $q.reject();
              }
            }, $q.reject);
          }
        };
      },
    };
  }
})();
