(function () {
  'use strict';

  angular.module('directives.latin1FormValidation').directive('latin1', Latin1FormValidation);

  function Latin1FormValidation() {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.latin1 = function (modelValue, viewValue) {
          // No validar si el texto viene vacío
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }

          var text = viewValue;
          var regex = /^[A-z\s\u00C0-\u00ff]+$/g;
          var anotherRegex = /[_\[\]]/;

          return !(!regex.test(text) || anotherRegex.test(text));
        };
      },
    };
  }
})();
