(function () {
  'use strict';

  angular.module('productDetails.productRegular').component('productRegular', {
    templateUrl: 'pages/product-details/components/product-regular/product-regular.template.html',
    controller: ProductRegularController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
    },
  });

  function ProductRegularController(
    $scope,
    $rootScope,
    $timeout,
    $window,
    CartService,
    GlobalService
  ) {
    var vm = this;
    $scope.global = GlobalService;

    var unregisterCart;

    vm.busy = false;
    vm.soldOut = false;

    // Variable para que llama el mensaje de productos frescos
    vm.freshProductMessage = false;

    // Variable para que llama el tag de Pack 5x4
    //vm.product.pack.type = false;

    $scope.imgSlider = null;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.addToCart = addToCart;
    vm.removeFromCart = removeFromCart;

    $scope.$on('$destroy', onDestroy);
    $scope.checkIfInCart = checkIfInCart;
    $scope.initTagsSlider = initTagsSlider;

    function onChanges(changes) {
      if (changes && changes.product) {
        onInit();
      }
    }

    function onInit() {
      if (vm.product) {
        vm.product.url = $window.location.href;
        vm.soldOut =
          (vm.product.items && vm.product.items[0].stock <= 0) ||
          (vm.product.tipoPackDinamico && !vm.product.packDinamico.length);

        $scope.checkIfInCart();
        $scope.initTagsSlider();

        // Si el carro cambia, verificar si el producto actual se encuentra en él
        unregisterCart = $scope.$watch('global.cart', function () {
          $scope.checkIfInCart();
        });
      }
    }

    function onDestroy() {
      unregisterCart();
    }

    /**
     * Agregar producto al carro
     * @param item Producto
     */
    function addToCart(item) {
      if (!vm.busy) {
        vm.busy = true;

        var cartItem = item.items[0].portion;
        var itemsPack;

        // Si el producto es un pack, agregarlo al carro
        if (vm.product.tipoPackDinamico && vm.product.tipoPackDinamico.codigo === 'N_IGUALES') {
          // Los packs del tipo N_IGUALES sólo tienen 1 producto
          itemsPack = vm.product.packDinamico[0].productos;
        } else {
          itemsPack = [];
        }

        // Necesitamos que el precio esté en la porción del producto para poder enviar las analíticas
        cartItem.price = item.items[0].unit_price;

        // mapeamos propiedades extras para analiticas
        cartItem.title = item.title;
        cartItem.subtitle = item.subtitle;
        cartItem.url = item.url;
        cartItem.imageUrl = item.image_url;

        CartService.addToCart(cartItem, itemsPack, 'product-regular').then(
          function (value) {
            vm.busy = false;
            GlobalService.cart = value;
          },
          function () {
            vm.busy = false;
          }
        );
      }
    }

    function checkIfInCart() {
      if (vm.product && vm.product.items && $scope.global.cart) {
        var itemInCart = $scope.global.cart.productos.find((product) => {
          return product.sku === vm.product.items[0].portion.sku;
        });

        vm.product.inCart = itemInCart ? itemInCart.cantidad : 0;
      }

      $timeout(() => {
        $rootScope.$broadcast('updateAlwaysVisible');
        console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
      });
    }

    function initTagsSlider() {
      if (vm.product.tags && vm.product.tags.length > 3) {
        $scope.tagSlider = new Swiper('#product-tags', {
          slidesPerView: 'auto',
          freeMode: true,
          direction: 'horizontal',
        });
      }
    }

    /**
     * Disminuir producto existente en el carro de compras
     * @param {object} item Producto a disminuir
     */
    function removeFromCart(item) {
      if (!vm.busy) {
        vm.busy = true;

        CartService.removeFromCart(item.portion, 'product-details')
          .then((value) => {
            if (value) {
              GlobalService.cart = value.data.response;
            }
          })
          .finally(() => {
            vm.busy = false;
          });
      }
    }
  }
})();
