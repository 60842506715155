(function () {
  'use strict';

  angular.module('pages.userProfile', [
    'pages.userProfile.addresses',
    'pages.userProfile.creditCard',
    'pages.userProfile.orders',
    'pages.userProfile.piggyBank',
    'pages.userProfile.userData',
    'pages.userProfile.billing',
  ]);
})();
