(function () {
  'use strict';

  angular.module('shared.promoModal').component('promoModalComponent', {
    templateUrl: 'shared/promo-modal/promo-modal.template.html',
    controller: PromoModalController,
    controllerAs: 'vm',
  });

  function PromoModalController($scope, $rootScope, ContentfulService) {
    const vm = this;

    // Variables que definirán si el modal se mostrará o no
    $scope.popupPromo = false;
    $scope.showPopupPromo = false;
    $scope.minPromoValue = 999999;

    // Variables que mostrarán la información en la vista
    vm.popupImage = '';
    vm.promoTitle = '';

    vm.$onInit = onInit;

    // Funciones enlazadas al rootScope
    $rootScope.$on('checkPopupPromo', checkPopupPromo);

    function onInit() {
      ContentfulService.getPromoPopup().then((value) => {
        if (value) {
          const { popupImage, promoTitle, minPromoValue } = value;

          vm.popupImage = popupImage;
          vm.promoTitle = promoTitle;

          $scope.popupPromo = true;
          $scope.showPopupPromo = true;
          $scope.minPromoValue = minPromoValue;
        }
      });
    }

    /**
     * Por ahora, el evento que gatillará que se muestre el modal, será
     * cuando el usuario haya incluído al carro de compras el valor en pesos
     * chilenos mayor o igual al valor mínimo de la promoción.
     *
     * @example
     * // Mostrar promoción
     * popupPromo    = true
     * cartValue     = 5200
     * minPromoValue = 5000
     *
     * // No mostrar promoción
     * popupPromo    = true
     * cartValue     = 3000
     * minPromoValue = 5000
     *
     * @param {number} cartTotal Valor del carro
     */
    function checkPopupPromo(event, opt) {
      // Si la promoción está activa y se cumple el valor mínimo de la promoción,
      // mostrar modal, pero sólo una vez...
      if ($scope.popupPromo && $scope.showPopupPromo && opt.cartTotal >= $scope.minPromoValue) {
        $('#promo-modal').modal('show');
        $scope.showPopupPromo = false;
      }

      // Si la promoción está activa, pero no se cumple la primera condición,
      // debemos resetear el estado del popup, con la finalidad de que se vuelva a
      // mostrar cuando el usuario haya eliminado/disminuído productos del carro y
      // vuelva a agregar más, volviendo a cumplir el valor mínimo de la promoción
      else if (
        $scope.popupPromo &&
        !$scope.showPopupPromo &&
        opt.cartTotal < $scope.minPromoValue
      ) {
        $scope.showPopupPromo = true;
      }
    }
  }
})();
