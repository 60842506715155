(function () {
  'use strict';

  angular.module('shared.navbarPromo').component('navbarPromoComponent', {
    templateUrl: 'shared/navbar-promo/navbar-promo.template.html',
    controller: NavbarPromoController,
    controllerAs: 'vm',
    bindings: {
      messageTitle: '<',
      message: '<',
    },
  });

  function NavbarPromoController($rootScope) {
    const vm = this;

    vm.dismissNavbarPromo = dismissNavbarPromo;

    function dismissNavbarPromo() {
      $rootScope.$broadcast('dismissNavbarPromo');
      console.info('Broadcast: ¡Cerrar la promoción del navbar!');
    }
  }
})();
