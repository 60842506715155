(function () {
  'use strict';

  angular.module('directives.resizeEvent').directive('resizeEvent', ResizeEvent);

  function ResizeEvent($rootScope, $window) {
    return {
      link: function () {
        $window.onresize = function () {
          $rootScope.$emit('lazyImg:refresh');
          $rootScope.$broadcast('resizeEvent');
        };
      },
    };
  }
})();
