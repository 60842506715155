(function () {
  'use strict';

  angular.module('directives.fixedTop').directive('fixedTop', FixedTop);

  function FixedTop() {
    return {
      link: function (scope, element) {
        element[0].style.position = 'fixed';
        element[0].style.top = 0;
        element[0].style.left = 0;
        element[0].style.right = 0;
        element[0].style.zIndex = 1030;
      },
    };
  }
})();
