(function () {
  'use strict';

  angular.module('services.homeNavigation').service('HomeNavigationService', HomeNavigationService);

  function HomeNavigationService($anchorScroll, $location, $rootScope, $state, Slug) {
    // desplazamiento vertical adicional en px segun ancho de pantalla
    function getOffsetByScreenWidth(clientWidth) {
      if (clientWidth >= 1400) {
        return 280;
      }

      if (clientWidth >= 769 && clientWidth <= 1399) {
        return 210;
      }

      return 195;
    }

    // scrollear a una subcategoria/seccion dentro de la categoría activa
    function scrollToSubcategory(index) {
      let newHash = 'subcategory-' + index;
      let clientWidth = $(window).width();
      if ($location.hash() !== newHash) {
        $anchorScroll.yOffset = getOffsetByScreenWidth(clientWidth);
        $location.hash('subcategory-' + index);
      } else {
        $anchorScroll.yOffset = getOffsetByScreenWidth(clientWidth);
        $anchorScroll();
      }

      $(`.breadcrumbs-button-${index}`).addClass('selected-subcategory');
      setTimeout(() => {
        $(`.breadcrumbs-button-${index}`).removeClass('selected-subcategory');
      }, 1500);

      if ($('.subcategories-nav') && window.innerWidth < 577) {
        $(`#subcategory-${index}-container`).addClass('mobile-section-transition');
        setTimeout(() => {
          $(`#subcategory-${index}-container`).removeClass('mobile-section-transition');
        }, 1100);
      }
    }

    // navegar al home
    function goHome() {
      $rootScope.$broadcast('changeCategory', {
        categoryId: 34,
        categoryName: 'destacados',
      });
      $state.go('home');
    }

    // Navegar a una categoria desde otra pagina
    function goToCategory(categoryId, categoryName) {
      $state.go('home.category', {
        categoryId: categoryId,
        categoryName: Slug.slugify(categoryName),
      });
    }

    // Navegar a una subcategoria/sección desde otra pagina
    function goToSubCategory(categoryId, categoryName, subcategoryId) {
      if (subcategoryId > 0) {
        $state.go('home.category', {
          categoryId: categoryId,
          categoryName: Slug.slugify(categoryName),
        });

        setTimeout(() => {
          scrollToSubcategory(subcategoryId);
        }, 0);
      } else {
        $state.go('home.category', {
          categoryId: categoryId,
          categoryName: Slug.slugify(categoryName),
        });

        setTimeout(() => {
          $('.breadcrumbs-button-0').addClass('selected-subcategory');
          setTimeout(() => {
            $('.breadcrumbs-button-0').removeClass('selected-subcategory');
          }, 1500);
        }, 300);
        scrollTop(100);
      }
    }

    function scrollTop(duration) {
      $('html,body').animate({ scrollTop: 0 }, duration);
    }

    function scrollHorizontalNavMobile(subcategoryId) {
      let clientWidth = $(window).width();
      $('#prev-nav-btn').removeClass('show-arrow');
      if (clientWidth < 767) {
        if (subcategoryId >= 0 && subcategoryId <= 2) {
          setTimeout(() => {
            $('.subcategories-nav').scrollLeft(0);
          }, 300);
        }
        if (subcategoryId >= 3 && subcategoryId <= 5) {
          setTimeout(() => {
            $('.subcategories-nav').scrollLeft(window.innerWidth);
          }, 300);
          if (subcategoryId >= 6 && subcategoryId <= 8) {
            setTimeout(() => {
              $('.subcategories-nav').scrollLeft(window.innerWidth * 2);
            }, 300);
          }
        }
      }
    }

    return {
      scrollToSubcategory: scrollToSubcategory,
      goHome: goHome,
      goToCategory: goToCategory,
      goToSubCategory: goToSubCategory,
      scrollHorizontalNavMobile: scrollHorizontalNavMobile,
    };
  }
})();
